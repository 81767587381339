const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
    },
    wrapper: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: (props) => props.radius * 2,
      height: (props) => props.radius * 2,
      borderRadius: '50%',
      margin: [100, 'auto'],
      userSelect: 'none',
    },
    text: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      width: (props) => props.radius * 2,
      height: (props) => props.radius * 2,
      marginLeft: (props) => -props.radius,
      marginTop: (props) => -props.radius,
      fontFamily: theme.fonts[1],
      fontSize: 120,
      color: theme.getRgba(theme.colors[1], 0.2),
      textTransform: 'uppercase',
      transition: `all .5s ${theme.easings['power3.out']}`,
      '& span': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        marginTop: -71,
        marginLeft: -8,
        width: 40,
        height: 120,
        transformOrigin: '50%, 50%',
        display: 'block',
        transforOrigin: 'center',
      },
    },
    active: {
      color: theme.colors[3],
    },

    // Switch
    switch: {
      position: 'absolute',
      zIndex: 10,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 65,
      height: 36,
      borderRadius: 30,
      boxShadow: 'rgb(0 0 0 / 20%) 4px 4px 4px 3px, rgb(255 255 255 / 18%) -1px -1px 3px 5px',
      cursor: 'pointer',
      pointerEvents: 'all',
      '&:hover': {
        '& $switchButton': {
          transform: 'scale(1.1)',
        },
      },
    },
    switchButton: {
      width: 28,
      height: 28,
      top: 5,
      left: 5,
      position: 'absolute',
      zIndex: 1,
      borderRadius: '50%',
      background: theme.colors[3],
      boxShadow: 'inset rgb(0 0 0 / 21%) -1px -2px 0px 0px, inset rgb(255 255 255 / 19%) 1px 2px 0px 0px',
      transition: 'transform .15s ease-out',
    },
    switchOff: {
      '& $switchButton': {
        transform: 'translateX(27px)',
      },
      '&:hover': {
        '& $switchButton': {
          transform: 'translateX(27px) scale(1.1)',
        },
      },
    },

    // Ball
    ball: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 220,
      height: 220,
      pointerEvents: 'none',
      transition: `transform .34s ${theme.easings['power3.out']}`,
    },
    ballOff: {
      transform: 'translate(-50%, -50%) rotate(-180deg)',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {

  }

  return output
}

export default style
