const style = (theme) => {
  const output = {
    root: {
      marginTop: '-50vh',
      height: 'var(--vh)',
      color: theme.colors[1],
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      textAlign: 'center',
      '&:before': {
        content: window.location.search.indexOf('debug') !== -1 ? '""' : '',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: 10,
        background: 'linear-gradient(to bottom, red, blue)',
      },
    },
    section: {
      position: 'relative',
      zIndex: 1,
      height: 'var(--vh)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.fonts[1],
      fontSize: '10vw',
      lineHeight: 0.9,
      marginBottom: 0,
      textTransform: 'uppercase',
    },
    button: {
      pointerEvents: 'none',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      marginTop: '0vh',
    },
    title: {
      fontSize: '18vw',
    },
  }

  return output
}

export default style
