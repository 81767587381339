const style = (theme) => {
  const output = {
    root: {
      pointerEvents: 'all',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: 'linear-gradient(#987fdd, #664dff)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: theme.colors[1],
      fontSize: 16,
    },
    hide: {
      pointerEvents: 'none',
    },
    logoContainer: {
      position: 'relative',
      zIndex: 1,
      '& img': {
        position: 'absolute',
        zIndex: 1,
        left: '48%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: 'auto',
      },
    },
    spinner: {
      width: 250,
      height: 250,
      transform: 'rotate(-90deg)',
    },
    pathTop: {
      strokeDasharray: '126, 126',
      strokeDashoffset: 126,
    },
    text: {
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 20,
      maxWidth: 600,
      padding: [0, 20],
    },
    buttonWrap: {
      padding: '10px 20px',
      borderRadius: '30px',
    },
    button: {
      opacity: 0.2,
      pointerEvents: 'none',
      width: 38,
      height: 38,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      background: theme.colors[1],
      cursor: 'pointer',
      transition: 'opacity .3s ease-out .3s',
      '&.visible': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& svg': {
        width: 10,
        height: 10,
        fill: theme.colors[0],
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    button: {
      width: 40,
      height: 40,
    },
  }

  return output
}

export default style
