import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import StrokeBall from '@/components/StrokeBall'
import DelayLink from '@/components/DelayLink'
import style from './style'

const useStyles = createUseStyles(style)

const Section11 = ({ className, speed = 2, openOverlay, closeOverlay, link, title }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement, scrollbar } = useContext(ScrollbarContext)
  const $root = useRef()
  const $title = useRef()
  const $link = useRef()
  const $discover = useRef()
  const $strokes = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    return (() => {
      if (ScrollTrigger.getById('section-11')) {
        ScrollTrigger.getById('section-11').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-11')) {
        ScrollTrigger.getById('section-11').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-11',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .fromTo($strokes.current, {
          scale: 0,
          opacity: 0,
        }, {
          scale: 1,
          opacity: 1,
        })
        .fromTo($title.current, {
          y: '100%',
          opacity: 0,
        }, {
          y: '0%',
          opacity: 1,
        }, '<')
        .fromTo($link.current, {
          pointerEvents: 'none',
        }, {
          pointerEvents: 'all',
        })

      if (theme.detect.debug) {
        window.console.log('section 11 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <StrokeBall ref={$strokes} className={classes.svgBall} />
        <DelayLink
          to={link}
          className={classes.link}
          ref={$link}
        >
          <h3 ref={$title} className={classes.title}>{title}</h3>
          <button
            className={classes.button}
            ref={$discover}
          >
            Discover
            {' '}
            <svg><use xlinkHref="#ico-arrow-right" /></svg>
          </button>
        </DelayLink>
        <button
          className={classes.toTop}
          onClick={() => {
            openOverlay()
            setTimeout(() => {
              scrollbar.scroll.scrollTo(0, { disableLerp: true, duration: 0 })
              scrollbar.el.scrollTop = 0
            }, 500)
            setTimeout(() => {
              closeOverlay()
            }, 1000)
          }}
        >
          <svg>
            <use xlinkHref="#ico-refresh" />
          </svg>
          Back to top
        </button>
      </div>
    </section>
  )
}
export default Section11
