const padding = '15px'

const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      color: 'white',
      alignItems: 'flex-end',
      height: '100%',
    },
    pause: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '& span': {
        position: 'absolute',
        width: 20,
        bottom: 25,
        left: 30,
        cursor: 'pointer',
        fontSize: 0,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before, &:after': {
          content: '""',
          display: 'inline-block',
          width: 4,
          height: 16,
          marginRight: 5,
          background: theme.colors[6],
        },
      },
    },
    fullscreen: {
      cursor: 'pointer',
      position: 'absolute',
      bottom: 25,
      right: 30,
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        fill: theme.colors[6],
        width: 16,
        height: 16,
      },
    },
    progressWrapper: {
      position: 'relative',
      flex: 1,
      display: 'block',
      height: '1px',
      padding: `${padding} 0`,
      cursor: 'pointer',
      marginLeft: 30,
      marginRight: 30,
      '&:before': {
        content: '""',
        background: 'rgba(255, 255, 255, .3)',
        position: 'absolute',
        top: padding,
        left: 0,
        width: '100%',
        height: '1px',
      },
    },
    progress: {
      position: 'absolute',
      top: padding,
      left: 0,
      width: '100%',
      height: '1px',
      background: theme.colors[6],
      transformOrigin: 'left',
    },
  }
  return output
}

export default style
