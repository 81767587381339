/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const StrokeButton = forwardRef((
  {
    children,
    className,
    ...props
  }, ref,
) => {
  const classes = useStyles()
  const $stroke1 = useRef()
  const $stroke2 = useRef()
  const $stroke3 = useRef()

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      {...props}
      className={classNames({
        [className]: true,
        [classes.root]: true,
      })}
    >
      <path
        ref={$stroke1}
        className={classNames({
          [classes.stroke]: true,
          [classes.stroke1]: true,
          'strokeBall--stroke1': true,
        })}
        d="M11,1.9c5.2,0,11.1,4.3,11.1,10.5S17.1,23,12.4,23S1.8,19.4,1.8,11.7S8.2,1.9,11,1.9z"
      />
      <path
        ref={$stroke2}
        className={classNames({
          [classes.stroke]: true,
          [classes.stroke2]: true,
          'strokeBall--stroke2': true,
        })}
        d="M11.9,1.6c4.3,0,9,4.5,9,11.1s-4.3,9.4-9.3,9.4s-8.6-6.5-8.6-11.3S5.5,1.6,11.9,1.6z"
      />
      <path
        ref={$stroke3}
        className={classNames({
          [classes.stroke]: true,
          [classes.stroke3]: true,
          'strokeBall--stroke3': true,
        })}
        d="M10.8,1C16.2,1,21,6.5,20.9,13.3c-0.1,6.7-5.7,9.3-8.4,9.3S2.3,19.4,2.3,10.4C2.4,5.4,5.3,1,10.8,1z"
      />
    </svg>
  )
})

export default StrokeButton
