const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      paddingTop: 120,
      paddingBottom: 30,
      width: 550,
      borderRadius: [0, 30, 30, 0],
      height: 'var(--vh)',
      background: theme.colors[1],
      zIndex: theme.zindex.mainNav,
    },
    container: {
      height: '100%',
    },
    wrapper: {
      height: '100%',
      position: 'relative',
      margin: 0,
      color: theme.colors[0],
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
      justifyContent: 'space-between',
    },
    mainNav: {
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
    },
    mainNavItem: {
      fontSize: 120,
      lineHeight: 0.8,
      overflow: 'hidden',
      paddingLeft: '4vw',
      '&:nth-child(2n+2)': {
        marginLeft: 40,
      },
      '& a': {
        color: theme.colors[2],
        display: 'inline-block',
        transform: 'translateY(100%)',
        '--delay1': 'calc(var(--index) * 0.1s)',
        '--delay2': 'calc(var(--delay1) + 0.2s)',
        transition: `opacity 0.2s ease-out, transform .7s ${theme.easings['power3.inOut']} 0s`,
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.4,
          },
        },
        '&.entering': {
          transform: 'translateY(0)',
          transition: `opacity 0.2s ease-out, transform .7s ${theme.easings['power3.out']} var(--delay2)`,
        },
      },
    },

    footerNav: {
      marginTop: 40,
      paddingLeft: '4.2vw',
    },
    footerNavItem: {
      marginTop: 20,
      color: theme.colors[2],
      fontSize: 14,
      '& strong': {
        display: 'block',
        fontSize: 18,
      },

      '& a': {
        color: theme.colors[2],
        extend: theme.link,
        display: 'inline-block',
      },
    },

    magnetic: {
      padding: 30,
    },
    quoteButton: {
      position: 'absolute',
      borderRadius: '50%',
      right: 0,
      bottom: 100,
      transform: 'translate(50%) scale(0) rotate(90deg)',
      transition: `transform .4s ${theme.easings['power3.out']} 0.05s`,
      '&.entering': {
        transform: 'translate(50%) scale(1) rotate(0deg)',
        transitionDelay: '.1s',
      },
      '&.clicked': {
        '& a': {
          transform: 'rotate(360deg)',
          '& svg': {
            transform: 'rotate(360deg) scale(1)',
          },
        },
      },
      '& svg': {
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fill: theme.colors[3],
        transition: `transform .5s ${theme.easings['power3.out']}`,
      },
      '& a': {
        width: 140,
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors[1],
        borderRadius: '50%',
        lineHeight: 1.1,
        transition: `transform .5s ${theme.easings['power3.out']}`,
        '@media (hover: hover)': {
          '&:hover': {
            transform: 'rotate(-15deg)',
            '& svg': {
              transform: 'scale(1.1)',
            },
          },
        },
      },
    },

    quoteButtonDesktop: {
      display: 'block',
    },
    quoteButtonMobile: {
      display: 'none',
    },

    socials: {},

    credits: {
      position: 'absolute',
      zIndex: 10,
      bottom: 15,
      right: '4.2vw',
      color: theme.colors[2],
      borderRadius: '50%',
      extend: theme.link,
      fontSize: 8,
      paddingBottom: 2,
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
  }

  /*------------------------------
  Max Height 700
  ------------------------------*/
  output['@media (max-height: 700px)'] = {
    mainNavItem: {
      fontSize: 100,
    },
    footerNavItem: {
      marginTop: 20,
      fontSize: 13,
      '& strong': {
        fontSize: 16,
      },
    },
  }

  /*------------------------------
  Max Height 630
  ------------------------------*/
  output['@media (max-height: 630px)'] = {
    mainNavItem: {
      fontSize: 75,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '100%',
      borderRadius: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },

    mainNav: {
      textAlign: 'center',
      paddingTop: 80,
    },

    mainNavItem: {
      fontSize: '24vw',
      paddingLeft: 0,
      '&:nth-child(2n+2)': {
        marginLeft: 0,
      },
    },

    footerNav: {
      marginTop: 20,
      padding: '0 4.2vw 30px',
      textAlign: 'center',
    },

    footerNavItem: {
      marginTop: 0,
      fontSize: 13,
      '& strong': {
        fontSize: 16,
      },
    },

    quoteButtonDesktop: {
      display: 'none',
    },

    quoteButtonMobile: {
      display: 'block',
      position: 'relative',
      transform: 'none',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
      marginTop: 15,
      textAlign: 'center',
      '&.entering': {
        transform: 'rotate(-10deg)',
      },
      '& a': {
        width: 140,
        height: 140,
        margin: [0, 'auto'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors[1],
        borderRadius: '50%',
        lineHeight: 1.1,
        transition: `transform .5s ${theme.easings['power3.out']}`,
      },
    },
    socials: {
      display: 'flex',
      pointerEvents: 'all',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: -10,
      padding: [0, 0, 50],
      '& a': {
        padding: [6, 10],
        pointerEvents: 'all',
      },
      '& svg': {
        width: 20,
        height: 20,
        fill: theme.colors[2],
      },
    },

    credits: {
      position: 'static',
      textAlign: 'center',
      paddingBottom: 40,
      marginTop: -20,
      display: 'inline-block',
      '&:before': {
        display: 'none',
      },
    },
  }

  return output
}

export default style
