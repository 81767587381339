import classNames from 'classnames'
import injectSheet from 'react-jss'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import DelayLink from '@/components/DelayLink'
import RevealImage from '@/components/RevealImage'
import style from './style'

const Banner = ({
  pretitle,
  image,
  title,
  text,
  internalLabel,
  internalLink,
  externalLabel,
  externalLink,
  classes,
  className,
  imageRight,
}) => {
  // const linkIntern = link.includes('ghostunicorns') || link.includes('wearetesting') || !link.includes('http')

  // window.console.log('externalLabel ---->', externalLabel, internalLabel, externalLink, internalLink)

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      <div className={classes.wrapper}>
        <div
          className={classNames({
            [classes.left]: true,
            [classes.leftInverse]: imageRight,
          })}
        >
          <InViewTrigger
            className={classes.triggerImage}
          >
            <RevealImage
              image={image}
              label={title}
            />
          </InViewTrigger>
        </div>
        <div
          className={classNames({
            [classes.right]: true,
            [classes.rightInverse]: imageRight,
          })}
        >
          <InViewTrigger>
            <RevealText
              className={classes.title}
              tag="h3"
              type="lines"
              lineHeight={0.85}
              value={title}
            />
            <RevealText
              className={classes.subTitle}
              tag="h4"
              type="lines"
              value={pretitle}
            />
            <RevealText
              className={classNames({
                [classes.text]: true,
              })}
              type="lines"
              value={text}
            />
          </InViewTrigger>
          <InViewTrigger>
            <RevealFadeIn>
              {internalLink && (
              <DelayLink
                to={internalLink}
                className={classNames({
                  [classes.button]: true,
                  [classes.buttonBorder]: false,
                })}
              >
                {internalLabel}
                <svg><use xlinkHref="#ico-arrow-right" /></svg>
              </DelayLink>
              )}
              {externalLink && (
              <DelayLink
                to={externalLink}
                className={classNames({
                  [classes.button]: true,
                  [classes.buttonBorder]: true,
                })}
              >
                {externalLabel}
                <svg><use xlinkHref="#ico-link" /></svg>
              </DelayLink>
              )}
            </RevealFadeIn>
          </InViewTrigger>
        </div>
      </div>
    </div>
  )
}

Banner.defaultProps = {
  imageRight: false,
}

export default injectSheet(style)(Banner)
