import { useEffect, useContext, useState } from 'react'
import { Context as LoadingContext } from '@/context/loading'

const usePagePrecache = ({
  init = false,
  sources = [],
  callback = () => {},
}) => {
  const { setReady, setSiteLoaded, isFontReady, isLoaderExited } = useContext(LoadingContext)
  const [trigger, setTrigger] = useState(false)
  const [load, setLoad] = useState(false)
  const [animationTrigger, setAnimationTrigger] = useState(false)

  /*------------------------------
  Complete preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setTrigger(true)
  }

  /*------------------------------
  Precache all Video & Images
  ------------------------------*/
  const precacheMedia = async (sourceList) => {
    const images = sourceList?.filter((src) => src.type === 'image').map((item) => item.src)
    const videos = sourceList?.filter((src) => src.type === 'video').map((item) => item.src)

    const promisesImage = await images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    const promisesVideo = await videos.map((src) => {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest()
        req.open('GET', src, true)
        req.responseType = 'blob'
        req.onload = () => {
          if (req.status === 200) {
            const videoBlob = req.response
            URL.createObjectURL(videoBlob)
            resolve()
          }
        }
        req.onerror = () => reject()
        req.send()
      })
    })

    await Promise.all([
      ...promisesImage,
      ...promisesVideo,
    ]).then(() => completePreload())
  }

  /*------------------------------
  Start the media precache
  ------------------------------*/
  useEffect(() => {
    if (init && sources.length > 0) precacheMedia(sources)
    if (init && sources.length === 0) completePreload()
  }, [init])

  /*------------------------------
  Check if Fonts & Audio are ready
  ------------------------------*/
  useEffect(() => {
    if (trigger && isFontReady) {
      setLoad(true)
    }
  }, [trigger, isFontReady])

  /*------------------------------
  Check if load is true and loader is exited
  ------------------------------*/
  useEffect(() => {
    if (load && isLoaderExited) {
      setAnimationTrigger(true)
      callback()
    }
  }, [load, isLoaderExited])

  /*------------------------------
  Return values
  ------------------------------*/
  return [load, animationTrigger]
}

export default usePagePrecache
