const style = (theme) => {
  const output = {
    root: {
      height: 'var(--vh)',
      color: theme.colors[1],
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      textAlign: 'center',
      '&:before': {
        content: window.location.search.indexOf('debug') !== -1 ? '""' : '',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: 10,
        background: 'linear-gradient(to bottom, red, blue)',
      },
    },
    section: {
      position: 'relative',
      zIndex: 1,
      height: 'var(--vh)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.fonts[1],
      fontSize: 160,
      opacity: 1,
      transform: 'translateX(200%)',
    },
    text: {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      fontSize: 20,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      fontSize: '20vw',
    },
    text: {
      padding: [0, 20],
      fontSize: 16,
    },
  }

  return output
}

export default style
