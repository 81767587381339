import { useEffect, useRef, useState, useMemo } from 'react'
import { useFrame } from '@react-three/fiber'
import { Vector3, CatmullRomCurve3, Color, MathUtils } from 'three'
import constants from '@/constants'
import zustand from '@/base/zustand'

/*------------------------------
Utilities Vector3
------------------------------*/
const convertoToVector3 = (arr) => {
  const output = []
  for (let i = 0; i < arr.length; i += 3) {
    output.push(new Vector3(
      arr[i],
      arr[i + 1],
      arr[i + 2],
    ))
  }
  return output
}

/*------------------------------
Spline
------------------------------*/
function Spline({ curve, color }) {
  const $mesh = useRef()
  const lineWidth = 0.0007 * 2 // MathUtils.randFloat(0.0015, 0.003)

  /*------------------------------
  Shaders
  ------------------------------*/
  const shaderArgs = useMemo(
    () => ({
      transparent: true,
      // frustumCulled: false,
      depthTest: false,
      depthWrite: false,
      // side: DoubleSide,
      // blending: AdditiveBlending,
      uniforms: {
        uTime: { value: 0 },
        uRandom: { value: Math.random() * 100 },
        uColor: { value: new Color(color) },
        uDirection: { value: Math.random() > 0.5 ? 1 : -1 },
      },
      vertexShader: /* glsl */`
        varying vec2 vUv;
        void main() {
          vUv = uv;
          vec4 mvPos = modelViewMatrix * vec4( position, 1.0 );
          gl_Position = projectionMatrix * mvPos;
        }
      `,
      fragmentShader: /* glsl */ `
        uniform float uTime;
        uniform float uRandom;
        uniform float uDirection;
        uniform vec3 uColor;
        varying vec2 vUv;

        void main() {
          float dash = sin((vUv.x * 10.) + (uTime * uRandom / 50.) + uRandom);
          dash *= uDirection;
          if (dash > .1) discard;
          gl_FragColor = vec4(uColor, .5);
        }
      `,
    }),
    [],
  )

  /*------------------------------
  Raf
  ------------------------------*/
  useFrame(({ clock }) => {
    $mesh.current.material.uniforms.uTime.value = clock.getElapsedTime()
  })

  return (
    <mesh ref={$mesh}>
      <tubeGeometry args={[curve, curve.points.length * 3, lineWidth, 8, false]} />
      {/* <meshStandardMaterial color={color} /> */}
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  )
}

/*------------------------------
Model
------------------------------*/
export default function Model() {
  const $groupScroll = useRef()
  const $groupMouseMove = useRef()
  const $groupMouseHover = useRef()
  const [splines, setSplines] = useState([])
  const scale = 4

  /*------------------------------
  Carico Json
  ------------------------------*/
  useEffect(() => {
    fetch(`${constants.DATA_URL}unicorn-splines-min.json`)
      .then((response) => {
        return response.json()
      }).then((res) => {
        if (!splines.length) {
          setSplines(res)
        }
      })

    $groupScroll.current.position.x = 0
    $groupScroll.current.position.y = 0
    $groupScroll.current.position.z = 0
    $groupScroll.current.rotation.y = 0

    $groupMouseHover.current.rotation.y = 0
    $groupMouseHover.current.position.x = 0
    $groupMouseHover.current.position.z = 0

    $groupMouseMove.current.rotation.y = 0
    $groupMouseMove.current.rotation.z = 0
  }, [])

  /*------------------------------
  Converto Splines
  ------------------------------*/
  const renderSplines = () => {
    const colors = [
      '#ffffff',
      '#ffffff',
      '#e7effe',
      '#ffabfa',
      '#9eb8fb',
    ]
    return splines.slice(0, 120).map((spline, index) => {
      const curve = new CatmullRomCurve3(convertoToVector3(spline))
      const color = colors[Math.floor(Math.random() * colors.length)]
      return (
        <Spline key={index.toString()} curve={curve} color={color} />
      )
    })
  }

  /*------------------------------
  Raf
  ------------------------------*/
  useFrame(() => {
    const { x, y } = zustand.general.mouse

    // Scroll
    $groupScroll.current.position.x = MathUtils.lerp($groupScroll.current.position.x, MathUtils.mapLinear(zustand.general.scrollY, 0.5, 0, -1, -1.5), 0.08)
    $groupScroll.current.position.y = MathUtils.lerp($groupScroll.current.position.y, MathUtils.mapLinear(zustand.general.scrollY, 0, 1, -1, -1), 0.08)
    $groupScroll.current.position.z = MathUtils.lerp($groupScroll.current.position.z, MathUtils.mapLinear(zustand.general.scrollY, 0, 1, 10, 3), 0.08)
    $groupScroll.current.rotation.y = MathUtils.lerp($groupScroll.current.rotation.y, MathUtils.mapLinear(zustand.general.scrollY, 0, 1, Math.PI, Math.PI * -0.62), 0.08)

    // Mouse
    $groupMouseMove.current.rotation.y = MathUtils.lerp($groupMouseMove.current.rotation.y, MathUtils.mapLinear(x, -1, 1, -Math.PI * 0.05, Math.PI * 0.05), 0.02)
    $groupMouseMove.current.rotation.x = MathUtils.lerp($groupMouseMove.current.rotation.x, MathUtils.mapLinear(y, -1, 1, Math.PI * 0.05, -Math.PI * 0.05), 0.02)

    // Link Hover
    if (zustand.home.modelHover === 'left') {
      $groupMouseHover.current.rotation.y = MathUtils.lerp($groupMouseHover.current.rotation.y, -0.5, 0.02)
      $groupMouseHover.current.position.x = MathUtils.lerp($groupMouseHover.current.position.x, 0, 0.02)
      $groupMouseHover.current.position.z = MathUtils.lerp($groupMouseHover.current.position.z, 0, 0.02)
    } else if (zustand.home.modelHover === 'right') {
      $groupMouseHover.current.rotation.y = MathUtils.lerp($groupMouseHover.current.rotation.y, 1.2, 0.02)
      $groupMouseHover.current.position.x = MathUtils.lerp($groupMouseHover.current.position.x, 0, 0.02)
      $groupMouseHover.current.position.z = MathUtils.lerp($groupMouseHover.current.position.z, 0, 0.02)
    } else {
      $groupMouseHover.current.rotation.y = MathUtils.lerp($groupMouseHover.current.rotation.y, 0, 0.02)
      $groupMouseHover.current.position.x = MathUtils.lerp($groupMouseHover.current.position.x, 0, 0.02)
      $groupMouseHover.current.position.z = MathUtils.lerp($groupMouseHover.current.position.z, 0, 0.02)
    }
  })

  return (
    <>
      <group scale={scale} ref={$groupScroll}>
        <group ref={$groupMouseMove}>
          <group ref={$groupMouseHover}>
            { splines.length && renderSplines() }
          </group>
        </group>
      </group>
    </>
  )
}
