const style = (theme) => {
  const transition = `all 1s ${theme.easings['power4.out']}`

  const output = {
    root: {
      position: 'fixed',
      top: 17,
      width: '100%',
      zIndex: theme.zindex.header,
      transition: 'all 0.6s',
      pointerEvents: 'none',
      background: 'transparent',
    },
    background: {},
    minimize: {
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      transition,
    },
    magnetic: {
      padding: [20],
    },
    logo: {
      marginTop: 15,
      marginLeft: 53,
      display: 'inline-block',
      pointerEvents: 'all',
      '& svg': {
        transition: 'all 0.3s',
        fill: (props) => (!props.headerInverse ? theme.colors[1] : theme.colors[3]),
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'all',
      height: '100%',
      marginLeft: -20,
    },
    burgerButton: {
      position: 'relative',
    },
    back: {
      position: 'absolute',
      zIndex: 2,
      top: 12,
      left: '50%',
      pointerEvents: 'all',
      width: 30,
      height: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      background: theme.colors[1],
      cursor: 'pointer',
      transform: 'translateX(-50%)',
      transition: 'opacity .2s ease-out',
      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.8,
        },
      },
      '& svg': {
        width: 10,
        height: 10,
        fill: theme.colors[0],
      },
    },
    headerLink: {
      fontSize: 15,
      fontFamily: theme.fonts[0],
      color: (props) => (!props.headerInverse ? theme.colors[1] : theme.colors[3]),
      transition: 'all 0.3s',
      marginRight: 50,
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none!important',
    },
    audioButton: {
      position: 'absolute',
      zIndex: 2,
      right: '4.2vw',
      top: 13,
      pointerEvents: 'all',
      cursor: 'pointer',
      '& svg': {
        top: 12,
        '& path': {
          strokeWidth: 1,
        },
      },
    },
    available: {
      position: 'absolute',
      zIndex: 2,
      right: 'calc(4.2vw + 140px)',
      top: 21,
      pointerEvents: 'all',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 9,
      textTransform: 'uppercase',
      color: theme.colors[1],
      fontFamily: theme.fonts[0],
      paddingBottom: 1,
      '& svg': {
        top: 10,
        '& path': {
          strokeWidth: 1,
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      top: -20,
    },
    logo: {
      marginLeft: 33,
    },
    burgerButton: {
      marginLeft: 10,
      marginRight: -10,
    },
    back: {
      marginRight: 20,
    },
    headerLink: {
      display: 'none',
    },
    audioButton: {
      top: 12,
    },
    available: {
      display: 'none',
    },
  }

  return output
}

export default style
