const style = (theme) => {
  const output = {
    root: {
      // willChange: 'clip-path',
      willChange: 'opacity',
      overflow: 'hidden',
      display: 'block',
      // clipPath: 'inset(100% 0% 0%)',
      opacity: 0,

      '& span': {
        display: 'block',
        willChange: 'transform',
      },
    },
    image: {
      '& img': {
        willChange: 'transform',
        transform: 'scale(1.1)',
      },
    },
  }

  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
