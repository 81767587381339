import { useRef, useEffect } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
// import { useRaf } from '@/components/Handlers'
import { removeParagraph } from '@/utils/strings'
// import zustand from '@/base/zustand'
import constants from '@/constants'
import style from './heroStyle'

const useStyles = createUseStyles(style)

const Hero = ({
  title,
  text,
  ready,
  // inview,
}) => {
  const classes = useStyles()
  const $svg = useRef()

  // useRaf(() => {
  //   if (inview) {
  //     gsap.to($svg.current, {
  //       rotationX: zustand.general.mouse.y * 2,
  //       rotationY: zustand.general.mouse.x * 2,
  //     })
  //   }
  // }, [inview])

  useEffect(() => {
    if (ready) {
      gsap.to($svg.current, {
        opacity: 1,
        scale: 1,
        rotation: 0,
        duration: 2,
        delay: 0.4,
      })
    }
  }, [ready])

  const renderLines = () => (
    <>
      <path
        className={classNames({
          [classes.path1]: true,
          [classes.path]: true,
        })}
        d="M1149,578s89.41,87.766-298,152c-81.514,15.639-289.787,24.252-342,24s-127.673-4.214-137,185c-5.522,99.47,25,143,25,143s13.278,62.41,175,54c76.084-3.96,241.523-9.89,367-47,183.74-53.71,239-115,239-115s98.34-73,96-207c-2.84-162.086-177-228-177-228"
      />
      <path
        className={classNames({
          [classes.path2]: true,
          [classes.path]: true,
        })}
        d="M242,468c-4.356.892-211,31.889-211,199,0,63.39,37.762,143.765,101,178,93.369,50.547,239,68,239,68"
      />
      <path
        className={classNames({
          [classes.path3]: true,
          [classes.path]: true,
        })}
        d="M881,351s89.627,27.773,136,66,83.76,83.11,89,189"
      />
      <path
        className={classNames({
          [classes.path4]: true,
          [classes.path]: true,
        })}
        d="M621,202s-8.337,37.613-175,67c-53.219,6.658-189.454,6.854-204,169-1.643,22.492,3,31,3,31s5.974,43.711,17,51,10.07,20,189,20,514-71.491,514-116c0.183-14.035-11.757-26.225-26.224-35.823"
      />
      <path
        className={classNames({
          [classes.path5]: true,
          [classes.path]: true,
        })}
        d="M452,20s14.865,14.419,19,19,21.86,25.939,42,38,71.763,33.735,105,46,54.026,27.9,72,35,122.576,37.35,169,119c20.052,34.37,21,75,21,75s-1.552,23.49-5,56"
      />
      <path
        className={classNames({
          [classes.path6]: true,
          [classes.path]: true,
        })}
        d="M453,25s9.151,40.336,20,65c6.636,30.376,10.8,83.052,11,98,0.086,33.82,17,68,17,68"
      />
    </>
  )

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.hero}>
          <RevealText
            className={classes.title}
            tag="h1"
            type="lines"
            lineHeight={0.85}
            value={removeParagraph(title)}
            visible={ready}
          />
          <RevealFadeIn
            className={classes.text}
            visible={ready}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </RevealFadeIn>
          <svg
            ref={$svg}
            viewBox="0 0 1300 1180"
            className={classNames({
              [classes.svg]: true,
            })}
          >
            <image href={`${constants.IMAGE_URL}merda.png`} />
            <g className={classes.line1}>
              {renderLines()}
            </g>
            <g className={classes.line2}>
              {renderLines()}
            </g>
            <g className={classes.line3}>
              {renderLines()}
            </g>
          </svg>
        </div>
      </div>
    </div>

  )
}

export default Hero
