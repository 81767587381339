import classNames from 'classnames'
import injectSheet from 'react-jss'
import style from './style'

const BannerIE11 = ({
  classes,
  className,
}) => {
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      Il tuo browser non è supportato. Aggiorna il browser per una maggiore sicurezza, velocità e la migliore esperienza su questo sito.
      <a href="https://browsehappy.com/" rel="noopener noreferrer" target="_blank">Aggiorna</a>
    </div>
  )
}

export default injectSheet(style)(BannerIE11)
