const style = (theme) => {
  const output = {
    wrapper: {
      position: 'relative',
      marginLeft: 'calc(4.2vw + 90px)',
      height: 190,
      cursor: 'grab',
      touchAction: 'pan-y',
      '&.grabbing': {
        cursor: 'grabbing',
      },
    },
    items: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      display: 'flex',
    },
    item: {
      userSelect: 'none',
      flex: '0 0 177px',
      width: 177,
      height: 190,
      marginRight: 30,
      borderRadius: 30,
      padding: [12, 26, 25],
      position: 'relative',
      zIndex: 1,
      border: `2px solid ${theme.getRgba(theme.colors[1], 0.25)}`,
      color: theme.getRgba(theme.colors[1], 0.3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&.hidden': {
        visibility: 'hidden',
      },
    },
    half: {
      border: `2px solid ${theme.colors[1]}`,
      color: theme.colors[1],
      '& $pin': {
        background: theme.colors[13],
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: theme.colors[13],
          top: 0,
          left: 0,
          transformOrigin: 'center',
          animation: '$pulse 2s ease-in infinite',
        },
        '&:after': {
          animationDelay: '1s',
        },
      },
    },
    available: {
      border: `2px solid ${theme.colors[1]}`,
      color: theme.colors[1],
      '& $pin': {
        background: '#93c9dd',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: '#93c9dd',
          top: 0,
          left: 0,
          transformOrigin: 'center',
          animation: '$pulse 2s ease-in infinite',
        },
        '&:after': {
          animationDelay: '1s',
        },
      },
    },
    month: {
      fontFamily: theme.fonts[1],
      fontSize: 56,
    },
    year: {
      fontFamily: theme.fonts[0],
      fontSize: 11,
    },
    pin: {
      position: 'absolute',
      zIndex: 1,
      top: 20,
      right: 20,
      width: 20,
      height: 20,
      borderRadius: 20,
      background: '#e44242',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: '0.5',
      },
      '100%': {
        transform: 'scale(2)',
        opacity: '0',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    wrapper: {
      marginLeft: 30,
      height: 120,
    },
    item: {
      flex: '0 0 120px',
      width: 120,
      height: 120,
      marginRight: 20,
      borderRadius: 20,
      padding: [12, 20, 20],
    },
    month: {
      fontSize: 40,
    },
    pin: {
      top: 15,
      right: 15,
      width: 15,
      height: 15,
      borderRadius: 15,
    },
  }

  return output
}

export default style
