import { useRef, useContext, useEffect, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import Modernizr from 'modernizr'
import SmoothScroll from 'locomotive-scroll'
import { Context as ScrollbarContext } from '@/context/scrollbar'

const LocomotiveScroll = forwardRef(({
  className,
  children,
  init,
  direction,
  lerp = 0.1,
}, ref) => {
  const $rootVirtual = useRef(null)

  const scrollRef = useRef()
  const { setScrollbar } = useContext(ScrollbarContext)

  useEffect(() => {
    if (
      $rootVirtual.current
      && init
      && !Modernizr.ie11
    ) {
      scrollRef.current = new SmoothScroll({
        el: $rootVirtual.current,
        direction,
        smooth: true,
        getDirection: true,
        getSpeed: true,
        repeat: false,
        touchMultiplier: 2,
        lerp,
      })
      setScrollbar(scrollRef.current)
    }
    return () => {
      if (
        init
        && scrollRef.current
      ) scrollRef.current.destroy()
    }
  }, [$rootVirtual, init])

  useImperativeHandle(ref, () => ({
    ref: $rootVirtual.current,
  }))

  return (
    <div
      className={classNames({
        [className]: className,
        'smooth-scroll': true,
      })}
      ref={$rootVirtual}
      data-scroll-container
    >
      {children}
    </div>
  )
})

export default LocomotiveScroll
