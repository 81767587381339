import { memo, Suspense, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'

import Bubble from '@/components/Three/Bubble'
import Picture from '@/components/Three/Picture'
import Logo from '@/components/Three/Logo'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)
const CanvasHome = ({ teamList, load }) => {
  const classes = useStyles()

  useEffect(() => {
    gsap.to('#canvas-manifesto', {
      opacity: load ? 1 : 0,
      delay: 2,
      duration: 2,
    })
  }, [load])

  return (
    <Canvas
      id="canvas-manifesto"
      className={classes.root}
      pixelRatio={[1, 2]}
      gl={{ antialias: true }}
      onCreated={(({ gl }) => {
        gl.setClearColor('#2d2b44')
      })}
      camera={{ position: [0, 0, 15], fov: 40 }}
    >
      <directionalLight position={[3, 10, 20]} />
      <ambientLight intensity={0.3} />
      <Suspense fallback={null}>
        <Bubble manifestSection={1} />
        <Bubble manifestSection={8} />
        <Bubble manifestSection={9} />
        <Bubble manifestSection={5} />
        <Bubble manifestSection={6} />
        <Bubble manifestSection={7} />
        <Bubble manifestSection={4} />
        <Bubble manifestSection={2} />
        <Bubble manifestSection={3} />
        <Bubble manifestSection={10} />
        <Logo />
        {/* <PostProcessing /> */}
        <Picture image={teamList.find((team) => team.slug === 'danilo-argentiero').featured_image} name="danilo" />
        <Picture image={teamList.find((team) => team.slug === 'riccardo-ugolini').featured_image} name="riccardo" />
      </Suspense>
    </Canvas>
  )
}

export default memo(CanvasHome)
