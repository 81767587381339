import { useContext, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { createUseStyles } from 'react-jss'
import { Context as LoadingContext } from '@/context/loading'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import useFontPreload from '@/hooks/useFontPreload'
import Overlay from '@/components/Overlay'
import Spinner from '@/components/Spinner'
import Nav from '@/components/Nav'
import ModalBio from '@/components/ModalBio'
import SVGSprite from '@/components/SVGSprite'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import useMouse from '@/hooks/useMouse'
import { CONFIG } from '@/constants'
import zustand from '@/base/zustand'
import { useRaf } from '@/components/Handlers'
import global from '@/global'
import credits from '@/utils/credits'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()
  const { scroll, speed, limit } = useContext(ScrollbarContext)
  const { isFontReady } = useContext(LoadingContext)

  useFontPreload({ fonts: ['TWKEverett', 'Dharma Gothic M'] })

  useEffect(() => {
    credits()
  }, [])

  const { mainNav } = useSelector((state) => ({
    extraNav: state.nav.extra_menu,
    headerNav: state.nav.header_menu,
    socialNav: state.nav.social_menu,
    languageNav: state.nav.language_menu,
    mainNav: state.nav.main_menu,
  }), shallowEqual)

  const { col1, col2 } = zustand.home

  useMouse(({ normalizedX, normalizedY }) => {
    // window.console.log('y ---->', normalizedY * 2 - 1)
    zustand.general.mouse.x = normalizedX * 2 - 1
    zustand.general.mouse.y = normalizedY * 2 - 1
  })

  useRaf(() => {
    if (scroll.current.y) {
      zustand.general.scrollY = scroll.current.y / limit.current.y
      zustand.general.speedScroll = Math.max(0, Math.min(0.1, Math.abs(speed.current * 0.001)))
    } else {
      zustand.general.scrollY = 0
      zustand.general.speedScroll = 0
    }
  }, [scroll])

  useEffect(() => {
    zustand.home.col1 = col1
    zustand.home.col2 = col2
  }, [col1, col2])

  return (
    <div className={`${classes.root} content-root`}>
      <ConditionalWrapper
        condition={process.env.NODE_ENV === 'production'}
        wrapper={(child) => <GoogleReCaptchaProvider reCaptchaKey={CONFIG.RECAPTCHA_SITE_KEY}>{child}</GoogleReCaptchaProvider>}
      >
        <Loader />
        <Overlay />
        <ModalBio />
        <Spinner />
        {isFontReady && (
        <Nav
          mainNav={mainNav}
        />
        )}
        <Header />
        <div className="wrapper">
          {children}
        </div>
        <SVGSprite />
      </ConditionalWrapper>
    </div>
  )
}

export default Layout
