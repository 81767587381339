import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import RevealText from '@/components/RevealText'
import zustand from '@/base/zustand'
import { removeParagraph, removeBr } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const Section9 = ({ className, speed = 2, text }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const $text = useRef()
  const classes = useStyles()
  const theme = useTheme()

  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['9'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-9')) {
        ScrollTrigger.getById('section-9').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-9')) {
        ScrollTrigger.getById('section-9').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-9',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .addLabel('start')
        .to(zustand.manifest.sections['9'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .fromTo($root.current.querySelectorAll('svg'), {
          x: 'random(-400vw, 400vw)',
          y: '100vh',
          rotate: 'random(-180, 180)',
          opacity: 1,
          scale: 1.5,
        }, {
          x: 'random(-20vw, 20vw)',
          y: '-100vh',
          rotate: 'random(-10, 10)',
          filter: 'blur(4px)',
          scale: 0.6,
          opacity: 0,
          stagger: 0.2,
          duration: 1.5,
          ease: 'linear',
        }, 'start')
        .to($text.current.querySelectorAll('.line > div'), {
          y: '0%',
          scaleY: 1,
          duration: 0.5,
          stagger: 0.05,
        }, 'start')

      if (theme.detect.debug) {
        window.console.log('section 9 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <RevealText
          value={removeParagraph(window.innerWidth > 768 ? text : removeBr(text))}
          ref={$text}
          className={classes.text}
          tag="div"
          type="lines"
        />
        <svg className={classes.ico}>
          <use xlinkHref="#ico-tshirt" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-cart" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-ticket" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-basket" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-money" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-tshirt" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-cart" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-ticket" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-basket" />
        </svg>
        <svg className={classes.ico}>
          <use xlinkHref="#ico-money" />
        </svg>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'none' }}>
        <symbol id="ico-tshirt" viewBox="0 0 80 80">
          <path d="M69.6,29.8L58.8,19c-0.9-0.9-2.2-1.5-3.5-1.5h-5.9c-2.6-2.6-5.9-4-9.4-4s-6.8,1.4-9.4,4h-5.9 c-1.3,0-2.6,0.5-3.5,1.5l-11,10.8c-0.4,0.4-0.4,1,0,1.4l8,8c0.4,0.4,1,0.4,1.4,0l2.3-2.3v27.6c0,0.6,0.4,1,1,1h34c0.6,0,1-0.4,1-1 V36.9l2.3,2.3c0.4,0.4,1,0.4,1.4,0l8-8C70,30.8,70,30.2,69.6,29.8z M32.4,18.4c2.1-1.9,4.7-2.9,7.5-2.9s5.4,1,7.5,2.9 c0,0.1,0,0.2,0,0.3c-4.6-1.7-10.5-1.7-15,0C32.4,18.6,32.4,18.5,32.4,18.4z M47.8,21.1c-0.5,2.4-3.9,4.4-7.9,4.4s-7.4-2-7.9-4.4 C36.5,19,43.3,19,47.8,21.1z M55.9,63.5h-32v-4h32V63.5z M60.9,37.1l-3.3-3.3c-0.3-0.3-0.7-0.4-1.1-0.2c-0.4,0.1-0.6,0.5-0.6,0.9v23 h-32v-23c0-0.4-0.2-0.8-0.6-0.9c-0.4-0.2-0.8-0.1-1.1,0.2l-3.3,3.3l-6.6-6.6l10.1-10.1c0.6-0.6,1.3-0.9,2.1-0.9h5.4c0,0.3,0,0.7,0,1 v0.1c0.1,3.8,4.5,6.9,10,6.9s9.9-3.1,10-6.9v-0.1c0-0.3,0-0.7-0.1-1h5.4c0.8,0,1.6,0.3,2.1,0.9l10.2,10.1L60.9, 37.1z" />
        </symbol>
        <symbol id="ico-cart" viewBox="0 0 80 80">
          <path d="M64.9,22.7H22.4l-2-7.8c-0.1-0.5-0.6-0.8-1.1-0.8h-4.3c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h3.5l1.9,7.8 l2.1,27.5c-1.3,0.8-2.1,2.1-2.1,3.7c0,2.2,1.7,4.1,3.8,4.3c-0.4,0.6-0.6,1.4-0.6,2.2c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3 c0-0.8-0.2-1.5-0.6-2.2h19.6c-0.4,0.6-0.6,1.4-0.6,2.2c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3c0-0.8-0.2-1.5-0.6-2.2h1.7 c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1H24.8c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.4,0,29.7,0,31.3,0 c3.1,0,5.7-2.3,6.2-5.3L66,23.9C66.1,23.3,65.6,22.7,64.9,22.7z M55.2,59.5c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2 s-2.2-1-2.2-2.2C53,60.5,54,59.5,55.2,59.5z M28.1,59.5c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2 C25.9,60.5,26.9,59.5,28.1,59.5z M60.2,47.3c-0.3,2-2,3.5-4.1,3.5c-2.7,0-30.4,0-31.4,0l-2-26h40.9L60.2,47.3z" />
          <path d="M29.2,30.2h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1 C28.1,29.8,28.6,30.2,29.2,30.2z" />
          <path d="M40,30.2h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1H40c-0.6,0-1.1,0.5-1.1,1.1 C38.9,29.8,39.4,30.2,40,30.2z" />
          <path d="M50.8,30.2h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1 C49.7,29.8,50.2,30.2,50.8,30.2z" />
          <path d="M29.2,38.9h5.4c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1S28.6,38.9,29.2,38.9z" />
          <path d="M40,38.9h5.4c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H40c-0.6,0-1.1,0.5-1.1,1.1S39.4,38.9,40,38.9z" />
          <path d="M50.8,38.9h5.4c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1S50.2,38.9,50.8,38.9z" />
          <path d="M29.2,46.5h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1 C28.1,46,28.6,46.5,29.2,46.5z" />
          <path d="M40,46.5h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1H40c-0.6,0-1.1,0.5-1.1,1.1 C38.9,46,39.4,46.5,40,46.5z" />
          <path d="M50.8,46.5h5.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-5.4c-0.6,0-1.1,0.5-1.1,1.1 C49.7,46,50.2,46.5,50.8,46.5z" />
        </symbol>
        <symbol id="ico-ticket" viewBox="0 0 80 80">
          <path d="M44.8,19.8c-0.2,0-0.5,0.1-0.6,0.3L18.9,45.4c-0.3,0.3-0.3,0.9,0,1.3l14.5,14.5c0.2,0.2,0.4,0.3,0.6,0.3 c0.2,0,0.5-0.1,0.6-0.3L60,35.8c0.3-0.3,0.3-0.9,0-1.3L45.5,20.1C45.3,19.9,45.1,19.8,44.8,19.8z M34,59.2L20.8,46L44.8,22 l13.2,13.2L34,59.2z" />
          <path d="M38.5,33.4c-0.5,0-0.9,0.4-0.9,0.9v14.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V34.3 C39.4,33.8,39,33.4,38.5,33.4z" />
          <path d="M43,38.4c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C45.2,39.4,44.2,38.4,43,38.4z M43,41 c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4S43.2,41,43,41z" />
          <path d="M34,40.2c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C36.2,41.2,35.2,40.2,34,40.2z  M34,42.8c-0.2,0-0.4-0.2-0.4-0.4S33.8,42,34,42s0.4,0.2,0.4,0.4S34.2,42.8,34,42.8z" />
          <path d="M67.2,12.8c-0.3-0.3-0.9-0.3-1.3,0l-5.8,5.8c-1-0.8-2.3-1.2-3.6-1.2c-1.6,0-3.1,0.6-4.2,1.8l-0.3,0.3l-4.6-4.6 c-0.7-0.7-1.7-1.1-2.7-1.1s-2,0.4-2.7,1.1L13.7,43.4c-0.7,0.7-1.1,1.7-1.1,2.7s0.4,2,1.1,2.7l4.6,4.6L18,53.5 c-1.1,1.1-1.8,2.6-1.8,4.2s0.6,3.1,1.8,4.2c2.4,2.3,6.2,2.3,8.5,0l0.3-0.3l4.6,4.6c0.7,0.7,1.7,1.1,2.7,1.1c1,0,1.9-0.4,2.7-1.1 l28.5-28.5c0.7-0.7,1.1-1.7,1.1-2.7c0-1-0.4-2-1.1-2.7l-4.6-4.6l0.3-0.3c1.1-1.1,1.8-2.6,1.8-4.2c0-1.3-0.4-2.5-1.2-3.6l5.8-5.8 C67.5,13.7,67.5,13.2,67.2,12.8z M60.8,23.4c0,1.1-0.4,2.2-1.2,3l-0.9,0.9c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6l5.2,5.2 c0.4,0.4,0.6,0.9,0.6,1.4s-0.2,1-0.6,1.4L35.4,65.1c-0.8,0.8-2,0.8-2.8,0l-5.2-5.2c-0.2-0.2-0.4-0.3-0.6-0.3 c-0.2,0-0.4,0.1-0.6,0.3l-0.9,0.9c-1.7,1.7-4.3,1.7-6,0c-0.8-0.8-1.2-1.9-1.2-3c0-1.1,0.4-2.2,1.2-3l0.9-0.9 c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6l-5.2-5.2c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.5,0.2-1,0.6-1.4l28.5-28.5 c0.4-0.4,0.9-0.6,1.4-0.6c0.5,0,1,0.2,1.4,0.6l5.2,5.2c0.3,0.3,0.9,0.3,1.3,0l0.9-0.9c0.8-0.8,1.9-1.2,3-1.2c0.8,0,1.6,0.2,2.3,0.7 l-2,2c-0.3,0.3-0.3,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3l2-2C60.6,21.8,60.8,22.6,60.8,23.4z" />
        </symbol>
        <symbol id="ico-basket" viewBox="0 0 80 80">
          <path d="M59.9,32.6h-7.5c0-0.2-0.1-0.3-0.2-0.4l-7.5-12.8c-0.3-0.6-1.1-0.8-1.6-0.4c-0.6,0.4-0.8,1.2-0.4,1.7l7,11.9 H30.8l5.6-12c0.3-0.6,0.1-1.4-0.5-1.7c-0.6-0.3-1.3-0.1-1.6,0.6l-5.9,12.8c0,0.1-0.1,0.2-0.1,0.3h-8.5c-2.6,0-4.6,2.2-4.6,4.7 c0,2.6,2,4.7,4.6,4.7h0.5l4,18.4c0.1,0.4,0.5,0.7,0.9,0.7H54c0.4,0,0.8-0.3,0.9-0.7l3.8-18.4h1.1c2.5,0,4.6-2.2,4.6-4.7 C64.5,34.8,62.4,32.6,59.9,32.6z M53.3,59.4H25.9l-3.7-17.3h34.7L53.3,59.4z M59.9,40.2h-1.6c-0.1,0-0.2-0.1-0.3-0.1H21.1 c-0.1,0-0.2,0-0.3,0.1h-1c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9h40c1.6,0,2.9,1.3,2.9,2.9C62.7,39,61.4,40.2,59.9,40.2z" />
          <path d="M41.5,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v12.4 C40.6,56.9,41,57.4,41.5,57.4z" />
          <path d="M45.6,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v12.4 C44.8,56.9,45.2,57.4,45.6,57.4z" />
          <path d="M49.3,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v12.4 C48.4,56.9,48.9,57.4,49.3,57.4z" />
          <path d="M29.9,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9S29,43.6,29,44.1v12.4 C29,56.9,29.4,57.4,29.9,57.4z" />
          <path d="M33.9,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v12.4 C33.1,56.9,33.5,57.4,33.9,57.4z" />
          <path d="M37.7,57.4c0.5,0,0.9-0.4,0.9-0.9V44.1c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v12.4 C36.8,56.9,37.2,57.4,37.7,57.4z" />
          <path d="M46.9,35.6H21.1c-0.3,0-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6h25.9c0.3,0,0.6-0.2,0.6-0.6 C47.5,35.8,47.3,35.6,46.9,35.6z" />
          <path d="M53,35.6h-3.7c-0.3,0-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6H53c0.3,0,0.6-0.2,0.6-0.6 C53.6,35.8,53.4,35.6,53,35.6z" />
        </symbol>
        <symbol id="ico-money" viewBox="0 0 80 80">
          <path
            d="M65,32.5c-0.1-1.4-1.8-2.5-5.2-3.4c0.1-0.2,0.2-0.5,0.2-0.8v-5.8h0c-0.3-4.9-20.2-5-22.5-5
 s-22.2,0.1-22.5,5h0v0.1l0,0l0,0v5.7c0,1.6,2,2.8,5.1,3.7c0,0.1-0.1,0.3-0.1,0.4h0v0.1l0,0l0,0v5.7c-3.3,0.8-4.9,1.9-5,3.3h0v0.1
 l0,0l0,0v5.7c0,1.1,1,2,2.6,2.8c-0.1,0.2-0.1,0.3-0.1,0.5h0v0.1l0,0l0,0v5.7c0,2.8,6.2,4.5,13.2,5.3c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
 c1.3,0.2,2.7,0.3,4,0.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0c1.7,0.1,3.4,0.2,5,0.2s3.2-0.1,5-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0
 c1.3-0.1,2.7-0.2,4-0.4c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c6.9-0.8,13.2-2.5,13.2-5.3v-5.8h0c-0.1-1-0.9-1.9-2.6-2.6
 c0.1-0.2,0.2-0.5,0.2-0.8V42c3-0.9,5-2.2,5-3.7L65,32.5L65,32.5z M58.2,47.8L58,48c-0.4,0.4-1.2,0.8-2.1,1.2v-4
 c1-0.3,1.8-0.7,2.5-1.1v3.4C58.3,47.6,58.3,47.7,58.2,47.8z M39.6,51.6c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6,0-0.9,0v-4.2
 c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0c0.8,0,1.7,0,2.5-0.1v4.2c-0.2,0-0.4,0-0.5,0C40.6,51.6,40.1,51.6,39.6,51.6z M36,51.7
 c-0.4,0-0.8,0-1.1,0c0,0-0.1,0-0.1,0c-0.5,0-0.9,0-1.4-0.1v-4.2c0.8,0,1.7,0.1,2.5,0.1c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0v4.2
 c-0.2,0-0.3,0-0.5,0C36.1,51.7,36,51.7,36,51.7z M20.9,49.8C20.9,49.7,20.9,49.7,20.9,49.8l-0.1-4c0.8,0.2,1.6,0.4,2.5,0.6v4.1
 c-0.8-0.2-1.5-0.3-2.1-0.5C21.1,49.8,21,49.8,20.9,49.8z M38.5,56.7c0.2,0,0.5,0,0.7,0v4.2c-1.2,0-2.3,0-3.3-0.1v-4.2
 c0.8,0,1.7,0.1,2.5,0.1C38.4,56.7,38.4,56.7,38.5,56.7z M41.7,56.7c0.8,0,1.7,0,2.5-0.1v4.2c-1.1,0.1-2.2,0.1-3.3,0.1v-4.2
 c0.2,0,0.5,0,0.7,0C41.6,56.7,41.6,56.7,41.7,56.7z M48.3,56.3v4.1c-0.8,0.1-1.6,0.2-2.5,0.2v-4.2C46.7,56.4,47.5,56.4,48.3,56.3z
  M50,56.1c0.9-0.1,1.7-0.2,2.5-0.4v4.1c-0.8,0.1-1.6,0.3-2.5,0.4V56.1z M54.2,55.4c0.9-0.2,1.7-0.4,2.5-0.6v4
 c-0.7,0.2-1.6,0.4-2.5,0.6V55.4z M25.8,55.4v4.1c-0.9-0.2-1.8-0.4-2.5-0.6v-4C24.1,55.1,24.9,55.3,25.8,55.4z M27.5,55.7
 c0.8,0.1,1.6,0.2,2.5,0.4v4.1c-0.9-0.1-1.7-0.2-2.5-0.4V55.7z M31.7,56.3c0.8,0.1,1.7,0.1,2.5,0.2v4.2c-0.9-0.1-1.7-0.1-2.5-0.2
 V56.3z M29.6,51.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0v-4.1c0.8,0.1,1.7,0.1,2.5,0.2v4.2C31,51.4,30.3,51.4,29.6,51.3z
  M27.5,46.9V51c-0.9-0.1-1.7-0.2-2.5-0.4v-4.1C25.8,46.7,26.6,46.8,27.5,46.9z M43.9,51.4c-0.2,0-0.4,0-0.6,0v-4.2
 c0.8-0.1,1.7-0.1,2.5-0.2v4.1c-0.5,0.1-1,0.1-1.6,0.1C44.1,51.4,44,51.4,43.9,51.4z M47.5,51v-4.1c0.9-0.1,1.7-0.2,2.5-0.4v4.1
 C49.2,50.8,48.4,50.9,47.5,51z M51.7,46.3c0.9-0.2,1.7-0.4,2.5-0.6v4c-0.7,0.2-1.6,0.4-2.5,0.6V46.3z M40.3,42.5
 c-0.6,0-1.2,0-1.8-0.1c0,0-0.1,0-0.1,0v-4.2c0.8,0,1.7,0.1,2.5,0.1c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0v4.2c-0.4,0-0.8,0-1.2,0
 C40.4,42.5,40.3,42.5,40.3,42.5z M20.8,30.6v-4c0.8,0.2,1.6,0.4,2.5,0.6v4.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.9-0.2-1.3-0.3
 C21.4,30.7,21.1,30.6,20.8,30.6z M36,28.3c0.2,0,0.5,0,0.7,0v4.2c-1.1,0-2.2,0-3.3-0.1v-4.2c0.8,0,1.7,0.1,2.5,0.1
 C35.9,28.3,35.9,28.3,36,28.3z M39.2,28.3c0.8,0,1.7,0,2.5-0.1v4.2c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0c-0.6,0-1.1,0-1.7,0.1
 c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0v-4.2c0.2,0,0.5,0,0.7,0C39.1,28.3,39.1,28.3,39.2,28.3z M58.1,28.7L58.1,28.7L57.8,29
 c-0.4,0.3-1.1,0.7-2,1v-4c1-0.3,1.8-0.7,2.5-1.1v3.4C58.3,28.5,58.3,28.6,58.1,28.7z M47.5,31.9v-4.1c0.9-0.1,1.7-0.2,2.5-0.4v4.1
 C49.2,31.6,48.4,31.8,47.5,31.9z M51.7,31.2v-4.1c0.9-0.2,1.7-0.4,2.5-0.6v4C53.4,30.8,52.6,31,51.7,31.2z M45.8,27.9v4.1
 c-0.8,0.1-1.6,0.2-2.5,0.2v-4.2C44.2,28.1,45,28,45.8,27.9z M29.2,32.1v-4.1c0.8,0.1,1.7,0.1,2.5,0.2v4.1
 C30.8,32.2,30,32.2,29.2,32.1z M27.5,27.8v4.1c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.2,0-0.3,0c-0.5-0.1-1-0.1-1.4-0.2c-0.1,0-0.2,0-0.3,0
 v-4.1C25.8,27.5,26.6,27.7,27.5,27.8z M36.5,42.3c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1-0.1-1.6-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0v-4.1
 c0.8,0.1,1.7,0.1,2.5,0.2v4.2C36.6,42.3,36.5,42.3,36.5,42.3z M31,41.7c-0.3-0.1-0.6-0.1-1-0.2v-4.1c0.8,0.1,1.6,0.2,2.5,0.4v4.1
 C32,41.8,31.5,41.7,31,41.7C31,41.7,31,41.7,31,41.7z M28.3,37.1v4.1c-0.9-0.2-1.8-0.4-2.5-0.6v-4C26.6,36.7,27.4,36.9,28.3,37.1z
  M24.2,36v4c-1.3-0.5-2.2-1-2.4-1.5l-0.1-0.1c0,0,0-0.1,0-0.1v-3.5C22.3,35.3,23.2,35.7,24.2,36z M44.2,38.3c0.8,0,1.7,0,2.5-0.1
 v4.2c-1.1,0.1-2.2,0.1-3.3,0.1v-4.2c0.2,0,0.5,0,0.7,0C44.1,38.3,44.1,38.3,44.2,38.3z M54.9,41.5c-0.4,0.1-0.7,0.1-1.1,0.2
 c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0-0.4,0.1-0.6,0.1v-4.1c0.9-0.1,1.7-0.2,2.5-0.4L54.9,41.5C55,41.5,54.9,41.5,54.9,41.5z M49.3,42.2
 c-0.3,0-0.6,0-0.9,0.1v-4.1c0.8-0.1,1.7-0.1,2.5-0.2v4.1C50.3,42.1,49.9,42.2,49.3,42.2C49.3,42.2,49.3,42.2,49.3,42.2z M58.8,40.7
 c-0.4,0.1-0.9,0.2-1.3,0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.1,0-0.2,0v-4.1c0.9-0.2,1.7-0.4,2.5-0.6v4C59,40.6,58.9,40.6,58.8,40.7z
  M63.3,32.6c-0.1,0.6-1.4,1.3-3.6,2l0,0c-2.9,0.9-7.6,1.7-13.4,2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.5,0-0.8,0c-0.7,0-1.4,0-2.1,0
 s-1.4,0-2.1,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.9,0c-5.8-0.3-10.5-1.1-13.4-2l0,0c-2.3-0.7-3.6-1.4-3.6-2c0,0,0,0,0,0
 c0.1,0,0.1,0,0.2,0c0.3,0.1,0.7,0.2,1,0.2c1.7,0.4,3.5,0.6,5.4,0.9c0,0,0,0,0,0c0,0,0,0,0,0c1.3,0.2,2.7,0.3,4.1,0.4c0,0,0,0,0,0
 c0,0,0,0,0,0c1,0.1,2.1,0.1,3.1,0.1c0.6,0,1.3,0,1.9,0c0.4,0,0.9,0,1.3,0c6.1-0.1,11.7-0.8,15.6-1.9c0.2-0.1,0.4-0.1,0.5-0.2
 c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
 c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1C62.2,31.3,63.2,32.2,63.3,32.6z
  M37.5,19.2c13.7,0,20.7,2.2,20.8,3.4c-0.1,0.6-1.4,1.3-3.6,2l0,0c-2.9,0.9-7.6,1.7-13.4,2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.5,0-0.8,0
 c-0.7,0-1.4,0-2.1,0s-1.4,0-2.1,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.9,0c-5.8-0.3-10.5-1.1-13.4-2l0,0c-2.3-0.7-3.5-1.4-3.6-2
 C16.8,21.3,23.8,19.2,37.5,19.2z M16.7,28.3v-3.5c0.7,0.4,1.5,0.8,2.5,1.1v4C17.5,29.4,16.7,28.8,16.7,28.3z M20.7,39.9
 C20.7,39.9,20.7,39.9,20.7,39.9c1.2,1.2,3.5,2.1,6.5,2.8c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.7,0.1c2.7,0.6,5.9,1,9.4,1.2
 c0,0,0,0,0,0s0,0,0,0c1.1,0.1,2.3,0.1,3.5,0.1c0.5,0,1,0,1.5,0c0.6,0,1.2,0,1.8,0c1.1,0,2.2-0.1,3.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0
 c3.5-0.2,6.8-0.6,9.6-1.2c-0.6,0.3-1.4,0.6-2.5,1l0,0c-3.1,1-8.1,1.8-14.4,2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0
 c-0.5,0-1.1,0-1.6,0c-0.7,0-1.4,0-2.1,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.9,0c-5.8-0.3-10.5-1.1-13.4-2l0,0
 c-2.3-0.7-3.6-1.5-3.6-2C16.8,41.4,17.6,40.6,20.7,39.9z M16.7,47.5V44c0.7,0.4,1.5,0.8,2.5,1.1v4L18.7,49l0,0
 C17.4,48.4,16.7,47.9,16.7,47.5z M19.2,56.7v-3.5c0.7,0.4,1.5,0.8,2.5,1.1v4C20,57.7,19.2,57.1,19.2,56.7z M58.3,58.3v-4
 c1-0.3,1.8-0.7,2.5-1.1v3.4C60.8,57.1,60,57.7,58.3,58.3z M60.8,50.9c-0.1,0.6-1.4,1.3-3.6,2l0,0c-2.9,0.9-7.6,1.7-13.4,2
 c-0.3,0-0.6,0-0.9,0c-0.2,0-0.5,0-0.8,0c-0.7,0-1.4,0-2.1,0s-1.4,0-2.1,0c-0.3,0-0.5,0-0.7,0c-0.3,0-0.6,0-0.9,0
 c-5.8-0.3-10.5-1.1-13.4-2l0,0c-2.2-0.7-3.5-1.4-3.6-2c0.2,0.1,0.5,0.2,0.8,0.3c0,0,0.1,0,0.1,0c0.2,0.1,0.5,0.1,0.7,0.2
 c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c3,0.8,6.9,1.3,11.1,1.6c0,0,0,0,0,0c0,0,0,0,0,0c1.3,0.1,2.6,0.1,3.9,0.1
 c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.4,0c1.2,0,2.4-0.1,3.6-0.1c0,0,0,0,0,0c0,0,0,0,0,0c1.4-0.1,2.8-0.2,4.1-0.4c0,0,0,0,0,0
 c0,0,0,0,0.1,0c1.5-0.2,2.9-0.4,4.2-0.6c0.3,0,0.5-0.1,0.8-0.2c0.1,0,0.1,0,0.2,0c2.5-0.5,4.6-1.2,6.1-2c0.1,0,0.2-0.1,0.2-0.1
 c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2C60.3,50.2,60.8,50.7,60.8,50.9z M60.8,40v-4c1-0.3,1.8-0.7,2.5-1.1v3.4
 C63.3,38.8,62.5,39.4,60.8,40z"
          />
        </symbol>
      </svg>

    </section>
  )
}
export default Section9
