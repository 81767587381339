import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'
import CreateJs from 'preload-js'
import gsap from 'gsap'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import Meta from '@/components/Meta'
import CanvasManifesto from '@/components/CanvasManifesto'
import ScrollIndicator from '@/components/ScrollIndicator'
import useScrollTrigger from '@/hooks/useScrollTrigger'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import zustand from '@/base/zustand'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section7 from './Section7'
import Section8 from './Section8'
import Section9 from './Section9'
import Section10 from './Section10'
import Section11 from './Section11'
import style from './style'

const useStyles = createUseStyles(style)

const Manifesto = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const { scrollbar } = useContext(ScrollbarContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const [isFirstTime, setFirstTime] = useState(true)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()
  const $overlay = useRef()
  const vw = useRef(window.innerWidth)

  const { animationReady } = useScrollTrigger()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, strings, socialNav, teamList, params } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    teamList: state.content.cpt._cty_team || [], // eslint-disable-line
    strings: state.options.strings,
    socialNav: state.nav.social_menu,
    params: state.router.location,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug, child) => dispatch(contentActions.fetchPage(slug, child)), [dispatch])
  const fetchCpts = useCallback((cpt) => dispatch(contentActions.fetchCpts(cpt)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (page.featured_image) preload.current.loadFile({ src: page.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && teamList.length > 0) preloadMedia()
  }, [page, teamList])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage, true)
    fetchCpts('_cty_team')
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    vw.current = window.innerWidth
    fetchData(stub.current)
  }

  /*------------------------------
  Resize
  ------------------------------*/
  const handleResize = () => {
    if (vw.current !== window.innerWidth) {
      window.location.reload()
    }
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
    gsap.to('body', {
      background: 'linear-gradient(#2d2b44, #2d2b44)',
    })

    window.addEventListener('resize', handleResize)

    return (() => {
      window.removeEventListener('resize', handleResize)
    })
  }, [])

  /*------------------------------
  Open Overlay
  ------------------------------*/
  const openOverlay = () => {
    gsap.fromTo($overlay.current, {
      y: '100%',
    }, {
      y: 0,
      ease: 'power3.out',
      duration: 0.5,
    })
  }

  /*------------------------------
  Close Overlay
  ------------------------------*/
  const closeOverlay = () => {
    gsap.to($overlay.current, {
      y: '-100%',
      ease: 'power3.out',
      duration: 0.5,
      delay: 1,
    })
  }

  /*------------------------------
  Animation Ready
  ------------------------------*/
  useEffect(() => {
    if (animationReady && scrollbar) {
      setTimeout(() => {
        scrollbar.update()
      }, 300)
    }
    if (animationReady && isFirstTime) {
      setFirstTime(false)
      if (params) {
        const { partner } = params.query
        if (partner && scrollbar) {
          setTimeout(() => {
            scrollbar.update()
            scrollbar.scroll.scrollTo(`#${partner}`, { disableLerp: true, duration: 0 })
          }, 700)
        }
      }
    }
  }, [animationReady, params, isFirstTime, scrollbar])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) {
      setPageAnimationReady(true)
      setTimeout(() => {
        gsap.killTweensOf([
          zustand.manifest.sections['1'],
          zustand.manifest.sections['2'],
          zustand.manifest.sections['3'],
          zustand.manifest.sections['4'],
          zustand.manifest.sections['5'],
          zustand.manifest.sections['6'],
          zustand.manifest.sections['7'],
          zustand.manifest.sections['8'],
          zustand.manifest.sections['9'],
          zustand.manifest.sections['10'],
        ])
        gsap.set([
          zustand.manifest.sections['1'],
          zustand.manifest.sections['2'],
          zustand.manifest.sections['3'],
          zustand.manifest.sections['4'],
          zustand.manifest.sections['5'],
          zustand.manifest.sections['6'],
          zustand.manifest.sections['7'],
          zustand.manifest.sections['8'],
          zustand.manifest.sections['9'],
          zustand.manifest.sections['10'],
        ], {
          progress: 0,
        })
      }, 100)
    }
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>

          {/* #1 What’s perfection? */}
          <Section1 className={classes.section} speed={2} title={page.acf?.section1.title} text={page.acf?.section1.text} />

          {/* #2 Moneta Magento 2 */}
          <Section8 className={classes.section} speed={6.1} />

          {/* #3 Icone Magento */}
          <Section9 className={classes.section} speed={3.5} text={page.acf?.magento.text} />

          {/* #4 Danilo */}
          <Section5 className={classes.section} speed={6.5} manifestSection={5} id="danilo-argentiero" progressName="danilo" people={teamList.find((team) => team.slug === 'danilo-argentiero')} />

          {/* #5 Riccardo */}
          <Section5 className={classes.section} speed={6.5} manifestSection={6} id="riccardo-ugolini" progressName="riccardo" people={teamList.find((team) => team.slug === 'riccardo-ugolini')} />

          {/* #6 We are ghost unicorns */}
          <Section7 className={classes.section} speed={4.5} text={page.acf?.weare.intro_text} />

          {/* #7 This is the true essence of perfection */}
          <Section4 className={classes.section} speed={5.5} />

          {/* #8 We are the ones who create -  We are the silent ones */}
          <Section2 className={classes.section} speed={3} titleTop={page.acf?.weare.title1_top} titleBottom={page.acf?.weare.title1_bottom} text={page.acf?.weare.text1} />

          {/* #9 We leave no marks - No traces, no errors */}
          <Section3 className={classes.section} speed={3} titleTop={page.acf?.weare.title2_top} titleBottom={page.acf?.weare.title2_bottom} text={page.acf?.weare.text2} />

          {/* #10 Sheets */}
          <Section10 className={classes.section} speed={8.1} sheets={page.acf?.sheet.sheets} />

          {/* #11 What we do */}
          <Section11 className={classes.section} speed={1} openOverlay={openOverlay} closeOverlay={closeOverlay} link={page.acf?.link.next_link_url} title={page.acf?.link.next_link_label} />

        </div>
      </>
    )
  }

  return load && (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageManifesto ${classes.root}`}
        ref={$root}
        lerp={0.03}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      <ScrollIndicator load={load} social={socialNav} splitted={[0, 0.28, 0.72]} label={strings.scroll_to_discover_label} />
      <CanvasManifesto teamList={teamList} load={load} />
      <div
        ref={$overlay}
        className={classes.overlay}
      >
        <span role="img" aria-label="back to top">
          🚀
        </span>
      </div>
    </>
  )
}

export default Manifesto
