/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from 'react'
import gsap from 'gsap'

const RevealButton = ({
  className,
  children,
  duration,
  ease,
  delay,
  visible,
  inview,
  tag,
  ...props
}) => {
  const $root = useRef()
  const Tag = tag

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    gsap.set($root.current, {
      y: '120%',
      transformOrigin: 'center top',
      pointerEvents: 'none',
    })
  }, [])

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    gsap.to($root.current, {
      y: (visible || inview) ? '0%' : '110%',
      scaleY: (visible || inview) ? 1 : 2,

      duration,
      ease,
      delay,
      onComplete: () => {
        gsap.to($root.current, {
          pointerEvents: (visible || inview) ? 'all' : 'none',
        })
      },
    })
  }, [visible, inview])

  return (
    <Tag className={className} {...props}>
      <div ref={$root}>
        {children}
      </div>
    </Tag>
  )
}

/*------------------------------
Default Props
------------------------------*/
RevealButton.defaultProps = {
  className: '',
  tag: 'div',
  duration: 1.3,
  delay: 0,
  ease: 'power3.out',
  visible: false,
  inview: false,
}

export default RevealButton
