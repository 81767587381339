import { useMemo, useRef } from 'react'
import { MathUtils, DoubleSide } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useTexture } from '@react-three/drei'
import zustand from '@/base/zustand'

/*------------------------------
Picture
------------------------------*/
const Picture = ({ image, name }) => {
  const $picture = useRef()
  const texture = useTexture(image)
  const { viewport } = useThree()

  const shaderArgs = useMemo(() => {
    return ({
      side: DoubleSide,
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uSpeed: { value: 0 },
        uTexture: { value: texture },
      },
      vertexShader: /* glsl */`
        varying vec2 vUv;
        varying vec3 vPos;
        uniform float uTime;
        uniform float uSpeed;
        #define PI 3.141592653589

        vec3 distort(vec3 pos) {
          pos.x += sin(pos.y * .3 + uSpeed * 10. + uTime ) * .2;
          pos.y += sin(pos.x * .4 + uSpeed * 10. + uTime ) * .2;
          pos.z += sin(pos.y * .5 + uTime ) * .4;
          return pos;
        }

        void main() {
          vec3 pos = distort(position);
          vPos = distort(normalize(normal));
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
        }
      `,
      fragmentShader: /* glsl */`
        varying vec2 vUv;
        varying vec3 vPos;
        uniform float uSpeed;
        uniform sampler2D uTexture;

        void main() {
          vec2 uv = vUv;
          vec3 finalColor = texture2D(uTexture, uv).rgb;
          float light = vPos.z * 0.04;
          finalColor = finalColor - light;
          gl_FragColor = vec4(finalColor, 1. - finalColor.r);
        }
      `,
    })
  }, [])

  useFrame(({ clock }) => {
    // window.console.log(' ---->', name, zustand.manifest.progress[name])
    shaderArgs.uniforms.uTime.value = clock.getElapsedTime()
    shaderArgs.uniforms.uSpeed.value = MathUtils.lerp(shaderArgs.uniforms.uSpeed.value, zustand.general.speedScroll, 0.1)
    $picture.current.rotation.z = MathUtils.mapLinear(zustand.manifest.progress[name], 0, 1, -Math.PI * 0.1, Math.PI * 0.1)
    $picture.current.rotation.y = MathUtils.mapLinear(zustand.manifest.progress[name], 0, 1, -Math.PI, Math.PI)
    $picture.current.position.y = MathUtils.mapLinear(zustand.manifest.progress[name], 0, 1, -viewport.height, viewport.height)
  })

  return (
    <mesh ref={$picture} position={[0, -viewport.height, 0]}>
      <planeBufferGeometry args={[5, 8, 32, 32]} />
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  )
}

export default Picture
