/* eslint-disable class-methods-use-this */
import {
  ShaderMaterial,
  UniformsUtils,
  MathUtils,
  Color,
} from 'three'
import { Pass, FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass'
import zustand from '@/base/zustand'

class MaskPass extends Pass {
  constructor() {
    super()
    this.time = 0

    const shader = {

      uniforms: {
        uTex: { value: null },
        uTime: { value: 0 },
        uRadius: { value: 0.0 },
        uSpeed: { value: 0 },
        uMinRadius: { value: 0.15 },
        uAspect: { value: { x: window.innerWidth, y: window.innerHeight } },
        uColor1: { value: new Color('#ee759a') },
        uColor2: { value: new Color('#6f53fe') },
      },

      vertexShader: /* glsl */`
    
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,

      fragmentShader: /* glsl */`
        uniform sampler2D uTex;
        uniform float uTime;
        uniform vec2 uAspect;
        uniform float uRadius;
        uniform vec3 uColor1;
        uniform vec3 uColor2;
        uniform float uMinRadius;
        uniform float uSpeed;
        varying vec2 vUv;

        void main() { 
          float aspect = max(uAspect.x / uAspect.y, uAspect.y / uAspect.x);
          vec2 uv = vUv;
          vec3 color = texture2D(uTex, uv).rgb;

          vec2 uvMask = vUv;
          uvMask -= .5;

          if (uAspect.x / uAspect.y > 1.) {
            uvMask.x *= aspect;
          } else {
            uvMask.y *= aspect;
          }
          // uvMask.x += sin((uSpeed * 10. + 15.) * uv.y + uSpeed * 100.) * (0.004 + uSpeed * 0.5);
          // uvMask.y += cos((uSpeed * 10. + 15.) * uv.x + uSpeed * 100.) * (0.004 + uSpeed) * 0.5;
          uvMask.x += sin(20. * uv.y + uTime) * (0.005 + uSpeed);
          uvMask.y -= cos(20. * uv.x + uTime) * (0.005 + uSpeed);
          float c = length(uvMask);


          float radius = uRadius * aspect / 1.5;
          radius = radius * (1. - uMinRadius) + uMinRadius ;
          c = smoothstep(radius, radius + 0.001, c);

          vec3 gradientBg = mix(uColor2, uColor1, vUv.y);
          vec3 finalColor = mix(color, gradientBg, c);

          gl_FragColor = vec4(finalColor, 1.);

        }`,
    }

    this.uniforms = UniformsUtils.clone(shader.uniforms)

    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
    })

    this.fsQuad = new FullScreenQuad(this.material)
  }

  render(renderer, writeBuffer, readBuffer /* , deltaTime, maskActive */) {
    this.time += 0.05
    this.uniforms.uTex.value = readBuffer.texture
    this.uniforms.uTime.value = this.time

    this.uniforms.uRadius.value = MathUtils.lerp(this.uniforms.uRadius.value, zustand.general.scrollY, 0.1)
    this.uniforms.uSpeed.value = MathUtils.lerp(this.uniforms.uSpeed.value, zustand.general.speedScroll, 0.1)

    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      this.fsQuad.render(renderer)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      this.fsQuad.render(renderer)
    }
  }
}

export { MaskPass }
