import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import zustand from '@/base/zustand'
import CircularText from '@/components/CircularText'
import style from './style'

const useStyles = createUseStyles(style)

const Section8 = ({ className, speed = 2 }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const $circularWrap = useRef()
  const $circular = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['8'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-8')) {
        ScrollTrigger.getById('section-8').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-8')) {
        ScrollTrigger.getById('section-8').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-8',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .addLabel('start')

        .to(zustand.manifest.progress, {
          logo: 0.5,
          duration: 2,
        }, 'start')
        .fromTo($circularWrap.current.querySelectorAll('.circular-letter-wrap'), {
          opacity: 0,
        }, {
          opacity: 1,
          stagger: 0.05,
          duration: 0.2,
        }, 'start+=1')
        .addLabel('logo')
        .to($circularWrap.current, {
          rotation: 360,
          ease: 'linear',
          duration: 5,
        }, 'start')
        .to(zustand.manifest.sections['8'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .to($circularWrap.current.querySelectorAll('.circular-letter-wrap'), {
          opacity: 0,
          stagger: 0.05,
          duration: -0.1,
        }, 'logo+=1')
        .to(zustand.manifest.progress, {
          logo: 1,
          duration: 2,
          ease: 'power1.in',
        }, 'logo+=2')
        .to($circular.current, {
          opacity: 0,
        }, 'logo+=2')

      if (theme.detect.debug) {
        window.console.log('section 8 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <div ref={$circularWrap}>
          <div ref={$circular} className={classes.circularTextWrap}>
            <CircularText
              className={classes.circularText}
              space={true}
              radius={window.innerHeight * 0.1}
            >
              MAGENTO 2 MAGENTO 2
            </CircularText>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Section8
