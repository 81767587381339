import { useState, useMemo, useEffect, useRef, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import useSound from 'use-sound'
import gsap from 'gsap'
import classNames from 'classnames'
import { useRaf } from '@/components/Handlers'
import RevealText from '@/components/RevealText'
import { Context as AudioContext } from '@/context/audio'
import { loopArray } from '@/utils/array'
import usePrevious from '@/hooks/usePrevious'
import pastAudio from '@/assets/audio/oh-no.mp3'
import style from './style'

const useStyles = createUseStyles(style)

const MonthSelector = ({ className, handleChange, active, stringPast }) => {
  const classes = useStyles()
  const { setPlayHoverSound } = useContext(AudioContext)
  const [playNoAudio, {
    sound: soundNo,
  }] = useSound(pastAudio)

  const time = useRef(0)
  const $stroke1 = useRef()
  const $stroke2 = useRef()
  const $stroke3 = useRef()
  const $stroke4 = useRef()
  const $stroke5 = useRef()
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const years = useMemo(() => (Array(5).fill().map((y, ind) => year + ind)), [])
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const [activeMonth, setActiveMonth] = useState(month)
  const [activeYear, setActiveYear] = useState(0)
  const [isNotAvailable, setNotAvailable] = useState(false)
  const prevNotAvailable = usePrevious(isNotAvailable)

  useEffect(() => {
    setNotAvailable(activeYear === 0 && activeMonth < month)
    handleChange({ month: months[activeMonth], year: years[activeYear], available: !isNotAvailable })

    if (isNotAvailable && !prevNotAvailable) {
      soundNo.volume(0.5)
      playNoAudio()
    }
  }, [activeMonth, activeYear, isNotAvailable])

  const handleYear = (dir) => {
    setActiveYear(loopArray(years, activeYear, dir === 'prev' ? -1 : 1))
  }

  const handleMonth = (dir) => {
    setActiveMonth(loopArray(months, activeMonth, dir === 'prev' ? -1 : 1))
  }

  useRaf(() => {
    if (active) {
      time.current += 0.08

      gsap.set($stroke1.current, {
        x: Math.cos(time.current) * 3,
        y: Math.sin(time.current) * 2,
      })
      gsap.set($stroke2.current, {
        x: Math.cos(time.current * 1.1) * 3,
        y: Math.sin(time.current * 0.8) * 3,
      })
      gsap.set($stroke3.current, {
        x: Math.sin(time.current * 0.8) * 3,
        y: Math.cos(time.current * 1.1) * 3,
      })
      gsap.set($stroke4.current, {
        x: Math.sin(time.current * 0.8) * 4,
        y: Math.cos(time.current * 1.2) * 2,
      })
      gsap.set($stroke5.current, {
        x: Math.cos(time.current * 0.8) * 3,
        y: Math.sin(time.current * 1.2) * 2,
      })
    }
  }, [active])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.wrapper}>
        <div className={classNames({
          [classes.header]: true,
          [classes.months]: true,
        })}
        >
          <button
            className={classes.button}
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => handleMonth('prev')}
          >
            <svg><use xlinkHref="#ico-arrow-left" /></svg>
          </button>
          <div className={classes.text}>
            {months.map((m, index) => (
              <RevealText
                className={classes.textSpan}
                tag="div"
                type="chars"
                lineHeight={0.7}
                enterDelay={0.05}
                exitDelay={0}
                value={m}
                duration={0.7}
                stagger={0.02}
                visible={activeMonth === index}
                key={m}
              />
            ))}
          </div>
          <button
            className={classes.button}
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => handleMonth('next')}
          >
            <svg><use xlinkHref="#ico-arrow-right" /></svg>
          </button>
        </div>
        <div className={classNames({
          [classes.header]: true,
          [classes.years]: true,
        })}
        >
          <button
            className={classes.button}
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => handleYear('prev')}
          >
            <svg><use xlinkHref="#ico-arrow-left" /></svg>
          </button>
          <div className={classes.text}>
            {years.map((y, index) => (
              <RevealText
                className={classes.textSpan}
                tag="div"
                type="chars"
                lineHeight={0.7}
                enterDelay={0.05}
                exitDelay={0}
                value={y}
                duration={0.7}
                stagger={0.02}
                visible={activeYear === index}
                key={y}
              />
            ))}

          </div>
          <button
            className={classes.button}
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => handleYear('next')}
          >
            <svg><use xlinkHref="#ico-arrow-right" /></svg>
          </button>
        </div>
      </div>
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 400 450"
        className={classes.svg}
      >
        <path
          ref={$stroke1}
          style={{ fill: 'none', stroke: '#AAAEFF', strokeWidth: 2, strokeMiterlimit: 10 }}
          d="M297.1,407.9c-6.5,0.9-13.1,1.5-19.8,1.8c-58.3,2.7-115.4,1.7-173.6-1.5c-22.4-1.2-42.3-9.5-56.6-27.3 c-16.4-20.4-15.7-46-14.8-70.9c2.3-62.4-0.1-124.4-1-186.7c-0.6-39,12.7-82,59.1-82c34.1,0,68,3.8,102,4.5 c34.2,0.7,68.2-7.5,102.3-5.8c46,2.3,75.5,37.8,75.9,82.3c0.6,61,0.4,122,0.6,183.1c0.1,15.8,0.1,31.8-3.7,47.1 C358.5,389.1,329.7,403.2,297.1,407.9z"
        />
        <path
          ref={$stroke2}
          style={{ fill: 'none', stroke: '#89C3EB', strokeMiterlimit: 10 }}
          d="M105.5,36.7c6.4-1,12.9-1.5,19.4-1.8c59-2.8,117.9-4.1,176.8,0.3c16.4,1.2,33.5,5.5,45.5,17.2 c32.2,31.5,17.4,92.7,15.7,132.7c-2.2,51.1-2.9,102.1-4.1,153.2c-0.8,31.3-10.9,63.7-46.3,63.7c-67.2,0-133.9,8-201,9.6 c-49.2,1.2-72.4-58-72.8-101.5c-0.4-40.3-3.3-80.6-4.9-120.9c-1.5-36.8-8.3-83.5,10.9-116.6C57.8,50,81.1,40.3,105.5,36.7z"
        />
        <path
          ref={$stroke3}
          style={{ fill: 'none', stroke: '#C59FFF', strokeWidth: 2, strokeMiterlimit: 10 }}
          d="M314.8,413.4c-6.3,1-53.2,5.9-59.7,6.3c-58.5,2.9-89.8,3.2-148.3-1.4c-16.3-1.3-33.3-5.7-45.2-17.8 C29.7,367.8,44.4,304.5,46,263.1c2.2-52.8-3.1-106.5,4.1-158.4c5.8-42.1-9.3-76.4,40.2-76.4c66.7,0,139,6.8,205.3,0.5 c71.7-6.8,71.8,59.9,72.2,104.9c0.4,41.7-5.2,91.9-3.6,133.5c1.5,38,16.6,77.8-2.3,112C348.8,402.9,339,409.6,314.8,413.4z"
        />
        <path
          ref={$stroke4}
          style={{ fill: '#AAAEFF' }}
          d="M29,149.2c22.1-1.8,44.4-2.5,66.6-2.1c22.4,0.4,44.7,2,66.9,4.8c22.3,2.7,44.5,6.8,66.4,11.6 c21.8,4.8,43.5,9.7,65.7,11.6c22.3,1.9,44.7,1.2,66.8-2c2.8-0.4,5.5-0.8,8.3-1.3c1.9-0.3,1.1-3.2-0.8-2.9 c-22.1,3.8-44.6,5.1-67,3.8c-22.4-1.3-44.2-5.8-66-10.6c-21.8-4.8-43.7-9.1-65.8-12.1c-22-2.9-44.1-4.8-66.2-5.5 c-22.2-0.7-44.4-0.3-66.6,1.2c-2.8,0.2-5.5,0.4-8.3,0.6C27.1,146.3,27.1,149.3,29,149.2L29,149.2z"
        />
        <path
          ref={$stroke5}
          style={{ fill: '#C59FFF' }}
          d="M39.8,156.2c21.7-1,43.4,0.1,65,2.2c21.8,2.1,43.5,5.2,65.3,7.8c10.8,1.3,21.5,2.5,32.3,3.4 c10.8,0.9,21.8,1.7,32.7,1.6c10.7-0.1,21.2-1.4,31.5-4.6c10-3.1,19.7-7.3,29.8-10.1c10.5-3,21.3-4.7,32.1-5.9 c10.9-1.2,21.9-2,32.9-2.3c2.7-0.1,5.4-0.1,8.1-0.1c1,0,1-1.5,0-1.5c-11,0.1-22,0.6-33,1.6c-10.5,0.9-21,2.2-31.3,4.4 c-10.5,2.3-20.4,5.9-30.5,9.5c-10.3,3.7-20.6,6.4-31.6,7.1c-10.8,0.7-21.6,0.1-32.3-0.7c-10.9-0.8-21.8-1.9-32.7-3.1 c-21.7-2.5-43.3-5.5-65-7.9c-21.7-2.3-43.5-3.9-65.3-3.4c-2.7,0.1-5.3,0.2-8,0.3C38.8,154.8,38.8,156.3,39.8,156.2L39.8,156.2z"
        />
      </svg>
      <RevealText
        className={classNames({
          [classes.notAvailable]: true,
        })}
        lineHeight={0.95}
        tag="div"
        type="lines"
        value={stringPast}
        duration={0.7}
        visible={isNotAvailable}
      />
    </div>
  )
}

export default MonthSelector
