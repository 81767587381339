const style = () => {
  const output = {
    root: {
      position: 'fixed!important',
      // height: theme.detect.isNativeScroll ? 'calc(var(--vh-fixed) + 110px)!important' : 'var(--vh-fixed)!important',
      height: 'var(--vh)!important',
      width: '100vw!important',
      top: 0,
      left: 0,
      zIndex: 10,
      pointerEvents: 'none',
    },
  }

  return output
}

export default style
