import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import zustand from '@/base/zustand'
import style from './style'

const useStyles = createUseStyles(style)

const Section10 = ({ className, speed = 2, sheets }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const pause = {
    x: 0,
  }
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['10'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-10')) {
        ScrollTrigger.getById('section-10').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-10')) {
        ScrollTrigger.getById('section-10').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-10',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .addLabel('start')
        .fromTo($root.current.querySelectorAll('.sheet'), {
          opacity: 0,
          scale: 4,
        }, {
          opacity: 1,
          scale: 1,
          stagger: 1,
          y: (index) => Math.cos(index) * -15,
          x: (index) => Math.cos(index) * -15,
          rotate: (index) => Math.cos(((Math.PI * 5) / sheets.length) * index) * -20,
        })
        .to(pause, {
          x: 1,
        })
        .to(zustand.manifest.sections['10'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .to($root.current.querySelectorAll('.sheet'), {
          y: '-200%',
          opacity: 0,
          stagger: -0.1,
        })

      if (theme.detect.debug) {
        window.console.log('section 10 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  const renderSheets = () => {
    return sheets.map((sheet, ind) => (
      <div
        key={ind.toString()}
        className={classNames({
          [classes.sheet]: true,
          sheet,
        })}
        style={{ backgroundColor: sheet.color }}
      >
        <h2 className={classes.title}>{sheet.title}</h2>
        <div className={classes.text} dangerouslySetInnerHTML={{ __html: sheet.text }} />
      </div>
    ))
  }

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        {renderSheets()}
      </div>
    </section>
  )
}
export default Section10
