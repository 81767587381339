const style = (theme) => {
  const output = {
    // '@global': {
    //   '.c-scrollbar': {
    //     display: 'none',
    //   },
    // },
    root: {
      position: 'fixed',
      extend: theme.wrapper,
      zIndex: 3,
      top: 0,
      width: '100%',
      height: 'var(--vh)',
      pointerEvents: 'none',
    },
    scroll: {
      position: 'absolute',
      right: 'calc(4.2vw + 48px)',
      bottom: 60,
      height: 3,
      borderRadius: 4,
      width: 125,
      pointerEvents: 'all',
      display: (props) => (!props.onlySocial ? 'block' : 'none'),
    },
    stick: {
      display: 'flex',
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      height: 3,
      borderRadius: 3,
      transformOrigin: '0 0',
      width: '100%',
      background: theme.getRgba(theme.colors[1], 0.3),
      clipPath: theme.detect.isNativeScroll || theme.detect.hasTouch ? '' : 'url(#scrollMask)',
    },
    thumb: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      transformOrigin: '0 0',
      width: '100%',
      height: '100%',
      background: theme.colors[1],
      borderRadius: 3,
    },
    scrollButton: {
      fontSize: 0,
      height: '100%',
      flex: '1 1 auto',
      position: 'relative',
      zIndex: 2,
      cursor: 'pointer',
      pointerEvents: 'all',
    },
    progress: {
      position: 'absolute',
      zIndex: 1,
      color: theme.colors[1],
      fontSize: 10,
      bottom: -3,
      left: 'calc(100% + 20px)',
      textAlign: 'right',
    },
    scrollIcon: {
      position: 'absolute',
      zIndex: 1,
      left: '5.5vw',
      bottom: 28,
    },

    discover: {
      textTransform: 'uppercase',
      position: 'absolute',
      zIndex: 1,
      fontSize: 10,
      color: theme.colors[1],
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 57,
      display: (props) => (!props.onlySocial ? 'block' : 'none'),
      '& div': {
        transformOrigin: '50% 0%',
        '--d': 'calc(var(--index) * 0.1s)',
        animation: '$pulse 1.2s ease-in infinite alternate var(--d)',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 'calc(50% - 6px)',
        borderTop: '1px solid #fff',
        borderRight: '1px solid #fff',
        transform: 'rotate(135deg)',
        width: 5,
        height: 5,
        bottom: -15,
        animation: '$scroll .8s ease-in infinite alternate',
      },
    },

    socials: {
      position: 'absolute',
      left: '4.2vw',
      bottom: 47,
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'all',
      '& a': {
        padding: [6, 10],
        pointerEvents: 'all',
      },
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scaleY(1.1)',
        opacity: '1',
      },
      '100%': {
        transform: 'scaleY(1)',
        opacity: '0.2',
      },
    },
    '@keyframes scroll': {
      '0%': {
        transform: 'rotate(135deg) translate(-2px, 4px)',
      },
      '100%': {
        opacity: 0.6,
        transform: 'rotate(135deg)',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    progress: {
      fontSize: 9,
    },
    scroll: {
      right: 'calc(4.2vw + 38px)',
      bottom: 35,
      width: 100,
    },
    discover: {
      fontSize: 9,
      left: 'calc(4.2vw + 45px)',
      transform: 'none',
      bottom: 33,
      '&:before': {
        display: 'none',
      },
    },
    socials: {
      bottom: 24,
    },
  }

  return output
}

export default style
