const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
    },
    overlay: {
      background: 'linear-gradient(#2d2b44, #2d2b44)',
      position: 'fixed',
      zIndex: 10,
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      pointerEvents: 'none',
      transform: 'translateY(-100%)',
      fontSize: '10vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        display: 'inline-block',
        transform: 'rotate(-45deg)',
      },
    },
    page: {},
    start: {
      height: '50vh',
    },
    wrapper: {
      extend: theme.wrapper,
    },
    section: {
      pointerEvents: 'none',
      '& a, & button': {
        pointerEvents: 'all',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      '& .pin-spacer': {
        pointerEvents: 'none',
        '& > button, & > a': {
          pointerEvents: 'all',
        },
      },
    },
  }

  return output
}

export default style
