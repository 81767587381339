import axios from 'axios'
import { API_BASE_URL, API_CACHE_BASE_URL } from '@/constants'

const mainAxios = axios.create({ baseURL: API_BASE_URL })

const mainCacheAxios = axios.create({ baseURL: process.env.NODE_ENV === 'production' ? API_CACHE_BASE_URL : API_BASE_URL })

export {
  mainAxios,
  mainCacheAxios,
}
