import { useEffect, useRef } from 'react'
import gsap from 'gsap'

const RevealFadeIn = ({
  className,
  children,
  duration,
  ease,
  delay,
  visible,
  inview,
  tag,
}) => {
  const $root = useRef()
  const Tag = tag

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    gsap.set($root.current, {
      opacity: 0,
    })
  }, [])

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    gsap.to($root.current, {
      opacity: (visible || inview) ? 1 : 0,
      duration,
      ease,
      delay,
    })
  }, [visible, inview])

  return (
    <Tag className={className} ref={$root} style={{ opacity: 0 }}>
      {children}
    </Tag>
  )
}

/*------------------------------
Default Props
------------------------------*/
RevealFadeIn.defaultProps = {
  className: '',
  tag: 'div',
  duration: 0.7,
  delay: 0,
  ease: 'power3.inOut',
  visible: false,
  inview: false,
}

export default RevealFadeIn
