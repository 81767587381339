const style = (theme) => {
  const output = {
    root: {
      extend: theme.smallWrapper,
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors[0],
      color: theme.colors[1],
      width: '100%',
      zIndex: theme.zindex.header,
      paddingTop: 50,
      paddingBottom: 50,
      fontSize: 16,
      fontFamily: theme.fonts[1],
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        display: 'block',
        width: 'fit-content',
        color: theme.colors[1],
        fontSize: 16,
        fontFamily: theme.fonts[1],
        fontWeight: 400,
        backgroundColor: '#4eb558',
        padding: '15px 25px',
        borderRadius: 10,
        marginTop: 20,
      },
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  }

  return output
}

export default style
