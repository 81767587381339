import { useRef, useContext, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AudioWave from '@/components/AudioWave'
import Logo from '@/components/Logo'
import StrokeButton from '@/components/StrokeButton'
import DelayLink from '@/components/DelayLink'
import Burger from '@/components/Burger'
import { Context } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({ treshold }) => {
  const { setHeaderRef, setHeaderHeight, headerInverse, isBackVisible } = useContext(Context)
  const { isAudioActive, setAudioActive, setPlayHoverSound } = useContext(AudioContext)
  const [isAudioButtonHover, setAudioButtonHover] = useState(false)
  const { scroll } = useContext(ScrollbarContext)
  const classes = useStyles({ headerInverse })
  const [isMainButtonHover, setMainButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const $root = useRef()
  const history = useHistory()
  const rootBounds = useRef()
  const theme = useTheme()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, strings } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    linkShop: state.options.strings.shop_page_link || '',
    headerMenu: state.nav.header_menu,
    strings: state.options.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') setMainButtonHover(true)
    if (e.type === 'mouseleave') setMainButtonHover(false)
  }

  // share header ref to using it in <Nav/> for accessibility purpose
  useEffect(() => {
    setHeaderRef($root)
  }, [])

  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderHeight($root.current.getBoundingClientRect().height)
    }
  })

  const handleMainMenuClick = () => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }

  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  const update = () => {
    if (scroll.current.y <= treshold) setTop(true)
    if (scroll.current.y > treshold) setTop(false)
  }

  const handleAudioClick = () => {
    setAudioActive(!isAudioActive)
  }

  const handleMouseMoveAudioButton = (e) => {
    if (e.type === 'mouseenter') setAudioButtonHover(true)
    if (e.type === 'mouseleave') setAudioButtonHover(false)
  }

  useRaf(() => {
    update()
  }, [isTop])

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = () => {
    return (
      <button
        className={classNames({
          [classes.burgerButton]: true,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => {
          handleMouseMove(e)
          setPlayHoverSound(true)
        }}
        onMouseLeave={(e) => { handleMouseMove(e) }}
      >
        <Burger
          isActive={isMenuOpen}
          isHover={isMainButtonHover}
          headerInverse={headerInverse}
        />
      </button>
    )
  }

  /*------------------------------
  Render BackButton
  ------------------------------*/
  const renderBackButton = () => {
    return isBackVisible && (
      <button
        className={classNames({
          [classes.back]: true,
          [classes.hide]: isMenuOpen,
        })}
        onMouseEnter={() => {
          setPlayHoverSound(true)
        }}
        onClick={() => history.goBack()}
        aria-label="go-back"
        ref={preventPressSpacebar}
      >
        <svg><use xlinkHref="#ico-arrow-left" /></svg>
      </button>
    )
  }

  /*------------------------------
  Render Audio
  ------------------------------*/
  const renderAudioButton = () => {
    return (
      <StrokeButton
        offset={2}
        amplitude={4}
        className={classNames({
          [classes.audioButton]: true,
          [classes.hide]: isMenuOpen,
        })}
      >
        <button
          onClick={handleAudioClick}
          aria-label="toggle-audio"
          ref={preventPressSpacebar}
          onMouseEnter={(e) => {
            setPlayHoverSound(true)
            handleMouseMoveAudioButton(e)
          }}
          onMouseLeave={handleMouseMoveAudioButton}
        >
          <AudioWave
            isActive={isAudioActive}
            isHover={isAudioButtonHover}
            color={theme.colors[1]}
          />
        </button>
      </StrokeButton>
    )
  }

  /*------------------------------
  Render Available From
  ------------------------------*/
  const renderAvailableFrom = () => {
    return strings?.available_from_label && (
      <StrokeButton
        offset={10}
        amplitude={4}
        className={classNames({
          [classes.available]: true,
          [classes.hide]: isMenuOpen,
        })}
      >
        <DelayLink to={strings.available_from_url}>
          {strings.available_from_label}
        </DelayLink>
      </StrokeButton>
    )
  }

  return (
    <header
      className={classNames({
        [classes.root]: true,
        [classes.minimize]: !isTop && !isMenuOpen,
        [classes.isMenuOpen]: isMenuOpen,
      })}
      ref={$root}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.nav}>
            {renderBurgerButton()}
            {renderBackButton()}
          </div>
          <div className={classNames({
            [classes.logo]: true,
            [classes.hide]: isMenuOpen,
          })}
          >
            <Logo />
          </div>
          {renderAvailableFrom()}
          {renderAudioButton()}
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  treshold: 20,
  menuLabel: 'Menu',
  headerNav: [],
}

export default Header
