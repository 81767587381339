import { mainCacheAxios as axios } from '@/base/axios'
import { REQUEST_ROUTING, RECEIVE_ROUTING } from '@/actions/types'
import { API } from '@/constants'

const requestRouting = () => ({ type: REQUEST_ROUTING })

const receiveRouting = (data) => ({
  type: RECEIVE_ROUTING,
  payload: data,
})

const fetchRouting = () => async (dispatch) => {
  dispatch(requestRouting())
  const response = await axios.get(API.ROUTING)
  dispatch(receiveRouting(response.data))
}

export {
  fetchRouting,
  receiveRouting,
}
