import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles, useTheme } from 'react-jss'
import gsap from 'gsap'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import AvailableSelector from '@/components/AvailableSelector'
import WpContactForm7 from '@/components/WpContactForm7'
import RevealText from '@/components/RevealText'
import InViewTrigger from '@/components/InViewTrigger'
import RevealFadeIn from '@/components/RevealFadeIn'
import StrokeButton from '@/components/StrokeButton'
import ScrollIndicator from '@/components/ScrollIndicator'
import Meta from '@/components/Meta'
import { removeParagraph } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import * as contentActions from '@/actions/content'
import * as formActions from '@/actions/form'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const Contact = () => {
  const { setPageAnimationReady, headerHeight, pageAnimationReady } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const { scrollbar } = useContext(ScrollbarContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const $root = useRef()
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const formId = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, currentForm, strings, socialNav, params } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    currentForm: state.form[formId.current] || [],
    strings: state.options.strings || {},
    socialNav: state.nav.social_menu,
    params: state.router.location,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchForm = useCallback((id) => dispatch(formActions.fetchForm(id)), [dispatch])
  const sendContactForm = useCallback((id, data, isMultipart) => dispatch(formActions.sendContactForm(id, data, isMultipart)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }
  /*------------------------------
  If Page exist call API form
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      formId.current = page.acf.formId
      fetchForm(page.acf.formId)
    }
  }, [page])

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    if (currentForm.length > 0 && Object.keys(page).length > 0) {
      gsap.to('body', {
        background: 'linear-gradient(#d285e2, #6859a3)',
      })
      completePreload()
    }
  }, [currentForm, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Animation Ready
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && params) {
      const { goto } = params.query
      if (goto && scrollbar) {
        setTimeout(() => {
          scrollbar.update()
          scrollbar.scroll.scrollTo(`#${goto}`)
        }, 2000)
      }
    }
  }, [pageAnimationReady, params])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Form
  ------------------------------*/
  const renderForm = () => {
    return (
      <div className={classes.formWrapper} id="contacts">
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <InViewTrigger>
              <RevealText
                tag="h2"
                className={classes.title}
                value={removeParagraph(page.acf?.contacts_title)}
                type="lines"
                lineHeight={0.85}
              />
              <RevealFadeIn>
                <div
                  className={classes.text}
                  dangerouslySetInnerHTML={{ __html: page.acf?.contacts_text }}
                />
                <StrokeButton
                  className={classes.email}
                  offset={5}
                  amplitude={7}
                >
                  <a href={`mailto:${page.acf?.contacts_email}`}>{page.acf?.contacts_email}</a>
                </StrokeButton>
              </RevealFadeIn>
            </InViewTrigger>
          </div>
          <div className={classes.right}>
            <InViewTrigger>
              <RevealText
                tag="h3"
                className={classNames({
                  [classes.title]: true,
                  [classes.subTitle]: true,
                })}
                value={removeParagraph(page.acf?.contacts_label)}
                type="lines"
                lineHeight={0.85}
              />
              <RevealFadeIn>
                <WpContactForm7
                  fields={currentForm}
                  formID={formId.current}
                  sendContactForm={sendContactForm}
                  submitLabel={strings.label_submit}
                />
              </RevealFadeIn>
            </InViewTrigger>
          </div>
        </div>
      </div>

    )
  }

  /*------------------------------
  Render Available
  ------------------------------*/
  const renderAvailable = () => {
    return (
      <div className={classes.available}>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <RevealText
              tag="h1"
              className={classes.title}
              value={removeParagraph(page.acf?.available_from)}
              type="lines"
              lineHeight={0.85}
              visible={pageAnimationReady}
            />
          </div>
        </div>
        <RevealFadeIn visible={pageAnimationReady}>
          <AvailableSelector
            className={classes.availableSelector}
            items={page.acf?.field_available}
            ready={pageAnimationReady}
          />
        </RevealFadeIn>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <InViewTrigger>
              <RevealFadeIn>
                <div
                  className={classes.text}
                  dangerouslySetInnerHTML={{ __html: page.acf?.contacts_address }}
                />
              </RevealFadeIn>
            </InViewTrigger>
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div
          className={classes.page}
          id="available"
        >
          {renderAvailable()}
          {renderForm()}
        </div>
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageContact ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      <ScrollIndicator
        className={classes.scrollIndicator}
        load={load}
        social={socialNav}
        onlySocial={true}
        label={strings.scroll_to_discover_label}
      />
    </>
  )
}

export default Contact
