import { createContext, useRef, useCallback, useEffect } from 'react'
import Velocity from 'touch-velocity'
import { useRaf, useResize } from '@/components/Handlers'
import { range, newMapRange } from '@/utils/math'
import useWillMount from '@/hooks/useWillMount'

const Context = createContext({})

const Mouse = ({ children }) => {
  const contextRef = useRef()
  const velocityRef = useRef()

  const prevMouseRef = useRef({
    x: 0,
    y: 0,
  })

  const mouseRef = useRef({
    x: global.innerWidth / 2,
    y: global.innerHeight / 2,
    normalizedX: 0,
    normalizedY: 0,
  })

  const screenSizeRef = useRef({
    width: 0,
    height: 0,
  })

  const scrollbarRef = useRef({
    y: 0,
    yVelocity: 0,
    yDirection: 0,
    yViewportPercent: 0,
    prevY: 0,
    prevYViewportPercent: 0,
  })

  const onMouseMove = useCallback((e) => {
    prevMouseRef.current.x = mouseRef.current.x
    prevMouseRef.current.y = mouseRef.current.y
    mouseRef.current.x = e.clientX
    mouseRef.current.y = e.clientY
    mouseRef.current.normalizedX = newMapRange(
      mouseRef.current.x,
      [0, screenSizeRef.current.width],
      [-1, 1],
    )
    mouseRef.current.normalizedY = newMapRange(
      mouseRef.current.y,
      [0, screenSizeRef.current.height],
      [-1, 1],
    )
  }, [])

  useWillMount(() => {
    velocityRef.current = new Velocity()
    contextRef.current = {
      prevMouse: prevMouseRef,
      mouse: mouseRef,
      scrollbar: scrollbarRef,
      screenSize: screenSizeRef,
    }
  })

  useEffect(() => {
    global.addEventListener('mousemove', onMouseMove)
    return () => {
      global.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  useResize(() => {
    screenSizeRef.current = {
      width: global.innerWidth,
      height: global.innerHeight,
    }
  })

  useRaf(() => {
    scrollbarRef.current.prevYViewportPercent = scrollbarRef.current.yViewportPercent
    scrollbarRef.current.yViewportPercent = newMapRange(
      scrollbarRef.current.y,
      [0, screenSizeRef.current.height * 1],
      [0, 1],
      true,
    )
    velocityRef.current.updatePosition(scrollbarRef.current.y)
    scrollbarRef.current.yVelocity = range(
      velocityRef.current.getVelocity() * 0.001,
      -1,
      1,
    )
    scrollbarRef.current.yVelocity = velocityRef.current.getVelocity() * 0.001
  })

  return (
    <Context.Provider
      value={{
        ...contextRef.current,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context }

export default Mouse
