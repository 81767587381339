import { useMemo } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import Select from 'react-select'

import style from '../style'

const SelectField = ({
  classes,
  label,
  name,
  onBlur,
  required,
  value,
  error,
  touched,
  options,
  withLabel,
  submitCount,
  setFieldValue,
}) => {
  const getOptions = useMemo(() => options.map((option) => ({ value: option, label: option })))
  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlSelect]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label className={classes.select}>
        {withLabel && (
          <span className={classes.label}>
            {label}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <Select
          placeholder={label}
          options={getOptions}
          name={name}
          value={options ? options.find((option) => option.value === value) : ''}
          onChange={(option) => setFieldValue(name, option.value)}
          onBlur={onBlur}
          className="selectContainer"
          classNamePrefix="customSelect"
        />
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

SelectField.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(SelectField)
