import { useMemo, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import gsap from 'gsap'

/*------------------------------
Background
------------------------------*/
const Background = ({ callback }) => {
  const { viewport } = useThree()
  const scale = useMemo(() => ((40 + 15) / viewport.distance), [viewport])

  const shaderArgs = useMemo(() => ({
    uniforms: {
      uTime: { value: 0 },
      uAlpha: { value: 0 },
      uResolution: { value: { x: viewport.width, y: viewport.height } },
    },
    vertexShader: /* glsl */`
      varying vec2 vUv;
      void main() {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
    fragmentShader: /* glsl */`
      uniform float uTime;
      uniform float uAlpha;
      uniform vec2 uResolution;
      varying vec2 vUv;

      #define GOD_RAY_LENGTH .6 // higher number = shorter rays
      #define GOD_RAY_FREQUENCY 23.0
      #define PI 3.14159265

      float GodRays(  in vec2 ndc, in vec2 uv) {
          vec2 godRayOrigin = ndc + vec2(0., -.9);
          float rayInput = atan(godRayOrigin.y, godRayOrigin.x) * PI * .2;
          float light = (sin(rayInput * GOD_RAY_FREQUENCY + uTime * -2.) * 0.5 + 0.5);
          light = 0.5 * (light + (sin(rayInput * 13.0 + uTime) * 0.5 + 0.5));
          light *= pow(clamp(dot(normalize(-godRayOrigin), normalize(ndc - godRayOrigin)), 0.0, 1.0), 2.5);
          light *= pow(uv.y, GOD_RAY_LENGTH);
          light = pow(light, 1.75);
          return light;
      }

      void main() {
        float ratio = uResolution.x/uResolution.y;
        vec2 uv = vUv;
        vec2 ndc = uv - 0.5;
        ndc.x *= ratio;
        uv.x *= ratio;
        float godRay = GodRays(ndc, uv);
        vec3 color = vec3((godRay + 0.05)/1.05);
        color *= uAlpha;
        gl_FragColor = vec4(color, color.r);
      }
    `,
  }), [viewport])

  useEffect(() => {
    gsap.to(shaderArgs.uniforms.uAlpha, {
      value: 1,
      duration: 1.5,
    })
    gsap.to(shaderArgs.uniforms.uAlpha, {
      value: 0,
      delay: 7,
      duration: 2,
      onComplete: () => {
        callback(false)
      },
    })

    return () => {
      gsap.killTweensOf(shaderArgs.uniforms.uAlpha)
    }
  }, [])

  useFrame(({ clock }) => {
    shaderArgs.uniforms.uTime.value = clock.getElapsedTime()
  })

  return (
    <>
      <mesh position={[0, 0, -40]}>
        <planeBufferGeometry attach="geometry" args={[viewport.width * scale, viewport.height * scale, 1, 1]} />
        <shaderMaterial args={[shaderArgs]} />
      </mesh>
    </>
  )
}

export default Background
