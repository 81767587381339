import { useEffect, useRef } from 'react'
import { Color } from 'three'
import { extend, useFrame, useThree } from '@react-three/fiber'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass'
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import zustand from '@/base/zustand'

import { MousePass } from './post/MousePass'
import { MaskPass } from './post/MaskPass'

extend({ EffectComposer, RenderPass, SMAAPass, MousePass, MaskPass }) // UnrealBloomPass

export default function PostProcessing() {
  const composer = useRef()
  const maskPass = useRef()
  const mousePass = useRef()
  const { gl, scene, camera, size } = useThree()
  useEffect(() => {
    composer.current.setSize(size.width, size.height)
  }, [size])
  useFrame(() => {
    composer.current.render()
    maskPass.current.material.uniforms.uColor1.value = new Color(zustand.home.col1)
    maskPass.current.material.uniforms.uColor2.value = new Color(zustand.home.col2)
  }, 1)

  return (
    <effectComposer smaa ref={composer} args={[gl]}>
      <renderPass attachArray="passes" args={[scene, camera]} />
      <mousePass
        ref={mousePass}
        material-uniforms-uAspect-value={[size.width, size.height]}
        attachArray="passes"
      />
      <maskPass
        ref={maskPass}
        material-uniforms-uAspect-value={[size.width, size.height]}
        attachArray="passes"
      />
      <sMAAPass attachArray="passes" args={[size.width, size.height]} />
      {/* <shaderPass
        attachArray="passes"
        args={[FXAAShader]}
        material-uniforms-resolution-value={[1 / size.width, 1 / size.height]}
      /> */}
      {/* <unrealBloomPass
        attachArray="passes"
        args={[new Vector2(size.width, size.height), 1, 0.4, 0]}
      /> */}
    </effectComposer>
  )
}
