const SVGSprite = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol id="ico-close" viewBox="0 0 24 24">
        <path d="M21.8,19.2L14.5,12l7.3-7.3c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0L12,9.4L4.8,2.2c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5 L9.5,12l-7.3,7.3c-0.7,0.7-0.7,1.8,0,2.5c0.7,0.7,1.8,0.7,2.5,0l7.3-7.3l7.2,7.2c0.7,0.7,1.8,0.7,2.5,0S22.5,19.9,21.8,19.2z" />
      </symbol>
      <symbol id="ico-download" viewBox="0 0 170 170">
        <path d="M88.9,119.4l31-31c2.6-2.6,2.6-6.7,0-9.3l0,0c-2.6-2.6-6.7-2.6-9.3,0L84,105.9L57.2,79.1c-2.6-2.6-6.7-2.6-9.3,0l0,0 c-2.6,2.6-2.6,6.7,0,9.3l31,31c1.4,1.4,3.2,2,5,1.9C85.7,121.4,87.6,120.8,88.9,119.4z" />
        <path d="M84,79.6L84,79.6c-4.1,0-7.5-3.4-7.5-7.5v-20c0-4.1,3.4-7.5,7.5-7.5h0c4.1,0,7.5,3.4,7.5,7.5v20 C91.5,76.3,88.1,79.6,84,79.6z" />
      </symbol>
      <symbol id="ico-twitter" viewBox="0 0 512 512">
        <path
          transform="translate(0,50)"
          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
        />
      </symbol>
      <symbol id="ico-github" viewBox="0 0 16 16">
        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
      </symbol>
      <symbol id="ico-linkedin" viewBox="0 0 512 512">
        <path d="M0 160h114.496v352H0zM426.368 164.128c-1.216-.384-2.368-.8-3.648-1.152-1.536-.352-3.072-.64-4.64-.896-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z" />
        <circle cx="56" cy="56" r="56" />
      </symbol>
      <symbol id="ico-instagram" viewBox="0 0 64 64">
        <path d="M18.5.3h27.1C55.7.3 64 8.5 64 18.5v27c0 10-8.3 18.2-18.5 18.2h-27C8.3 63.7 0 55.5 0 45.5v-27C0 8.5 8.3.3 18.5.3zm13.3 14.6c9.9 0 17.9 8 17.9 17.9 0 9.9-8 17.9-17.9 17.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8.1-17.9 17.9-17.9zm0 6c6.5 0 11.8 5.3 11.8 11.8s-5.3 11.8-11.8 11.8c-6.5 0-11.8-5.3-11.8-11.8 0-6.5 5.3-11.8 11.8-11.8zm17.3-8.5c1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9zM20.7 5.5h22.6c8.5 0 15.5 6.9 15.5 15.4v22.8c0 8.5-7 15.4-15.5 15.4H20.7C12.2 59 5.2 52 5.2 43.6V20.8c0-8.4 7-15.3 15.5-15.3z" fillRule="evenodd" clipRule="evenodd" />
      </symbol>
      <symbol id="ico-facebook" viewBox="0 0 512 512">
        <path d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z" />
      </symbol>
      <symbol id="ico-youtube" viewBox="0 0 24 24">
        <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
      </symbol>
      <symbol id="ico-mail" viewBox="0 0 64 64">
        <path d="M32,0C14.5,0,0,14.5,0,32s14.5,32,32,32s32-14.5,32-32S49.5,0,32,0z M32,59.7C16.7,59.7,4.3,47.3,4.3,32S16.7,4.3,32,4.3 S59.7,16.7,59.7,32S47.3,59.7,32,59.7z" />
        <path d="M49.7,18.1H14.3c-1.7,0-3,1.3-3,3v21.7c0,1.7,1.3,3,3,3h35.4c1.7,0,3-1.3,3-3V21.1C52.3,19.4,51,18.1,49.7,18.1z M48.4,24.1 v15.3l-9-7.2L48.4,24.1z M26.7,34.8l2.1,2.1c1.7,1.3,3.8,1.3,5.1,0l2.1-2.1l8.5,6.8H17.7L26.7,34.8z M31.8,33.9L18.1,22h27.3 L31.8,33.9L31.8,33.9z M24.1,32.2l-9,7.2V24.1L24.1,32.2z" />
      </symbol>
      <symbol id="ico-refresh" viewBox="0 0 36 36">
        <circle cx="18" cy="18" r="17.6" style={{ opacity: 0.2, fill: '#ffffff' }} />
        <path
          d="M23.6,17.9v0.4c0,3.1-2.5,5.7-5.7,5.7s-5.7-2.5-5.7-5.7s2.5-5.7,5.7-5.7h0.6L17.1,14l1.2,1.3l3.5-3.5l-3.5-3.5 l-1.2,1.2l1.4,1.4h-0.6c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4v-0.4H23.6z"
          style={{ fill: '#ffffff' }}
        />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 31.5 36.37">
        <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
      </symbol>
      <symbol id="ico-ball" viewBox="0 0 17 17">
        <path d="M8.500,-0.000 C13.194,-0.000 17.000,3.806 17.000,8.500 C17.000,13.194 13.194,17.000 8.500,17.000 C3.806,17.000 0.000,13.194 0.000,8.500 C0.000,3.806 3.806,-0.000 8.500,-0.000 Z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 24 24">
        <path d="M5.5,13.4l8.4,8.4c0.7,0.7,1.8,0.7,2.5,0l0,0c0.7-0.7,0.7-1.8,0-2.5L9.2,12l7.3-7.3c0.7-0.7,0.7-1.8,0-2.5l0,0 c-0.7-0.7-1.8-0.7-2.5,0l-8.4,8.4C5.1,11,5,11.5,5,12C5,12.5,5.1,13,5.5,13.4z" />
      </symbol>
      <symbol id="ico-arrow-right" viewBox="0 0 24 24">
        <path d="M18.5,10.6L10,2.2c-0.7-0.7-1.8-0.7-2.5,0l0,0C6.8,2.9,6.8,4,7.5,4.7l7.3,7.3l-7.3,7.3c-0.7,0.7-0.7,1.8,0,2.5l0,0 c0.7,0.7,1.8,0.7,2.5,0l8.4-8.4C18.9,13,19,12.5,19,12C19,11.5,18.9,11,18.5,10.6z" />
      </symbol>
      <symbol id="ico-link" viewBox="0 0 24 24">
        <path d="M21.8,2.3c-0.4-0.4-0.8-0.6-1.4-0.6H8.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h7.9l-14,14.1c-0.6,0.6-0.6,1.8,0,2.6l0,0 c0.6,0.6,1.8,0.6,2.6,0l14-14v7.9c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8V3.7C22.3,3,22.2,2.7,21.8,2.3z" />
      </symbol>
    </svg>
  )
}
export default SVGSprite
