/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useResize, useRaf } from '@/components/Handlers'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const ScrollingText = ({ className, text, inview, speed = 2 }) => {
  const classes = useStyles()
  const $text = useRef()
  const textWidth = useRef('')
  const time = useRef(0)
  const [clonedText, setClonedText] = useState(`<span>${text}</span>`)

  useEffect(() => {
    textWidth.current = $text.current.offsetWidth
  }, [])

  useResize(() => {
    setClonedText(`<span>${text}</span>`)
    textWidth.current = $text.current.querySelector('span').offsetWidth
  })

  useEffect(() => {
    if ($text.current.clientWidth < window.innerWidth + textWidth.current) {
      setClonedText(`${clonedText}<span>${text}</span>`)
    }
  }, [clonedText])

  useRaf(() => {
    if (inview) {
      time.current += speed
      $text.current.style.transform = `translate3d(-${time.current % textWidth.current}px, 0, 0)`
    }
  }, [inview])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      <span
        ref={$text}
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: clonedText }}
      />
    </div>
  )
}

export default ScrollingText
