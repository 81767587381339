/* eslint-disable no-case-declarations */
/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import classNames from 'classnames'
import { Context as GeneralContext } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import { Context as LoadingContext } from '@/context/loading'
import HTMLParse from 'react-html-parser'
import useSound from 'use-sound'
import usePrevious from '@/hooks/usePrevious'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import RevealText from '@/components/RevealText'
import WpContactForm7 from '@/components/WpContactForm7'
import MagneticButton from '@/components/MagneticButton'
import WhoSelector from '@/components/WhoSelector'
import MonthSelector from '@/components/MonthSelector'
import StrokeButton from '@/components/StrokeButton'
import StrokeBall from '@/components/StrokeBall'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import CanvasQuote from '@/components/CanvasQuote'
import Meta from '@/components/Meta'
import { getSlug } from '@/utils/path'
import { removeParagraph } from '@/utils/strings'
import * as contentActions from '@/actions/content'
import * as formActions from '@/actions/form'
import budget1Audio from '@/assets/audio/cry.mp3'
import budget2Audio from '@/assets/audio/clap.mp3'
import budget3Audio from '@/assets/audio/yeah.mp3'
import budget4Audio from '@/assets/audio/heaven.mp3'
import fallingAudio from '@/assets/audio/falling-down.mp3'
import yeahAudio from '@/assets/audio/oh-yeah.mp3'
import sexyAudio from '@/assets/audio/sexy-whistle.mp3'
import welcomeAudio from '@/assets/audio/welcome.mp3'
import style from './style'

const useStyles = createUseStyles(style)

const Step = ({ children, id, active }) => {
  const { headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const $root = useRef()

  useEffect(() => {
    if (!active) {
      gsap.to($root.current, {
        autoAlpha: 0,
        duration: 0.6,
        onComplete: () => {
          gsap.set($root.current, {
            display: 'none',
          })
        },
      })
    } else {
      if (window.innerWidth > 720) {
        gsap.set($root.current, {
          height: window.innerHeight < 780 ? 400 : 600,
          display: 'flex',
          delay: 0.6,
        })
      } else {
        gsap.set($root.current, {
          height: 'auto',
          display: 'flex',
          delay: 0.6,
        })
      }
      gsap.to($root.current, {
        autoAlpha: 1,
        duration: 0.6,
        delay: 0.6,
      })
    }
  }, [active])

  return (
    <div
      ref={$root}
      className={classNames({
        [classes.step]: true,
        [classes[`step${id}`]]: true,
        active,
      })}
    >
      {children}
    </div>
  )
}

const Quote = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const { scrollbar } = useContext(ScrollbarContext)
  const { setPlayHoverSound } = useContext(AudioContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const $root = useRef()
  const location = useLocation()
  const formId = useRef()
  const stub = useRef(getSlug(location.pathname))
  // const budgets = [
  //   '<25k',
  //   '25k-50k',
  //   '50k-75k',
  //   '>75k',
  // ]

  /*------------------------------
  Sounds
  ------------------------------*/
  const [playBudget1, {
    sound: budget1Sound,
    stop: stopBudget1,
  }] = useSound(budget1Audio)
  const [playBudget2, {
    sound: budget2Sound,
    stop: stopBudget2,
  }] = useSound(budget2Audio)
  const [playBudget3, {
    sound: budget3Sound,
    stop: stopBudget3,
  }] = useSound(budget3Audio)
  const [playBudget4, {
    sound: budget4Sound,
    stop: stopBudget4,
  }] = useSound(budget4Audio)
  const [playFallingAudio, {
    stop: stopFallingAudio,
  }] = useSound(fallingAudio)
  const [playYeahAudio, {
    stop: stopYeahAudio,
  }] = useSound(yeahAudio)
  const [playSexyAudio, {
    stop: stopSexyAudio,
  }] = useSound(sexyAudio)
  const [playWelcomeAudio, {
    stop: stopWelcomeAudio,
  }] = useSound(welcomeAudio)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, currentForm, strings } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    currentForm: state.form[formId.current] || [],
    strings: state.options.strings || {},
  }), shallowEqual)

  const budgets = useRef(Array(4).fill(''))
  const [steps, setSteps] = useState({
    who: 'Agency',
    type: 'New project',
    budget: budgets[1],
    when: '',
  })
  const [step, setStep] = useState(0)
  const prevStep = usePrevious(step)
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [dateAvailable, setDateAvailable] = useState(true)
  const [canvasEffect, setCanvasEffect] = useState(false)
  const [textareaValue, setTextareaValue] = useState('')

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchForm = useCallback((id) => dispatch(formActions.fetchForm(id)), [dispatch])
  const sendContactForm = useCallback((id, data, isMultipart) => dispatch(formActions.sendContactForm(id, data, isMultipart)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    gsap.to('body', {
      background: 'linear-gradient(#987fdd, #664dff)',
    })
    if (Object.keys(page).length > 0) {
      formId.current = page.acf?.step5.formId
      fetchForm(formId.current)
      budgets.current = [
        page.acf?.step3.budget_1,
        page.acf?.step3.budget_2,
        page.acf?.step3.budget_3,
        page.acf?.step3.budget_4,
      ]

      setSteps({
        ...steps,
        who: page.acf?.step1.option1,
        type: page.acf?.step2.option1,
        budget: budgets.current[1],
      })
    }
  }, [page])

  /*------------------------------
  Cambio Background
  ------------------------------*/
  useEffect(() => {
    if (step !== 5) {
      gsap.to('body', {
        background: 'linear-gradient(#987fdd, #664dff)',
      })
    } else {
      gsap.to('body', {
        background: 'linear-gradient(#7979d4, #9f50ff)',
      })
    }
    setButtonDisabled(step === 4 && !dateAvailable)

    setTimeout(() => {
      if (prevStep && scrollbar) scrollbar.update()
    }, 1000)
  }, [step, prevStep, dateAvailable])

  useEffect(() => {
    const intro = `A short recap, you are ${steps.who === 'Brand' ? 'a brand' : 'an agency'} `
    const budget = `with ${steps.budget !== page.acf?.step3.budget_4 ? `${steps.budget} of` : 'an infinite'} budget `
    const project = `for ${steps.type === 'New project' ? 'a new project' : 'an existing project'} `
    const when = `and you need our help from ${steps.when}, right?`
    setTextareaValue(intro + budget + project + when)
  }, [steps])

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    if (currentForm.length > 0 && Object.keys(page).length > 0) completePreload()
  }, [currentForm, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  RenderSteps
  ------------------------------*/
  const renderStepIndicator = () => {
    return (
      <div
        className={classNames({
          [classes.stepIndicator]: true,
          active: step > 0 && step < 5,
        })}
      >
        <div
          className={classes.actualStep}
        >
          {`STEP ${step}`}
        </div>
        <button
          className={classNames({
            [classes.stepIndicatorButton]: true,
            [classes.stepIndicatorButtonActive]: step === 1,
          })}
          onClick={() => { if (step > 1) setStep(1) }}
        >
          { step > 1 && steps.who }
        </button>
        <button
          className={classNames({
            [classes.stepIndicatorButton]: true,
            [classes.stepIndicatorButtonActive]: step === 2,
          })}
          onClick={() => { if (step > 2) setStep(2) }}
        >
          { step > 2 && steps.type }
        </button>
        <button
          className={classNames({
            [classes.stepIndicatorButton]: true,
            [classes.stepIndicatorButtonActive]: step === 3,
          })}
          onClick={() => { if (step > 3) setStep(3) }}
        >
          { step > 3 && steps.budget }
        </button>
        <button
          className={classNames({
            [classes.stepIndicatorButton]: true,
            [classes.stepIndicatorButtonActive]: step === 4,
          })}
          onClick={() => { if (step > 4) setStep(4) }}
        >
          { step > 4 && steps.when }
        </button>

        <div className={classes.stepControllers}>
          <button
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => {
              setStep(Math.min(5, step + 1))
            }}
            disabled={isButtonDisabled}
            className={classNames({
              [classes.button]: true,
              [classes.buttonDisabled]: isButtonDisabled,
            })}
          >
            NEXT
            {' '}
            <svg><use xlinkHref="#ico-arrow-right" /></svg>
          </button>
          <button
            onMouseEnter={() => {
              setPlayHoverSound(true)
            }}
            onClick={() => {
              setStep(Math.max(0, step - 1))
            }}
            className={classNames({
              [classes.buttonPrevStep]: true,
            })}
          >
            <svg><use xlinkHref="#ico-arrow-left" /></svg>
          </button>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Restart
  ------------------------------*/
  const renderRestart = () => {
    return step === 5 && (
      <button
        className={classes.buttonRestart}
        onMouseEnter={() => {
          setPlayHoverSound(true)
        }}
        onClick={() => setStep(0)}
      >
        <svg><use xlinkHref="#ico-refresh" /></svg>
        <span>{page.acf?.step5.restart}</span>
      </button>
    )
  }

  /*------------------------------
  Render Step 0
  ------------------------------*/
  const renderStep0 = () => {
    return (
      <Step
        active={step === 0}
        id={0}
      >
        <div className={classes.left}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step0.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 0}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step0.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 0}
          />
          <div className={classes.start}>
            <StrokeBall className={classes.startStrokeBall} />
            <MagneticButton
              speed={0.5}
              className={classes.magnetic}
            >
              <button
                className={classes.startButton}
                onMouseEnter={() => {
                  setPlayHoverSound(true)
                }}
                onClick={() => {
                  setStep(1)
                }}
              >
                <MagneticButton
                  speed={1}
                  tollerance={0.3}
                  className={classNames({
                    [classes.insideMagnetic]: true,
                  })}
                >
                  {HTMLParse(removeParagraph(page.acf?.step0.label))}
                </MagneticButton>
              </button>
            </MagneticButton>
          </div>
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Step 1
  ------------------------------*/
  const renderStep1 = () => {
    return (
      <Step
        id={1}
        active={step === 1}
      >
        <div className={classes.left}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step1.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 1}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step1.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 1}
            enterDelay={0.5}
          />
        </div>
        <div className={classes.right}>
          {window.innerWidth > 992 && (

          <WhoSelector
            className={classes.whoSelector}
            handleChange={({ who }) => {
              if (step !== 1) return
              if (who === page.acf?.step1.option1) {
                stopSexyAudio()
                playWelcomeAudio()
              } else {
                stopWelcomeAudio()
                playSexyAudio()
              }
              setSteps({ ...steps, who })
            }}
            types={[page.acf?.step1.option1, page.acf?.step1.option2]}
          />
          )}
          {window.innerWidth <= 992 && (
            <div className={classes.chooseType}>
              <StrokeButton
                className={classNames({
                  [classes.buttonType]: true,
                  [classes.buttonTypeA]: true,
                  active: steps.who === page.acf?.step1.option1,
                })}
                onMouseEnter={() => {
                  setPlayHoverSound(true)
                }}
                onClick={() => {
                  stopSexyAudio()
                  playWelcomeAudio()
                  setSteps({ ...steps, who: page.acf?.step1.option1 })
                }}
              >
                <RevealText
                  tag="div"
                  className={classes.title}
                  value={page.acf?.step1.option1}
                  type="lines"
                  lineHeight={0.85}
                  visible={step === 1}
                />
              </StrokeButton>
              <RevealText
                tag="div"
                className={classes.orType}
                value="OR"
                type="lines"
                lineHeight={0.85}
                visible={step === 1}
              />
              <StrokeButton
                className={classNames({
                  [classes.buttonType]: true,
                  [classes.buttonTypeB]: true,
                  active: steps.who === page.acf?.step1.option2,
                })}
                onMouseEnter={() => {
                  setPlayHoverSound(true)
                }}
                onClick={() => {
                  stopWelcomeAudio()
                  playSexyAudio()
                  setSteps({ ...steps, who: page.acf?.step1.option2 })
                }}
              >
                <RevealText
                  tag="div"
                  className={classes.title}
                  value={page.acf?.step1.option2}
                  type="lines"
                  lineHeight={0.85}
                  visible={step === 1}
                />
              </StrokeButton>
            </div>
          )}
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Step 2
  ------------------------------*/
  const renderStep2 = () => {
    return (
      <Step
        id={2}
        active={step === 2}
      >
        <div className={classes.left}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step2.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 2}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step2.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 2}
            enterDelay={0.5}
          />
        </div>
        <div className={classes.right}>
          <div className={classes.chooseType}>
            <StrokeButton
              className={classNames({
                [classes.buttonType]: true,
                [classes.buttonTypeA]: true,
                active: steps.type === page.acf?.step2.option1,
              })}
              onMouseEnter={() => {
                setPlayHoverSound(true)
              }}
              onClick={() => {
                stopFallingAudio()
                playYeahAudio()
                setSteps({ ...steps, type: page.acf?.step2.option1 })
              }}
            >
              <RevealText
                tag="div"
                className={classes.title}
                value={page.acf?.step2.option1}
                type="lines"
                lineHeight={0.85}
                visible={step === 2}
              />
            </StrokeButton>
            <RevealText
              tag="div"
              className={classes.orType}
              value="OR"
              type="lines"
              lineHeight={0.85}
              visible={step === 2}
            />
            <StrokeButton
              className={classNames({
                [classes.buttonType]: true,
                [classes.buttonTypeB]: true,
                active: steps.type === page.acf?.step2.option2,
              })}
              onMouseEnter={() => {
                setPlayHoverSound(true)
              }}
              onClick={() => {
                stopYeahAudio()
                playFallingAudio()
                setSteps({ ...steps, type: page.acf?.step2.option2 })
              }}
            >
              <RevealText
                tag="div"
                className={classes.title}
                value={page.acf?.step2.option2}
                type="lines"
                lineHeight={0.85}
                visible={step === 2}
              />
            </StrokeButton>
          </div>
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Step 3
  ------------------------------*/
  const renderStep3 = () => {
    return (
      <Step
        id={3}
        active={step === 3}
      >
        <div className={classes.left}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step3.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 3}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step3.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 3}
            enterDelay={0.5}
          />
        </div>
        <div className={classes.right}>
          <div className={classes.budgets}>
            {budgets.current.map((budget, index) => (
              <button
                key={index.toString()}
                onMouseEnter={() => {
                  setPlayHoverSound(true)
                }}
                onClick={() => {
                  setSteps({ ...steps, budget })
                  stopBudget1()
                  stopBudget2()
                  stopBudget3()
                  stopBudget4()
                  switch (index) {
                    case 0:
                      budget1Sound.volume(0.5)
                      playBudget1()
                      document.title = 'Are you serious?'
                      break
                    case 1:
                      budget2Sound.volume(0.5)
                      playBudget2()
                      document.title = page.yoast_head_json.og_title
                      break
                    case 2:
                      budget3Sound.volume(0.5)
                      playBudget3()
                      setCanvasEffect('confetti')
                      document.title = page.yoast_head_json.og_title
                      break
                    case 3:
                      budget4Sound.volume(0.5)
                      window.console.error('\n\n\nWelcome to Paradise.\n\n\n')
                      playBudget4('Ciao Jack, ti stavamo aspettando.')
                      setCanvasEffect('rays')
                      document.title = page.yoast_head_json.og_title
                      break
                    default:
                  }
                }}
                className={classNames({
                  [classes.budgetButton]: true,
                  [classes[`budgetButton${index}`]]: true,
                  active: steps.budget === budget,
                })}
              >
                <MagneticButton
                  speed={0.5}
                  className={classes.magneticBudget}
                >
                  {budget}
                </MagneticButton>
                <StrokeBall />
              </button>
            ))}

          </div>
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Step 4
  ------------------------------*/
  const renderStep4 = () => {
    return (
      <Step
        id={4}
        active={step === 4}
      >
        <div className={classes.left}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step4.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 4}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step4.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 4}
            enterDelay={0.5}
          />
        </div>
        <div className={classes.right}>
          <MonthSelector
            active={step === 4}
            className={classNames({
              [classes.monthSelector]: true,
            })}
            handleChange={({ month, year, available }) => {
              setSteps({ ...steps, when: `${month} ${year}` })
              setDateAvailable(available)
            }}
            stringPast={removeParagraph(page.acf?.step4.past)}
          />
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Step 4
  ------------------------------*/
  const renderStep5 = () => {
    return (
      <Step
        id={5}
        active={step === 5}
      >
        <div className={classes.form}>
          <RevealText
            tag="h1"
            className={classes.title}
            value={removeParagraph(page.acf?.step5.title)}
            type="lines"
            lineHeight={0.85}
            visible={step === 5}
          />
          <RevealText
            tag="div"
            className={classes.text}
            value={removeParagraph(page.acf?.step5.text)}
            type="lines"
            lineHeight={1.2}
            visible={step === 5}
            enterDelay={0.5}
          />
          <RevealText
            tag="div"
            className={classNames({
              [classes.text]: true,
              [classes.text2]: true,
            })}
            value={textareaValue}
            type="lines"
            lineHeight={1.2}
            visible={step === 5}
            enterDelay={1}
          />
          {step === 5 && (
          <WpContactForm7
            fields={currentForm}
            formID={formId.current}
            sendContactForm={sendContactForm}
            submitLabel={strings.label_submit}
            hiddenValues={steps}
          />
          )}
          {renderRestart()}
        </div>
      </Step>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderStep0()}
          {renderStep1()}
          {renderStep2()}
          {renderStep3()}
          {renderStep4()}
          {renderStep5()}
        </div>
      </>
    )
  }

  /*------------------------------
  Canvas Callback
  ------------------------------*/
  const handleCallback = (state) => {
    setCanvasEffect(state)
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageQuote ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      <CanvasQuote canvasEffect={canvasEffect} callback={handleCallback} />
      {load && renderStepIndicator()}
      {load && renderRestart()}
    </>
  )
}

export default Quote
