import { useEffect, useRef, useContext, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import { Context as AudioContext } from '@/context/audio'
import { Context as LoadingContext } from '@/context/loading'
import constants from '@/constants'
import style from './style'

const useStyles = createUseStyles(style)

const Loader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { setAudioActive } = useContext(AudioContext)
  const { siteLoaded, setLoaderExited, isLoaderExited } = useContext(LoadingContext)
  const $root = useRef()
  const $spinner = useRef()
  const $path = useRef()
  const $button = useRef()
  const [ready, setReady] = useState(false)
  const [isLoadEnd, setLoadEnd] = useState(false)

  /*------------------------------
  On Complete Loader End
  ------------------------------*/
  const onCompleteLoaderEnd = () => {
    setLoaderExited(true)
  }

  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = () => {
    gsap.set($root.current, {
      pointerEvents: 'none',
    })
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd })
    timeline
      .to([$spinner.current], ({
        autoAlpha: 0,
      }))
      .to($root.current, ({
        autoAlpha: 0,
      }), '<')
  }

  useEffect(() => {
    if (siteLoaded) {
      gsap.killTweensOf($path.current)
      gsap.to($path.current, {
        strokeDashoffset: 0,
        duration: 1,
        ease: 'power3.easeOut',
        onComplete: () => {
          gsap.to($button.current, {
            opacity: 1,
            pointerEvents: 'all',
            duration: 1,
            ease: 'power3.easeOut',
          })
        },
      })
      setLoadEnd(true)
    }
    if (siteLoaded && ready) {
      animateLoaderEnd()
      setAudioActive(true)
    }
    if (siteLoaded && theme.detect.debug) {
      animateLoaderEnd()
    }
  }, [siteLoaded, ready])

  useEffect(() => {
    gsap.to($path.current, {
      strokeDashoffset: 0,
      duration: 14,
      ease: 'circ.out',
    })

    document.documentElement.classList.add('loading')
  }, [])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      <div className={classes.logoContainer}>
        <img src={`${constants.IMAGE_URL}logo-ghost-unicorns.png`} alt="Ghost Unicorns" />
        <div className={classes.spinnerContainer}>
          <svg
            ref={$spinner}
            className={classes.spinner}
            viewBox="0 0 50 50"
            width="50"
            height="50"
          >
            <circle
              ref={$path}
              className={classNames({
                [classes.path]: true,
                [classes.pathTop]: true,
              })}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="0.3"
              stroke="#ffffff"
            />
            <circle className={classes.path} cx="25" cy="25" r="20" fill="none" strokeWidth="0.3" stroke="rgba(255, 255, 255, .3)" />
          </svg>
        </div>
      </div>
      <div className={classes.text}>
        You found the unicorns.
        <br />
        {' '}
        It’s really happening!
      </div>
      <button
        ref={$button}
        className={classNames({
          [classes.button]: true,
          visible: isLoadEnd,
        })}
        onClick={() => {
          setReady(true)
          document.documentElement.classList.remove('loading')
        }}
        title="Ready"
      >
        <svg><use xlinkHref="#ico-arrow-right" /></svg>
      </button>
    </div>
  )
}

export default Loader
