import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import CreateJs from 'preload-js'
import constants from '@/constants'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import ScrollIndicator from '@/components/ScrollIndicator'
import InViewTrigger from '@/components/InViewTrigger'
import Point from '@/components/Point'
import StrokeButton from '@/components/StrokeButton'
import ScrollingText from '@/components/ScrollingText'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import Banner from '@/components/Banner'
import StrokeBall from '@/components/StrokeBall'
import DelayLink from '@/components/DelayLink'
import Meta from '@/components/Meta'

import { getSlug } from '@/utils/path'
import * as contentActions from '@/actions/content'
import Hero from './hero'
import style from './style'

const useStyles = createUseStyles(style)

const About = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const { scrollbar } = useContext(ScrollbarContext)
  const classes = useStyles({ headerHeight })
  const preload = useRef(new CreateJs.LoadQueue())
  const [load, setLoad] = useState(false)
  const $root = useRef()
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $overlay = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, strings, socialNav } = useSelector((state) => ({
    strings: state.options.strings,
    page: state.content.pages[stub.current] || {},
    socialNav: state.nav.social_menu,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    preload.current.loadFile({ id: 'featured_image', src: `${constants.IMAGE_URL}merda.png` })
  }

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      gsap.to('body', {
        background: 'linear-gradient(#6983cd, #9c4efe)',
      })
      preloadMedia()
    }
  }, [page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderHeading = () => {
    return (
      <InViewTrigger
        once={false}
        className={classes.hero}
      >
        <Hero
          title={page.acf?.title}
          text={page.acf?.intro}
          ready={pageAnimationReady}
        />
      </InViewTrigger>
    )
  }

  /*------------------------------
  Render ScrollingText
  ------------------------------*/
  const renderScrollingText = () => {
    return page.acf?.scrolling_text && (
      <div className={classes.scrollingText}>
        <RevealFadeIn visible={pageAnimationReady}>
          <InViewTrigger once={false}>
            <ScrollingText
              text={page.acf?.scrolling_text}
            />
          </InViewTrigger>
          <StrokeButton
            className={classes.scrollingTextStroke}
            isHover={pageAnimationReady}
            amplitude={10}
          >
            {page.acf?.scrolling_text}
          </StrokeButton>
        </RevealFadeIn>
      </div>
    )
  }

  /*------------------------------
  Render Page
  ------------------------------*/
  const renderPage = () => {
    return (
      <div className={classes.smallWrapper}>
        <RevealFadeIn
          delay={0.4}
          visible={pageAnimationReady}
        >
          <p dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
        </RevealFadeIn>
      </div>
    )
  }

  /*------------------------------
  Render Text
  ------------------------------*/
  const renderText = (text) => {
    return text && (
      <div className={classes.textWrapper} dangerouslySetInnerHTML={{ __html: text }} />
    )
  }

  /*------------------------------
  Render Point
  ------------------------------*/
  const renderPoints = () => {
    return page.acf?.points && (
      <div className={classes.points}>
        <div className={classes.wrapper}>
          {
            page.acf?.points.map((point, index) => point.point !== '' && (
            <Point
              key={index.toString()}
              className={classes.point}
              index={index}
              text={point.point}
            />
            ))
          }
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Banners
  ------------------------------*/
  const renderBanners = () => {
    return page.acf?.banners && (
      <div className={classes.banners}>
        <div className={classes.wrapper}>
          {page.acf?.banners_title && (
            <InViewTrigger>
              <RevealText
                className={classNames({
                  [classes.title]: true,
                  [classes.bannerTitle]: true,
                })}
                tag="h2"
                type="lines"
                lineHeight={0.85}
                value={page.acf?.banners_title}
              />
            </InViewTrigger>
          )}
          {page.acf?.banners.map((banner, index) => banner.banner_title !== '' && (
            <Banner
              key={index.toString()}
              image={banner.banner_image.sizes.large}
              title={banner.banner_title}
              pretitle={banner.banner_subtitle}
              text={banner.banner_text}
              internalLabel={strings.discover_label}
              internalLink={banner.banner_link_url}
              externalLabel={strings.read_more_label}
              externalLink={banner.banner_external_url}
              className={classes.banner}
              imageRight={index % 2}
            />
          ))}
        </div>
      </div>
    )
  }

  /*------------------------------
  Open Overlay
  ------------------------------*/
  const openOverlay = () => {
    gsap.fromTo($overlay.current, {
      y: '100%',
    }, {
      y: 0,
      ease: 'power3.out',
      duration: 0.5,
    })
  }

  /*------------------------------
  Close Overlay
  ------------------------------*/
  const closeOverlay = () => {
    gsap.to($overlay.current, {
      y: '-100%',
      ease: 'power3.out',
      duration: 0.5,
      delay: 1,
    })
  }

  /*------------------------------
  Render NextLink
  ------------------------------*/
  const renderNextLink = () => {
    return (
      <section
        className={classes.nextLink}
      >
        <StrokeBall className={classes.svgBall} />
        <DelayLink
          to={page.acf?.next_link_url}
          className={classes.link}
        >
          <h3 className={classes.nextLinkTitle}>{page.acf?.next_link_label}</h3>
          <button
            className={classes.nextLinkButton}
          >
            Discover
            {' '}
            <svg><use xlinkHref="#ico-arrow-right" /></svg>
          </button>
        </DelayLink>
        <button
          className={classes.toTop}
          onClick={() => {
            openOverlay()
            setTimeout(() => {
              scrollbar.scroll.scrollTo(0, { disableLerp: true, duration: 0 })
              scrollbar.el.scrollTop = 0
            }, 500)
            setTimeout(() => {
              closeOverlay()
            }, 1000)
          }}
        >
          <svg>
            <use xlinkHref="#ico-refresh" />
          </svg>
          Back to top
        </button>
      </section>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHeading()}
          {renderScrollingText()}
          {renderText(page.acf?.text1)}
          {renderPoints()}
          {renderText(page.acf?.text2)}
          {renderBanners()}
          {renderPage()}
          {renderNextLink()}
        </div>
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageAbout ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      <ScrollIndicator
        className={classes.scrollIndicator}
        load={load}
        social={socialNav}
        label={strings.scroll_to_discover_label}
      />
      <div
        ref={$overlay}
        className={classes.overlay}
      >
        <span role="img" aria-label="back to top">
          🚀
        </span>
      </div>
    </>
  )
}

export default About
