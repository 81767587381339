import { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import Modernizr from 'modernizr'
import { useDispatch, useSelector } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import AudioContext from '@/context/audio'
import MouseContext from '@/context/mouse'
import LoadingContext from '@/context/loading'
import Handlers from '@/components/Handlers'
import BannerIE11 from '@/components/BannerIE11'
import Routes from '@/router'
import Layout from '@/layout'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/options'
import * as navActions from '@/actions/nav'
import useViewport from '@/hooks/useViewport'
import { theme } from './style'

const App = () => {
  const routes = useSelector((state) => state.routing.routes)
  const { vw, vh, vwfixed, vhfixed } = useViewport()

  document.documentElement.style.setProperty('--vw', `${vw}px`)
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
  document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const receiveRouting = useCallback((data) => dispatch(routingActions.receiveRouting(data)), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])
  const fetchMenu = useCallback((name) => dispatch(navActions.fetchMenu(name)), [dispatch])

  useEffect(() => {
    if (window.routing) {
      receiveRouting(window.routing)
    } else {
      fetchRouting()
    }
    fetchOptions()
    fetchMenu('main_menu')
    fetchMenu('extra_menu')
    fetchMenu('social_menu')
    fetchMenu('footer_menu')
    fetchMenu('header_menu')
  }, [])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <AudioContext>
            <LoadingContext>
              <MouseContext>
                <ScrollbarContext>
                  <Layout>
                    {Modernizr.ie11 && <BannerIE11 />}
                    <Routes routes={routes} />
                  </Layout>
                </ScrollbarContext>
              </MouseContext>
            </LoadingContext>
          </AudioContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
