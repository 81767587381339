import { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import { getBoundingClientRect } from '@/utils/dom'
import { useRaf } from '@/components/Handlers'
import { newMapRange } from '@/utils/math'
import style from './style'

const useStyles = createUseStyles(style)

const YoutubeVideoTimeline = ({
  video,
  onClickPause,
  goFullscreen,
}) => {
  const classes = useStyles(style)

  const getRoundedProgress = (progress) => Number(progress.toFixed(4))

  const getPercentageFromCurrentTime = ({
    duration,
    currentTime,
  }) => newMapRange(currentTime, [0, duration], [0, 1], true)

  const duration = useRef(video.getDuration() || 0)
  const $progress = useRef(null)
  const $wrapper = useRef(null)

  useRaf(() => {
    let progress = getPercentageFromCurrentTime({
      currentTime: video.getCurrentTime(),
      duration: duration.current,
    })
    progress = getRoundedProgress(progress)
    if ($progress.current) $progress.current.style.transform = `scaleX(${progress})`
  })

  const onProgressMouseDown = (e) => {
    const bounds = getBoundingClientRect($wrapper.current)
    const left = e.clientX - bounds.left
    let currentTime = newMapRange(
      left,
      [0, bounds.width],
      [0, duration.current],
      true,
    )
    currentTime = getRoundedProgress(currentTime)
    gsap.fromTo(
      $progress.current,
      {
        scaleX: getPercentageFromCurrentTime({
          currentTime: video.getCurrentTime(),
          duration: duration.current,
        }),
      },
      {
        scaleX: getPercentageFromCurrentTime({
          currentTime,
          duration: duration.current,
        }),
        ease: 'expo.inOut',
        duration: 0.8,
        onComplete: () => {
          video.seekTo(currentTime)
        },
      },
    )
  }

  return (
    <div className={classes.root}>
      <div
        className={`${classes.pause} youtubeVideoTimeline__pause`}
        onClick={onClickPause}
        role="presentation"
      >
        <span>
          pause
        </span>
      </div>
      <div
        className={`${classes.progressWrapper} youtubeVideoTimeline__progressWrapper`}
        onMouseDown={onProgressMouseDown}
        ref={$wrapper}
        role="presentation"
      >
        <div
          className={`${classes.progress} youtubeVideoTimeline__progress`}
          ref={$progress}
        />
      </div>
      <div
        className={`${classes.fullscreen} youtubeVideoTimeline__fullscreen`}
        onClick={goFullscreen}
        role="presentation"
      >
        <svg>
          <title>Fullscreen</title>
          <use xlinkHref="#ico-fullscreen" />
        </svg>
      </div>
    </div>
  )
}

export default YoutubeVideoTimeline
