const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'inline-block',
      transform: 'translate3d(0, 0, 0)',
    },
    svg: {
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translate3d(0, 0, 0)',
      zIndex: 1,
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
