import { useEffect, useContext, useRef, useState } from 'react'
import { Context as GeneralContext } from '@/context'
import { useRaf, useResize } from '@/components/Handlers'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import gsap from 'gsap'
import DelayLink from '@/components/DelayLink'
import SplitText from '@/vendors/bonus-files-for-npm-users/umd/SplitText'
import style from './style'

const useStyles = createUseStyles(style)

const ScrollIndicator = ({
  className,
  inverse,
  label,
  social,
  load,
  splitted = [0],
  onlySocial = false,
}) => {
  const { headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight, inverse, splitted, onlySocial })
  const $root = useRef()
  const $scroll = useRef()
  const $thumb = useRef()
  const $progress = useRef()
  const $discover = useRef()
  const $split = useRef()
  const theme = useTheme()

  const { scroll, limit, scrollbar } = useContext(ScrollbarContext)
  const scrollHeight = useRef(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    scrollHeight.current = $scroll.current.getBoundingClientRect().height - $thumb.current.getBoundingClientRect().height
  }, [])

  useEffect(() => {
    gsap.to($root.current, {
      opacity: load ? 1 : 0,
      delay: 2,
      duration: 2,
    })
  }, [load])

  useEffect(() => {
    if (label) {
      $split.current = new SplitText($discover.current, { type: 'chars' })
      $split.current.chars.forEach((s, i) => {
        s.style.setProperty('--index', i)
      })
    }
  }, [label])

  useResize(() => {
    scrollHeight.current = $scroll.current.getBoundingClientRect().height - $thumb.current.getBoundingClientRect().height
  })

  /*------------------------------
  Scroll Y
  ------------------------------*/
  useRaf(() => {
    if (scroll.current && limit.current) {
      setVisible(limit.current.y > 0 && scroll.current.y < 50)
      if (limit.current.y > 0 || theme.detect.isNativeScroll) {
        const prog = scroll.current.y / limit.current.y || 0
        gsap.to($thumb.current, {
          opacity: 1,
          scaleX: prog,
        })
        if ($progress.current) {
          $progress.current.innerHTML = prog !== null ? `${(gsap.utils.clamp(0, 1, prog) * 100).toFixed(0)}%` : '0%'
        }
      } else {
        gsap.to($thumb.current, {
          opacity: 0,
        })
      }
    }
  }, [scroll, limit])

  const renderButtons = () => {
    let oldWidth = 0
    return splitted.map((val, ind) => {
      const width = (ind === splitted.length - 1) ? 100 - val * 100 : (splitted[ind + 1] * 100 - oldWidth)
      oldWidth = splitted[ind + 1] * 100

      return (
        <button
          key={ind.toString()}
          className={classes.scrollButton}
          style={{ width: `${width}%` }}
          onClick={() => {
            scrollbar.scroll.scrollTo(Math.floor(parseInt(limit.current.y * val, 10)))
          }}
        >
          {val}
        </button>
      )
    })
  }

  const renderSVGMask = () => {
    let oldWidth = 0
    return (
      <svg width="0" height="0">
        <defs>
          <clipPath id="scrollMask">
            {
               splitted.map((val, ind) => {
                 const width = (ind === splitted.length - 1) ? (1 - val) * 125 : (splitted[ind + 1] * 125 - oldWidth)
                 oldWidth = splitted[ind + 1] * 125

                 return (
                   <rect
                     key={ind.toString()}
                     width={`${width - 3}`}
                     x={`${val * 125 + 3}`}
                     height={10}
                   />
                 )
               })
            }
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
      ref={$root}
      onMouseEnter={() => {
        if (scrollbar) scrollbar.update()
      }}
    >
      {social.length && (
      <div className={classes.socials}>
        {social.map((s, index) => (
          <DelayLink to={s.url} key={index.toString()} className={classes.social}>
            <svg>
              <title>{s.title}</title>
              <use xlinkHref={`#ico-${s.title.toLowerCase()}`} />
            </svg>
          </DelayLink>
        ))}
      </div>
      )}
      <div className={classes.scroll} ref={$scroll}>
        <div className={classes.stick}>
          <div className={classes.thumb} ref={$thumb} style={{ transform: 'scaleX(0)' }} />
          {renderButtons()}
          {renderSVGMask()}
        </div>
        <div className={classes.progress} ref={$progress}>0%</div>
      </div>
      <div
        ref={$discover}
        className={classNames({
          [classes.discover]: true,
          visible,
        })}
      >
        {label}
      </div>
    </div>
  )
}

/*------------------------------
Default Props
------------------------------*/
ScrollIndicator.defaultProps = {
  inverse: false,
  label: '',
  social: [],
}

export default ScrollIndicator
