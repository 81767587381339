const style = (theme) => {
  const output = {
    root: {
      maxWidth: 475,
      position: 'relative',
      zIndex: 1,
      margin: [0, 'auto'],
    },
    wrapper: {
      position: 'relative',
      paddingBottom: '110%',
      height: 0,
      zIndex: 1,
    },
    svg: {
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      width: '100%',
      height: 'auto',
    },

    button: {
      width: 30,
      height: 30,
      flex: '0 0 30px',
      borderRadius: '50%',
      background: theme.colors[4],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'transform .15s ease-out',
      '& svg': {
        width: 10,
        height: 10,
        fill: theme.colors[1],
      },
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    text: {
      position: 'relative',
      zIndex: 1,
      width: '100%',
      height: '55%',
      overflow: 'hidden',
      fontFamily: theme.fonts[1],
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    textSpan: {
      textAlign: 'center',
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      width: '100%',
      '& .revealText--wrap': {
        lineHeight: 0.9,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      width: '105%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    months: {
      top: '10%',
      zIndex: 1,
      height: '25%',
      fontSize: 85,
      color: '#eb89ea',
      '& $button': {
        background: '#eb89ea',
      },
    },
    years: {
      top: '35%',
      height: '55%',
      fontSize: 200,
      color: '#89c3eb',
      '& $button': {
        background: '#89c3eb',
      },
    },
    notAvailable: {
      fontFamily: theme.fonts[1],
      color: theme.colors[1],
      fontSize: 36,
      width: '100%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  }

  /*------------------------------
  Max height 760 && Max width 992px
  Max width 1410
  ------------------------------*/
  output['@media (min-width: 992px) and (max-height: 760px), (max-width: 1240px)'] = {
    root: {
      maxWidth: 330,
    },
    svg: {
      width: '90%',
    },
    months: {
      height: '22%',
      fontSize: 60,
    },
    years: {
      top: '32%',
      height: '50%',
      fontSize: 130,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      maxWidth: 240,
    },
    wrapper: {
      position: 'relative',
      paddingBottom: 0,
      height: 'auto',
    },
    header: {
      position: 'relative',
      left: 'auto',
      transform: 'none',
      margin: [10, 'auto'],
    },
    months: {
      fontSize: 50,
    },
    years: {
      fontSize: 50,
    },
    svg: {
      display: 'none',
    },
    text: {
      height: 50,
    },
    notAvailable: {
      fontSize: 16,
      fontFamily: theme.fonts[0],
    },
  }

  return output
}

export default style
