/* eslint-disable class-methods-use-this */
import {
  ShaderMaterial,
  UniformsUtils,
  MathUtils,
} from 'three'
import { Pass, FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass'
import zustand from '@/base/zustand'

class MousePass extends Pass {
  constructor() {
    super()
    this.time = 0
    this.speed = {
      x: 0,
      y: 0,
    }

    const shader = {

      uniforms: {
        uTex: { value: null },
        uTime: { value: 0 },
        uAspect: { value: { x: window.innerWidth, y: window.innerHeight } },
        uMouse: { value: { x: 0, y: 0 } },
        uDirection: { value: { x: 0, y: 0 } },
        uSpeed: { value: 0 },
        uDistortion: { value: 0.05 },
        uSize: { value: 0.15 },
      },

      vertexShader: /* glsl */`
    
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,

      fragmentShader: /* glsl */`
        uniform sampler2D uTex;
        uniform float uTime;
        uniform vec2 uMouse;
        uniform vec2 uDirection;
        uniform vec2 uAspect;
        uniform float uSpeed;
        uniform float uDistortion;
        uniform float uSize;

        varying vec2 vUv;
    
        void main() { 
          float aspect = max(uAspect.x / uAspect.y, uAspect.y / uAspect.x);
          vec2 uv = vUv;
          uv -= .5;
          uv -= uMouse * .5;
          if (uAspect.x / uAspect.y > 1.) {
            uv.x *= aspect;
          } else {
            uv.y *= aspect;
          }
          
          // Circle
          float c = (1. - smoothstep(0., uSize, length(uv))) * uSpeed;
          // c *= sin(atan(sin(uv.x * PI), sin(uv.y * PI)) * 2. + uSpeed * 2.);
          vec2 uv1 = vUv;
          uv1.x -= c * (uDistortion * uDirection.x + uSpeed * .01);
          uv1.y -= c * (uDistortion * uDirection.y + uSpeed * .01);

          vec3 color;
          /* Distorsione tutto */
          color = texture2D(uTex, uv1).rgb;

          /* Brightness */
          float brightness = 0.03;
          float contrast = 1.3;
          vec3 colorContrasted = color * contrast;
          vec3 bright = colorContrasted + vec3(brightness);

          /* Vignetting */
          vec2 uvVignetting = vUv - .5;
          uvVignetting *= .9;
          float vignetting = smoothstep(0.2, .6, 1. - length(uvVignetting));
          color = mix(color - .3, color, vignetting);

          gl_FragColor = vec4(color, 1.);
        }`,
    }

    this.uniforms = UniformsUtils.clone(shader.uniforms)

    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
    })

    this.fsQuad = new FullScreenQuad(this.material)
  }

  render(renderer, writeBuffer, readBuffer /* , deltaTime, maskActive */) {
    this.time += 0.05
    this.uniforms.uTex.value = readBuffer.texture
    this.uniforms.uTime.value = this.time

    // Mouse
    this.uniforms.uMouse.value.x = MathUtils.lerp(this.uniforms.uMouse.value.x, zustand.general.mouse.x, 0.1)
    this.uniforms.uMouse.value.y = MathUtils.lerp(this.uniforms.uMouse.value.y, -zustand.general.mouse.y, 0.1)

    // Mouse Speed
    this.speed.x = zustand.general.mouse.x.toFixed(2) - this.uniforms.uMouse.value.x.toFixed(2)
    this.speed.y = -zustand.general.mouse.y.toFixed(2) - this.uniforms.uMouse.value.y.toFixed(2)
    const speed = Math.max(
      Math.abs(this.speed.x),
      Math.abs(this.speed.y),
    )
    this.uniforms.uSpeed.value = MathUtils.lerp(this.uniforms.uSpeed.value, speed, 0.1)
    this.uniforms.uDirection.value.x = MathUtils.lerp(this.uniforms.uDirection.value.x, Math.sign(this.speed.x), 0.1)
    this.uniforms.uDirection.value.y = MathUtils.lerp(this.uniforms.uDirection.value.y, Math.sign(this.speed.y), 0.1)

    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      this.fsQuad.render(renderer)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      this.fsQuad.render(renderer)
    }
  }
}

export { MousePass }
