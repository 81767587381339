const style = (theme) => {
  const output = {
    root: {
      marginTop: '-50vh',
      height: 'var(--vh)',
      color: theme.colors[1],
      position: 'relative',
      zIndex: 1,
      textAlign: 'center',
      '&:before': {
        content: window.location.search.indexOf('debug') !== -1 ? '""' : '',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: 10,
        background: 'linear-gradient(to bottom, red, blue)',
      },
    },
    section: {
      position: 'relative',
      zIndex: 1,
      height: 'var(--vh)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sheet: {
      width: 440,
      height: 550,
      textAlign: 'left',
      color: '#53377f',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      padding: [70, 60],
      borderRadius: 10,
      boxShadow: '0 10px 30px -10px rgba(0, 0, 0, .2)',
    },
    title: {
      fontFamily: theme.fonts[1],
      fontSize: 60,
      lineHeight: 0.9,
      marginBottom: 50,
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    text: {
      fontSize: 17,
      lineHeight: 1.3,
      marginBottom: 0,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      marginTop: '0vh',
    },
    sheet: {
      width: '80%',
      height: 'calc(var(--vh) * .7)',
      padding: [40, 40],
    },
    title: {
      fontSize: 38,
      marginBottom: 30,
    },
    text: {
      fontSize: 15,
    },
  }

  return output
}

export default style
