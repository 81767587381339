const style = (theme) => {
  const output = {
    root: {
      width: theme.header.logo.width,
      height: theme.header.logo.height,
      fill: theme.colors[1],
    },
  }

  return output
}
export default style
