import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import zustand from '@/base/zustand'
import RevealText from '@/components/RevealText'
import { removeParagraph, removeBr } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const Section2 = ({ className, speed = 2, titleTop, titleBottom, text }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const $text1 = useRef()
  const $text2 = useRef()
  const $text3 = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const pause = {
    x: 0,
  }
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['2'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-2')) {
        ScrollTrigger.getById('section-2').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-2')) {
        ScrollTrigger.getById('section-2').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-2',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .addLabel('start')
        .fromTo($text1.current, {
          x: '200%',
          opacity: 0,
        }, {
          x: '0%',
          opacity: 1,
          duration: 1,
        }, 'start')
        .fromTo($text2.current, {
          x: '-200%',
          opacity: 0,
        }, {
          x: '0%',
          opacity: 1,
          duration: 1,
        }, 'start')
        .to(zustand.manifest.sections['2'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .to([$text1.current, $text2.current], {
          y: '-80%',
          stagger: '.05',
        }, 'start+=1')
        .to($text3.current.querySelectorAll('.line > div'), {
          y: '0%',
          scaleY: 1,
          duration: 0.5,
          stagger: 0.05,
        }, 'start+=1.1')
        .to(pause, {
          x: 1,
          duration: 0.5,
        })
        .to($text1.current, {
          x: '-200%',
          duration: 1,
          opacity: 0,
          ease: 'power1.in',
        })
        .to($text2.current, {
          x: '200%',
          duration: 1,
          opacity: 0,
          ease: 'power1.in',
        }, '<')
        .to($text3.current.querySelectorAll('.line > div'), {
          y: '-100%',
          scaleY: 1,
          duration: 0.5,
          stagger: 0.05,
        }, '<')

      if (theme.detect.debug) {
        window.console.log('section 2 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <p ref={$text1} className={classes.title}>{titleTop}</p>
        <p ref={$text2} className={classes.title}>{titleBottom}</p>
        <RevealText
          value={removeParagraph(window.innerWidth > 768 ? text : removeBr(text))}
          ref={$text3}
          className={classes.text}
          tag="div"
          type="lines"
        />
      </div>
    </section>
  )
}
export default Section2
