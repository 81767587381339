import { motion } from 'framer-motion'

const MountTransition = ({
  children,
  duration = 0.5,
  ease = 'easeOut',
  transition,
}) => (
  <motion.div
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, x: 0, y: 0 }}
    transition={{ duration: !transition ? 0 : duration, ease }}
  >
    {children}
  </motion.div>
)

MountTransition.defaultProps = {
  transition: true,
}

export default MountTransition
