import { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Context as LoadingContext } from '@/context/loading'
import style from './style'

const useStyles = createUseStyles(style)

const Spinner = () => {
  const { isLoading } = useContext(LoadingContext)
  const classes = useStyles()

  return (
    <div
      className={classNames({
        [classes.spinner]: true,
        [classes.visible]: isLoading,
      })}
    >
      <svg viewBox="0 0 50 50" className={classes.spinnerLoader}>
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="1" />
      </svg>
    </div>
  )
}

export default Spinner
