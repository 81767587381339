const style = (theme) => {
  const output = {
    root: {
      cursor: 'pointer',
      width: 60,
      height: 60,
      transform: 'translateY(-10px)',
      position: 'relative',
      zIndex: 1,
    },
    svg: {
      width: 60,
      height: 60,
    },
    magnetic: {
      padding: 10,
    },
    magneticAbsolute: {
      position: 'absolute',
      top: -10,
      left: -10,
    },
    path: {
      stroke: (props) => (props.headerInverse ? theme.colors[2] : theme.colors[1]),
      fill: 'none',
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      transition: 'stroke .3s',
    },
    stroke: {
      fill: 'none',
      strokeWidth: 1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      transition: 'stroke .3s',
    },
    stroke1: {
      stroke: theme.colors[3],
    },
    stroke2: {
      stroke: theme.colors[5],
    },
    isActive: {
      '& $path': {
        stroke: theme.colors[1],
      },
    },
  }

  return output
}

export default style
