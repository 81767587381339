/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { useRef, useEffect, useMemo, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { MathUtils } from 'three'
import { useRaf, useResize } from '@/components/Handlers'
import classNames from 'classnames'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const Line = ({ width, height, strokeWidth = 1, color = 'red', hover = false, offset = 0, amplitude }) => {
  const $path = useRef()
  const time = useRef(1)
  const { randFloat, lerp } = MathUtils
  const length = useRef()

  const { p1, p2, p4, p5 } = useMemo(() => {
    const p1 = {
      x: randFloat(0, offset),
      y: randFloat(height * 0.4, height * 0.6),
      time: randFloat(0.2, 0.4),
      rangeX: randFloat(-10, 10),
      rangeY: randFloat(-amplitude * 0.5, amplitude * 0.5),
    }

    const p2 = {
      x: randFloat(offset + width * 0.2, offset + width * 0.3),
      y: randFloat(height * 0.4, height * 0.6),
      time: randFloat(0.2, 0.4),
      rangeX: randFloat(-5, 5),
      rangeY: randFloat(-amplitude, amplitude),
    }

    const p4 = {
      x: randFloat(offset + width * 0.7, offset + width * 0.8),
      y: randFloat(height * 0.4, height * 0.6),
      time: randFloat(0.2, 0.4),
      rangeX: randFloat(-5, 5),
      rangeY: randFloat(-amplitude, amplitude),
    }

    const p5 = {
      x: randFloat(width + offset, width + offset * 2),
      y: randFloat(height * 0.4, height * 0.6),
      time: randFloat(0.2, 0.4),
      rangeX: randFloat(-10, 10),
      rangeY: randFloat(-amplitude * 0.5, amplitude * 0.5),
    }

    setTimeout(() => {
      length.current = ($path.current && $path.current.getTotalLength() + 40)?.toFixed(0)
      gsap.set($path.current, {
        strokeDasharray: length.current,
        strokeDashoffset: length.current,
      })
    }, 1000)

    return { p1, p2, p4, p5 }
  }, [width])

  const render = () => {
    const point1 = {
      x: p1.x + Math.cos(p1.time * time.current) * p1.rangeX,
      y: p1.y + Math.sin(p1.time * time.current) * p1.rangeY,
    }

    const point2 = {
      x: p2.x + Math.cos(p2.time * time.current) * p2.rangeX,
      y: p2.y + Math.cos(p2.time * time.current) * p2.rangeY,
    }

    const point4 = {
      x: p4.x + Math.cos(p4.time * time.current) * p4.rangeX,
      y: p4.y + Math.cos(p4.time * time.current) * p4.rangeY,
    }

    const point5 = {
      x: p5.x + Math.cos(p5.time * time.current) * p5.rangeX,
      y: p5.y + Math.cos(p5.time * time.current) * p5.rangeY,
    }

    return (
      `
        M ${point1.x} ${point1.y}
        
        Q ${point2.x} ${point2.y}
        ${lerp(point2.x, point4.x, 0.5)}, ${lerp(point2.y, point4.y, 0.5)}

        Q ${point4.x} ${point4.y}
        ${point5.x} ${point5.y}
      `
    )
  }

  const handleResize = () => {
    if ($path.current) {
      length.current = ($path.current && $path.current.getTotalLength() + 40).toFixed(0)
      gsap.set($path.current, {
        strokeDasharray: length.current,
        strokeDashoffset: length.current,
      })
    }
  }

  useResize(() => {
    setTimeout(() => {
      handleResize()
    }, 300)
  })

  useEffect(() => {
    if (hover) {
      gsap.to($path.current, {
        startAt: {
          strokeDashoffset: length.current,
        },
        strokeDashoffset: 0,
        duration: 1,
        ease: 'power4.out',
      })
    } else {
      gsap.to($path.current, {
        strokeDashoffset: -length.current,
        duration: 1,
        ease: 'power4.out',
      })
    }
  }, [hover])

  useRaf(() => {
    if (!hover) return
    time.current += 0.1
    if ($path.current) {
      $path.current.setAttribute('d', render())
    }
  }, [hover])

  return (
    <path
      ref={$path}
      fillRule="evenodd"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="butt"
      fill="none"
      d={render()}
    />
  )
}

const StrokeButton = ({
  children,
  className,
  offset = 50,
  amplitude = 20,
  isHover = false,
  ...props
}) => {
  const classes = useStyles()
  const $root = useRef()
  const $svg = useRef()
  const [hover, setHover] = useState(false)
  const theme = useTheme()
  const [bounds, setBounds] = useState({ width: 0, height: 0 })

  useResize(() => {
    setTimeout(() => {
      if ($root.current) {
        setBounds($root.current.getBoundingClientRect())
      }
    }, 100)
  })

  useEffect(() => {
    setTimeout(() => {
      setHover(isHover)
    }, 10)
  }, [isHover, bounds])

  return (
    <div
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {children}
      { !theme.detect.isSafari && (
      <svg
        className={classes.svg}
        width={bounds.width + offset * 2}
        height={bounds.height}
        ref={$svg}
        style={{ left: -offset }}
      >
        {bounds.width !== 0 && <Line amplitude={amplitude} offset={offset} width={bounds.width} height={bounds.height} color="#aaaeff" hover={hover} />}
        {bounds.width !== 0 && <Line amplitude={amplitude} offset={offset} width={bounds.width} height={bounds.height} color="#89c3eb" strokeWidth={3} hover={hover} />}
        {bounds.width !== 0 && <Line amplitude={amplitude} offset={offset} width={bounds.width} height={bounds.height} color="#c59fff" strokeWidth={2} hover={hover} />}
      </svg>
      )}
    </div>
  )
}

export default StrokeButton
