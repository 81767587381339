import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useParams, useHistory } from 'react-router-dom'
import CreateJs from 'preload-js'
import gsap from 'gsap'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import DelayLink from '@/components/DelayLink'
import StrokeButton from '@/components/StrokeButton'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import PageElements from '@/components/PageElements'
import { convertDate } from '@/utils/strings'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

/*------------------------------
Hero
------------------------------*/
const Hero = ({
  title, date, image, ready, color, backLink,
}) => {
  const { headerHeight, isMenuOpen } = useContext(GeneralContext)
  const classes = useStyles({ color, headerHeight })
  const history = useHistory()

  return (
    <div className={classes.hero}>
      <div className={classes.wrapper}>
        <DelayLink
          to={backLink}
          className={classNames({
            [classes.back]: true,
            [classes.hide]: isMenuOpen,
          })}
          onClick={() => history.goBack()}
          aria-label="go-back"
        >
          <svg><use xlinkHref="#ico-arrow-left" /></svg>
        </DelayLink>
        <div className={classes.heroHeader}>
          <RevealText
            tag="h1"
            className={`${classes.heroTitle}`}
            type="lines"
            lineHeight={0.85}
            value={title}
            visible={ready}
          />
          <RevealText
            tag="div"
            className={`${classes.heroDate}`}
            type="lines"
            value={date}
            visible={ready}
          />
        </div>
        <RevealFadeIn
          className={classes.heroImage}
          visible={ready}
          delay={0.5}
        >
          <img src={image} alt={title} />
        </RevealFadeIn>
      </div>
    </div>
  )
}

const Post = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const { stub } = useParams()
  const $root = useRef()
  const color = useRef()
  const [isNextPostClicked, setNextPostClicked] = useState(false)

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { post, strings } = useSelector((state) => ({
    post: state.content.posts.find((p) => p.slug === stub) || {},
    strings: state.options.strings,
  }), shallowEqual)

  const classes = useStyles({ headerHeight, color: color.current })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPost = useCallback((slug) => dispatch(contentActions.fetchPost(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    preload.current.loadFile({ id: 'featured_image', src: post.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(post).length > 0) {
      preloadMedia()
      color.current = post.acf?.post_color
      gsap.to('body', {
        background: `linear-gradient(to bottom, ${color.current}, ${color.current})`,
      })
    }
  }, [post])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = () => {
    fetchPost(stub)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData()
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Back button visible
  ------------------------------*/
  // useEffect(() => {
  //   setBackButtonVisible(true)
  //   return () => {
  //     setBackButtonVisible(false)
  //   }
  // }, [])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={post.title.rendered}
        meta={post.yoast_meta}
        schema={post.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Elements
  ------------------------------*/
  const renderElements = () => {
    return post.acf.page_builder && (
      <PageElements
        className={classes.pageElements}
        elements={post.acf.page_builder}
      />
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          <Hero
            title={post.title.rendered}
            image={post.featured_image}
            date={convertDate(post.date)}
            ready={load && !isNextPostClicked}
            backLink={strings.blog_url}
          />
          {renderElements()}
        </div>
        {post.siblings?.next && (
          <DelayLink
            to={post.siblings.next.link}
            id="next-post"
            className={classNames({
              [classes.nextPost]: true,
              [classes.nextPostVisibility]: isNextPostClicked,
            })}
            style={{ background: post.siblings.next.color }}
            onClickCapture={() => {
              setNextPostClicked(true)
              gsap.to('body', {
                background: `linear-gradient(to bottom, ${post.siblings.next.color}, ${post.siblings.next.color})`,
                delay: 0.3,
              })
            }}
            delay={1000}
          >
            <StrokeButton
              amplitude={10}
              offset={0}
            >
              <Hero
                title={post.siblings.next.title}
                image={post.siblings.next.cover.full[0]}
                date={convertDate(post.siblings.next.date)}
                ready={load && !isNextPostClicked}
              />
            </StrokeButton>
          </DelayLink>
        )}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pagePost ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Post
