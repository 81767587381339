const style = {
  root: {
    border: 'none',
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    touchAction: 'none',
    display: 'inline-block',

    '& span': {
      display: 'inline-block',
    },

    hover: {
      position: 'absolute',
      zIndex: '-1',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
  item: {},
}

export default style
