const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none',
      },
    },
    poster: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      zIndex: 2,
      transition: 'all 0.3s ease-in',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: theme.getRgba(theme.colors[0], 0.2),
      },
    },
    posterHide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    timeline: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '40px 30px 20px 30px',
      background: 'linear-gradient(to top, rgba(0, 0, 0, .3)0, rgba(0, 0, 0, 0) 20%)',
    },
    play: {
      position: 'absolute',
      top: 'calc(50% - 40px)',
      left: 'calc(50% - 40px)',
      borderRadius: '50%',
      width: 80,
      height: 80,
      cursor: 'pointer',
      background: theme.getRgba(theme.colors[1], 0.4),
      transition: `all .3s ${theme.easings['power4.out']}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 25,
        height: 25,
        marginLeft: 5,
        fill: theme.colors[1],
        transition: `all .3s ${theme.easings['power4.out']}`,
      },
      '&:hover': {
        background: theme.colors[1],
        '& svg': {
          fill: theme.getRgba(theme.colors[2], 0.4),
        },
      },
    },
    playHide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    '@keyframes rotate': {
      from: { transform: 'translate(-50%, -50%) rotate(0)' },
      to: { transform: 'translate(-50%, -50%) rotate(360deg)' },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60px',
      height: '60px',
      transformOrigin: 'center',
      animation: '$rotate 1s linear infinite',
      transition: 'all 0.3s ease-in',
      opacity: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      '& svg': {
        stroke: 'white',
        strokeWidth: 1,
        fill: 'none',
        width: 60,
        height: 60,
        animation: '$dash 1.5s ease-in-out infinite',
      },
    },
    loadingShow: {
      opacity: 1,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    play: {
      '&:before': {
        marginTop: -20,
        borderLeft: `25px solid ${theme.colors[1]}`,
        borderTop: '15px solid transparent',
        borderBottom: '15px solid transparent',
      },
      '&:hover': {
        paddingTop: 29,
        color: theme.getRgba(theme.colors[1], 0),
        '&:before': {
          borderLeftColor: theme.colors[4],
          transform: 'translate(-50%, 7px) scale(.5)',
        },
        '&:after': {
          transform: 'translate(-50%, -50%) scale(.8)',
        },
      },
    },
  }
  return output
}

export default style
