import { useEffect, useRef, useContext } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import { Context } from '@/context'
import MagneticButton from '@/components/MagneticButton'
import style from './style'
import { theme } from '../../style'

const useStyles = createUseStyles(style)

const Burger = ({ isActive, isHover }) => {
  const { headerInverse } = useContext(Context)
  const classes = useStyles({ headerInverse })
  const $path1 = useRef()
  const $path2 = useRef()
  const $path3 = useRef()
  const $stroke1 = useRef()
  const $stroke2 = useRef()

  useEffect(() => {
    gsap.set($path3.current, {
      strokeDasharray: '30px',
      strokeDashoffset: '0px',
    })

    gsap.set([$path1.current, $path2.current], {
      strokeDasharray: '15px 50px',
      strokeDashoffset: '0px',
    })

    gsap.set([$stroke1.current, $stroke2.current], {
      strokeDasharray: '147px',
      strokeDashoffset: '147px',
    })
  }, [])

  useEffect(() => {
    if (!isActive) {
      gsap.to($path1.current, {
        strokeDashoffset: isHover ? '-14px' : '0px',
        strokeDasharray: '15px 50px',
        stroke: theme.colors[1],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to($path2.current, {
        strokeDashoffset: isHover ? '0px' : '-14px',
        strokeDasharray: '15px 50px',
        stroke: theme.colors[1],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to($path3.current, {
        strokeDashoffset: '0px',
        stroke: theme.colors[1],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to([$stroke1.current, $stroke2.current], {
        strokeDashoffset: '147px',
        stagger: 0.1,
        delay: 0.1,
        ease: 'power3.out',
        duration: 0.5,
      })
    } else {
      gsap.to($path1.current, {
        strokeDashoffset: '21.5px',
        strokeDasharray: '15px 50px',
        stroke: theme.colors[2],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to($path2.current, {
        strokeDashoffset: '21px',
        strokeDasharray: '15px 50px',
        stroke: theme.colors[2],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to($path3.current, {
        strokeDashoffset: '-30px',
        stroke: theme.colors[2],
        ease: 'power3.out',
        duration: 0.5,
      })
      gsap.to([$stroke1.current, $stroke2.current], {
        strokeDashoffset: '0px',
        stagger: 0.1,
        delay: 0.2,
        ease: 'power3.out',
        duration: 0.5,
      })
    }
  }, [isHover, isActive])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.isActive]: isActive,
        [classes.isHover]: isHover,
      })}
    >
      <MagneticButton
        className={classes.magnetic}
        speed={0.5}
      >
        <svg className={classes.svg} viewBox="0 0 60 60">
          <path
            ref={$stroke1}
            className={classNames({
              [classes.stroke]: true,
              [classes.stroke1]: true,
            })}
            d="M12.9,29.6c-1.5,5.7-1.3,24.5,10.3,24.7c15.8,0.2,23.6-7.1,24.1-19c0.6-14.8-6.4-24-18.8-26.2 C13,6.4-0.4,21.6,4.3,37.1"
          />
          <path
            ref={$stroke2}
            className={classNames({
              [classes.stroke]: true,
              [classes.stroke2]: true,
            })}
            d="M47.5,20.5c-2.9-4.4-6.6-14.8-18.7-14.8C15.6,5.7,7.2,16.2,7.2,29.3c0,13.1,10.7,23.6,23.8,23.8 C47.5,53.2,61,33.3,55.4,18.8"
          />

        </svg>
        <MagneticButton
          className={classNames({
            [classes.magnetic]: true,
            [classes.magneticAbsolute]: true,
          })}
          speed={0.5}
        >
          <svg className={classes.svg} viewBox="0 0 60 60">

            <line
              ref={$path3}
              className={classes.path}
              x1="15"
              y1="30"
              x2="44"
              y2="30"
            />
            <path
              ref={$path1}
              className={classes.path}
              d="M15,24h29l0.2-0.1c1.3-0.9,1-2.9-0.5-3.3l0,0c-2.2-0.7-4.5-0.2-6.1,1.4L29.5,30l-6.1,6.1"
            />
            <path
              ref={$path2}
              className={classes.path}
              d="M15,36.1h29l0.2,0.1c1.3,0.9,1,2.9-0.5,3.3l0,0c-2.2,0.7-4.5,0.2-6.1-1.4l-8.1-8.1l-6.1-6.1"
            />
          </svg>
        </MagneticButton>
      </MagneticButton>
    </div>
  )
}

export default Burger
