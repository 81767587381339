import { RECEIVE_ROUTING } from '@/actions/types'

const initialState = {
  routes: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ROUTING:
      state = {
        ...state,
        routes: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
