import { useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import injectSheet from 'react-jss'
import { Context } from '@/context'
import style from './style'

const AudioWave = ({
  classes,
  className,
  isActive,
  isHover,
}) => {
  const { headerInverse } = useContext(Context)
  const [inverse, setInverse] = useState(headerInverse)

  useEffect(() => {
    setInverse(headerInverse)
  }, [headerInverse])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
        [classes.inverse]: inverse,
        [classes.active]: isActive,
        [classes.isHover]: isHover,
      })}
    >
      <svg viewBox="0 0 24 19.9">
        <path d="M16.5,1.2v17.5L1.4,9.9L16.5,1.2 M17.2,0L0,9.9l17.2,9.9V0L17.2,0z" />
        <rect x="20.1" y="5.7" width="0.7" height="8.7" />
        <rect x="23.3" y="2.5" width="0.7" height="15.2" />
      </svg>

      <div>
        Audio
        <em>
          <span>off</span>
          <span>on</span>
        </em>
      </div>
    </div>
  )
}

export default injectSheet(style)(AudioWave)
