import { useRef, useEffect, useState, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import zustand from '@/base/zustand'
import HTMLParse from 'react-html-parser'
import StrokeButton from '@/components/StrokeButton'
import style from './style'

const useStyles = createUseStyles(style)

const Section4 = ({ className, speed = 2 }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const $title = useRef()
  const $span = useRef()
  const classes = useStyles()
  const [lines, setLines] = useState(false)
  const theme = useTheme()
  const pause = {
    x: 0,
  }
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['4'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-4')) {
        ScrollTrigger.getById('section-4').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-4')) {
        ScrollTrigger.getById('section-4').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-4',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
          onUpdate: (self) => setLines(self.progress > 0.5),
        },
      })
        .addLabel('start')
        .to(zustand.manifest.sections['4'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .fromTo($title.current, {
          x: '200%',
          opacity: 0,
        }, {
          x: '0%',
          opacity: 1,
          duration: 2,
        }, 'start')
        .to($span.current, {
          opacity: 0.2,
        })
        .to(pause, {
          x: 1,
          duration: 3,
        })

      if (theme.detect.debug) {
        window.console.log('section 4 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <h2 ref={$title} className={classes.title}>
          <span ref={$span}>
            {HTMLParse(`This is the true ${window.innerWidth < 768 ? '<br />' : ''} essence of`)}
          </span>
          {' '}
          <StrokeButton offset={20} className={classes.button} isHover={lines}>perfection</StrokeButton>
        </h2>
      </div>
    </section>
  )
}
export default Section4
