const style = (theme) => {
  const output = {
    root: {
      '--font-size': '18px',
      '& > *:not(:first-child)': {
        marginTop: 80,
      },
      '& > *:last-child': {
        marginBottom: 80,
      },
    },
    wrapper: {
      extend: theme.wrapper,
      margin: [0, 'auto'],
      maxWidth: 1920,
    },
    smallWrapper: {
      maxWidth: 740,
      margin: [0, 'auto'],
    },
    columnTextTrigger: {
      display: 'flex',
    },
    titleContainer: {
      width: '30%',
      marginRight: '10%',
      '& h2': {
        fontFamily: theme.fonts[0],
        fontSize: 40,
        color: theme.colors[1],
        lineHeight: 60,
        paddingTop: 20,
      },
    },
    textContainer: {
      width: '60%',
      '& > p': {
        fontSize: 'var(--font-size)',
        color: theme.colors[1],
      },
    },

    singleImage: {
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& span': {
        position: 'relative',
        paddingBottom: '55%',
        overflow: 'hidden',
      },
    },

    simpleText: {
      '& h2': {
        fontSize: 40,
        color: theme.colors[1],
        lineHeight: 40,
      },
      '& p': {
        marginBottom: '1em',
      },
    },

    text: {},
    title: {
      fontSize: '60px!important',
    },
    simpleTitleText: {
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        marginTop: '1em',
        marginBottom: '1em',
        fontSize: 60,
        color: theme.colors[1],
        lineHeight: 0.9,
      },
      '& h2': {
        fontSize: 40,
        lineHeight: 1,
      },
      '& h3': {
        fontSize: 30,
        lineHeight: 1.1,
      },
      '& h4, & h5, & h6': {
        lineHeight: 1.1,
        fontSize: 20,
      },
      '& $text': {
        marginTop: 40,
        fontSize: 'var(--font-size)',
      },
      '& ul': {
        marginBottom: '2em',
        '& li': {
          color: theme.colors[1],
          marginBottom: 10,
          position: 'relative',
          paddingLeft: 30,
          '&:before': {
            content: '""',
            width: 6,
            height: 6,
            borderRadius: 6,
            position: 'absolute',
            zIndex: 1,
            left: 8,
            top: 9,
            background: theme.colors[1],
          },
        },
      },
    },

    arrowList: {
      '& ul': {
        '& li': {
          color: theme.colors[1],
          marginBottom: 10,
          position: 'relative',
          paddingLeft: 30,
          '& svg': {
            position: 'absolute',
            zIndex: 1,
            left: 8,
            top: 9,
            fill: theme.colors[1],
            width: 6,
            height: 6,
          },
        },
      },
    },

    doubleImage: {
      display: 'flex',
      '& h3': {
        marginTop: 20,
        fontSize: 12,
        color: theme.colors[1],
        fontFamily: theme.fonts[1],
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      '& p': {
        marginTop: 20,
        fontSize: 'var(--font-size)',
        color: theme.colors[5],
      },
    },

    triggerDoubleImageN1: {
      width: '45%',
      marginRight: '10%',
    },

    triggerDoubleImageN2: {
      width: '45%',
    },

    doubleImageN1: {
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& span': {
        position: 'relative',
        paddingBottom: '100%',
        overflow: 'hidden',
      },
    },
    doubleImageN2: {
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& span': {
        position: 'relative',
        paddingBottom: '65%',
        overflow: 'hidden',
      },
    },

    gallery: {
      '& .slick-dots li button::before': {
        border: 'none',
        // borderColor: `${theme.colors[2]} !important`,
        backgroundColor: theme.colors[1],
        borderRadius: '50%',
      },
      '& .slick-dots li.slick-active button::before': {
        background: theme.colors[0],
      },
      '& .slick-dots li button': {
        overflow: 'hidden',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      '& > *:not(:first-child)': {
        marginTop: 120,
      },
      '& > *:last-child': {
        marginBottom: 120,
      },
    },
    smallWrapper: {
      maxWidth: 'none',
      paddingLeft: '4.2vw',
      paddingRight: '4.2vw',
    },
    columnTextTrigger: {
      display: 'block',
    },
    titleContainer: {
      width: '100%',
      marginRight: 0,
      '& h2': {
        fontSize: 25,
        lineHeight: 32,
      },
    },
    textContainer: {
      marginTop: 30,
      width: '100%',
      '& > p': {
        columns: 'auto 1',
        columnGap: 0,
      },
    },

    doubleImage: {
      display: 'block',
      '& p': {
        marginTop: 20,
        fontSize: 'var(--font-size)',
        color: theme.colors[5],
      },
    },

    triggerDoubleImageN1: {
      width: '100%',
      marginRight: 0,
    },

    triggerDoubleImageN2: {
      width: '100%',
      marginTop: 60,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      '--font-size': '16px',
      '& > *:not(:first-child)': {
        marginTop: 50,
      },
      '& > *:last-child': {
        marginBottom: 50,
      },
    },
    title: {
      fontSize: '40px!important',
    },
    simpleTitleText: {
      '& h1': {
        fontSize: 40,
      },
      '& h2': {
        fontSize: 32,
        lineHeight: 1.1,
      },
      '& h3': {
        fontSize: 30,
      },
    },
  }

  return output
}

export default style
