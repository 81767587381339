const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
      '& a': {
        color: theme.colors[1],
      },
    },
    page: {
    },
    wrapper: {
      extend: theme.wrapper,
      margin: [0, 'auto'],
      maxWidth: 1920,
    },
    hero: {
      paddingTop: (props) => props.headerHeight + 60,
      marginBottom: 80,
      position: 'relative',
      zIndex: 1,
    },
    heroHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      padding: [0, 90],
      marginBottom: 20,
    },
    heroTitle: {
      flex: '0 0 50%',
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: '8vw',
      lineHeight: 0.85,
      fontWeight: 'normal',
      '& h1': {
        marginBottom: 0,
      },
    },
    heroDate: {
      fontSize: 17,
      paddingBottom: '2.1vw',
    },
    back: {
      position: 'absolute',
      zIndex: 2,
      top: (props) => `calc(${props.headerHeight + 45}px + 4.8vw)`,
      left: 'calc(4.2vw + 4px)',
      pointerEvents: 'all',
      width: 30,
      height: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      background: theme.colors[1],
      cursor: 'pointer',
      transition: 'opacity .2s ease-out',
      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.8,
        },
      },
      '& svg': {
        width: 10,
        height: 10,
        fill: theme.colors[0],
      },
    },
    nextPost: {
      overflow: 'hidden',
      display: 'block',
      transition: 'opacity 0.6s ease-out',
      textAlign: 'center',
      '& $hero': {
        transform: 'translateY(6px)',
        paddingTop: 60,
        marginBottom: 0,
        textAlign: 'left',
      },
      '& $heroImage': {
        overflow: 'hidden',
        height: 100,
      },
      '& img': {
        transition: 'opacity 0.3s ease-out',
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& img': {
            opacity: 0.3,
          },
        },
      },
      '& $back': {
        display: 'none',
      },
    },
    nextPostVisibility: {
      opacity: 0,
    },
    heroImage: {
      '& img': {
        width: '100%',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    heroHeader: {
      padding: 0,
    },
    back: {
      top: (props) => props.headerHeight + 33,
      left: '4.2vw',
    },
    heroDate: {
      fontSize: 13,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    hero: {
      paddingTop: (props) => props.headerHeight + 10,
    },
    heroHeader: {
      display: 'block',
    },
    heroTitle: {
      fontSize: 70,
    },
    back: {
      display: 'none',
    },
    nextPost: {
      display: 'block',
      maxHeight: '120vw',
    },
  }

  return output
}

export default style
