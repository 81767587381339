const style = (theme) => {
  const output = {
    point: {
      position: 'relative',
      zIndex: 1,
      width: 400,
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors[3],
      fontFamily: theme.fonts[1],
      fontSize: 70,
      textAlign: 'center',
      padding: [0, 50],
      lineHeight: 0.85,
      textTransform: 'uppercase',
      '& svg': {
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontSize: 7,
        fontFamily: theme.fonts[0],
        userSelect: 'none',
        transition: 'transform 1s ease-in-out',
        transform: 'scale(.5)',
      },
      '&.inview': {
        '& svg': {
          transform: 'scale(1)',
        },
      },
    },
    pointNum: {
      display: 'block',
      margin: [0, 'auto', 20],
      width: 30,
      height: 30,
      borderRadius: '50%',
      fontSize: 15,
      lineHeight: 1.9,
      fontFamily: theme.fonts[0],
      color: theme.colors[0],
      background: theme.colors[1],
      textAlign: 'center',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    point: {
      textAlign: 'left',
      width: '100%',
      height: 'auto',
      position: 'relative',
      margin: '0px 0px 20px 0px !important',
      paddingLeft: 40,
      transform: 'none!important',
      fontSize: 40,
      display: 'block',
      color: theme.colors[1],
      '& svg': {
        display: 'none',
      },
    },
    pointNum: {
      width: 20,
      height: 20,
      fontSize: 10,
      lineHeight: 2,
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 8,
    },
  }

  return output
}

export default style
