import { useEffect, useRef, useState, useCallback } from 'react'
import classNames from 'classnames'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import gsap from 'gsap'
import injectSheet from 'react-jss'
import * as layerActions from '@/actions/layer'
import style from './style'

const Overlay = ({
  classes,
}) => {
  const $root = useRef()
  const [isOverlayOpen, setOverlayOpen] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  useEffect(() => {
    setOverlayOpen(isMenuOpen)
  }, [isMenuOpen])

  useEffect(() => {
    gsap.set($root.current, { opacity: 0 })
  }, [])

  const fadeOverlay = (opt) => {
    gsap.to($root.current, {
      duration: 1,
      opacity: opt === 'in' ? 1 : 0,
      ease: 'power3.out',
    })
  }

  const handleClick = () => {
    if (isMenuOpen) closeMenu()
  }

  useEffect(() => {
    if (isOverlayOpen) fadeOverlay('in')
    if (!isOverlayOpen) fadeOverlay('out')
  }, [isOverlayOpen])

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [classes.visible]: isOverlayOpen,
      })}
      onClick={handleClick}
    />
  )
}

export default injectSheet(style)(Overlay)
