const style = (theme) => {
  const output = {
    root: {
    },
    page: {
      minHeight: 'var(--vh)',
      paddingTop: (props) => props.headerHeight,
      paddingBottom: (props) => props.headerHeight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    left: {
      flex: '0 0 510px',
      paddingLeft: 90,
    },
    right: {
      flex: '1 1 auto',
    },
    title: {
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      lineHeight: 0.85,
      fontSize: 130,
      fontWeight: 'normal',
      margin: 0,
    },
    text: { marginBottom: 10 },
    text2: {
      color: '#fe9cff',
      text: { marginBottom: 0 },
    },

    // STEP INDICATOR
    stepIndicator: {
      position: 'fixed',
      zIndex: 3,
      top: 0,
      width: '100%',
      height: 'var(--vh)',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: 60,
      extend: theme.wrapper,
      fontSize: 11,
      color: theme.colors[1],
      transition: 'opacity 0.4s ease-out',
      opacity: 0,
      '&.active': {
        opacity: 1,
      },
    },
    actualStep: {
      transform: 'translateY(4px)',
      width: 90,
      pointerEvents: 'all',
    },
    stepIndicatorButton: {
      width: 100,
      textAlign: 'center',
      paddingBottom: 12,
      position: 'relative',
      zIndex: 1,
      margin: [0, 3],
      fontSize: 11,
      color: theme.colors[1],
      border: 'none',
      fontFamily: theme.fonts[0],
      pointerEvents: 'all',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        left: 0,
        bottom: 0,
        height: 4,
        background: theme.colors[1],
        borderRadius: 4,
        opacity: 0.3,
        transition: 'opacity 0.2s ease-out',
      },
    },
    stepIndicatorButtonActive: {
      '&:before': {
        opacity: 1,
      },
    },
    stepControllers: {
      flex: '1 1 auto',
      textAlign: 'right',
      transform: 'translateY(16px)',

    },
    button: {
      extend: theme.button,
      pointerEvents: 'all',
    },
    buttonDisabled: {
      cursor: 'not-allowed',
      opacity: '.3',
    },
    buttonPrevStep: {
      width: 42,
      height: 42,
      top: 0,
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      background: theme.getRgba(theme.colors[1], 0.5),
      cursor: 'pointer',
      fontSize: 0,
      position: 'absolute',
      zIndex: 1,
      right: 100,
      pointerEvents: 'all',
      display: 'none',
      '& svg': {
        width: 10,
        height: 10,
        fill: theme.colors[0],
      },
    },

    // STEP
    step: {
      extend: theme.wrapper,
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      color: theme.colors[1],
      top: 0,
      left: 0,
      zIndex: 1,
      transition: 'opacity 0.4s ease-out',
      opacity: 0,
      pointerEvents: 'none',
      '&.active': {
        pointerEvents: 'all',
      },
    },

    // STEP 0
    step0: {
      height: 'auto!important',
      '& $text': {
        marginTop: 100,
      },
    },
    start: {
      position: 'absolute',
      top: 190,
      left: 340,
      zIndex: 1,
      '@media (hover: hover)': {
        '&:hover': {
          '& $startButton': {
            '&:before': {
              transform: 'translate(-50%, -50%) scale(1.1)',
            },
          },
          '& $startStrokeBall': {
            transform: 'translate(-50%, -50%) scale(0.95) rotate(30deg)',
          },
        },
      },
    },
    startButton: {
      width: 280,
      height: 280,
      borderRadius: '50%',
      color: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 20,
      lineHeight: 0.9,
      cursor: 'pointer',
      '&:before': {
        content: '""',
        background: theme.getRgba(theme.colors[3], 0.86),
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        transition: `transform .5s ${theme.easings['power3.out']}`,
        position: 'absolute',
        zIndex: -1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    magnetic: {
      padding: 30,
      margin: [-30, 0, 0, -30],
    },
    insideMagnetic: {
      padding: 80,
      display: 'inline-block',
    },
    startStrokeBall: {
      position: 'absolute',
      zIndex: -1,
      top: '50%',
      left: '50%',
      width: '140%',
      height: '140%',
      transform: 'translate(-50%, -50%)',
      transition: `transform .5s ${theme.easings['power3.out']}`,
      marginLeft: -15,
      marginTop: -15,
    },
    strokeBall: {
      width: '100%',
      height: '100%',
      '&:nth-child(2)': {
        transform: 'rotate(90deg)',
      },
      '&:nth-child(3)': {
        transform: 'rotate(120deg)',
      },
    },

    // STEP 1
    step1: {},
    whoSelector: {},

    // STEP 2
    step2: {},
    chooseType: {
      position: 'relative',
      zIndex: 1,
      paddingTop: 110,
      display: 'flex',
      flexDirection: 'column',
    },
    orType: {
      position: 'absolute',
      fontSize: 11,
      top: '222px',
      left: '385px',
    },
    buttonType: {
      alignSelf: 'flex-start',
      cursor: 'pointer',
      '& $title': {
        '& .line': {
          transition: 'opacity 0.2s ease-out',
          opacity: 0.2,
        },
      },
      '&.active': {
        pointerEvents: 'none',
        '& $title': {
          '& .line': {
            opacity: 1,
          },
        },
      },
      '&:hover': {
        '& $title': {
          '& .line': {
            opacity: 0.4,
          },
        },
      },
    },
    buttonTypeB: {
      marginTop: -51,
      marginLeft: 168,
    },

    // STEP 3
    step3: {},
    budgets: {
      position: 'relative',
      height: '100%',
      zIndex: 1,
    },
    budgetButton: {
      position: 'absolute',
      zIndex: 1,
      width: 150,
      height: 150,
      borderRadius: '50%',
      display: 'flex',
      background: 'transparent',
      fontFamily: theme.fonts[1],
      color: theme.colors[1],
      fontSize: 40,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      top: 0,
      left: 0,
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: theme.colors[3],
        transform: 'scale(0)',
        transition: `transform .5s ${theme.easings['power3.out']}`,
      },
      '& svg': {
        position: 'absolute',
        zIndex: -2,
        top: '-10%',
        left: '-10%',
        width: '120%',
        height: '120%',
        transition: 'opacity .3s ease-out',
      },
      '&.active': {
        '&:before': {
          transform: 'scale(1)',
        },
        '& svg': {
          opacity: 0,
        },
      },
    },
    magneticBudget: {
      padding: 30,
    },
    budgetButton2: {
      top: '20%',
      left: '50%',
      '& svg': {
        transform: 'rotate(45deg)',
      },
    },
    budgetButton1: {
      top: '40%',
      left: '20%',
      '& svg': {
        transform: 'rotate(90deg) scale(-1, -1)',
      },
    },
    budgetButton3: {
      top: '60%',
      left: '70%',
      '& svg': {
        transform: 'rotate(120deg) scale(-1, 1)',
      },
    },

    // STEP 4
    step4: {},
    monthSelector: {},

    // STEP 5
    step5: {
      paddingTop: 40,
      height: 'auto!important',
      textAlign: 'center',
      '& $buttonRestart': {
        display: 'none',
      },
    },
    form: {
      extend: theme.smallWrapper,
      maxWidth: 1000,
      margin: [0, 'auto'],
      textAlign: 'left',
      '& form': {
        '& > div:first-child': {
          width: '100%',
        },
      },
    },

    // RESTART
    buttonRestart: {
      position: 'fixed',
      zIndex: 10,
      top: 49,
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      cursor: 'pointer',
      '& svg': {
        width: 35,
        height: 35,
      },
      '& span': {
        marginTop: 1,
        fontSize: 9,
        color: theme.colors[1],
        textTransform: 'uppercase',
        display: 'block',
      },
    },
  }

  /*------------------------------
  Max Width < 1410
  ------------------------------*/
  output['@media(max-width: 1410px)'] = {
    title: {
      fontSize: 130,
    },

    // STEP 0
    start: {
      top: 230,
      left: 320,
    },
    startButton: {
      width: 250,
      height: 250,
    },

    // STEP 2
    chooseType: {
      paddingTop: 110,
    },
    orType: {
      top: 222,
      left: 392,
    },
    buttonTypeB: {
      marginTop: -51,
      marginLeft: 136,
    },
  }

  /*------------------------------
  Max height 700 && Max width 992px
  Max width 1240
  ------------------------------*/
  output['@media (min-width: 992px) and (max-height: 700px), (max-width: 1200px)'] = {
    root: {
    },
    title: {
      fontSize: 80,
    },
    text: {
      fontSize: 16,
    },

    left: {
      flex: '0 0 430px',
      paddingLeft: 0,
    },

    // STEP 0
    start: {
      top: 120,
      left: 180,
    },
    startButton: {
      width: 200,
      height: 200,
      fontSize: 18,
      lineHeight: 1,
    },

    // STEP 1
    whoSelector: {
      transform: 'scale(.8)',
    },
    step1: {
      '& $orType': {
        left: 137,
      },
    },

    // STEP 2
    chooseType: {
      paddingTop: 69,
    },
    orType: {
      top: 134,
      left: 241,
    },
    buttonTypeB: {
      marginTop: -33,
      marginLeft: 84,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
    },
    left: {
      flex: '0 0 330px',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
    },

    page: {
      display: 'block',
      height: 'auto',
      paddingTop: (props) => props.headerHeight - 30,
      paddingBottom: (props) => props.headerHeight - 30,
    },
    left: {
      flex: '1 0 100%',
      paddingLeft: 0,
    },
    right: {
      flex: '1 0 100%',
    },
    title: {
      fontSize: 75,
      fontWeight: 'normal',
      margin: 0,
    },
    text: {
      fontSize: 15,
    },
    step: {
      display: 'block',
      paddingLeft: 25,
      paddingRight: 25,
    },

    // STEP INDICATOR
    stepIndicator: {
      paddingLeft: 25,
      paddingRight: 25,
      paddingBottom: 40,
    },
    stepIndicatorButton: {
      display: 'none',
    },
    buttonPrevStep: {
      display: 'flex',
    },

    // STEP 0
    start: {
      top: 120,
      left: 120,
    },
    startButton: {
      width: 220,
      height: 220,
    },
    step0: {
      '& $text': {
        marginTop: 120,
      },
    },

    // STEP 1
    whoSelector: {
      marginTop: 40,
      transform: 'scale(.8)',
    },
    step1: {
      '& $orType': {
        left: 130,
        top: 80,
      },
    },
    // STEP 2
    chooseType: {
      paddingTop: 20,
    },
    orType: {
      left: 225,
      top: 80,
    },
    buttonTypeB: {
      marginTop: -30,
      marginLeft: 0,
    },

    // STEP 3
    budgets: {
      maxWidth: 250,
      paddingTop: 10,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    budgetButton: {
      position: 'relative',
      width: 100,
      height: 100,
      fontSize: 30,
      left: 'auto',
      top: 'auto',
      marginRight: 10,
      marginTop: 20,
      whiteSpace: 'nowrap',
      '& svg': {
        top: '-5%',
        left: '-5%',
        width: '110%',
        height: '110%',
      },
    },

    monthSelector: {
      paddingTop: 30,
      paddingRight: 15,
    },

    // STEP 5
    step5: {
      '& $buttonRestart': {
        position: 'relative',
        display: 'block',
        top: 'auto',
        left: 'auto',
        transform: 'none',
        margin: [40, 'auto', 10],
      },
    },

    // RESTART
    buttonRestart: {
      top: 18,
      display: 'none',
    },

    // FORM
    form: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }

  return output
}

export default style
