import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useLocation, useHistory } from 'react-router-dom'
import CreateJs from 'preload-js'
import gsap from 'gsap'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import PageElements from '@/components/PageElements'
import { convertDate } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

/*------------------------------
Hero
------------------------------*/
const Hero = ({
  title, date, image, ready, color,
}) => {
  const { headerHeight, isMenuOpen } = useContext(GeneralContext)
  const classes = useStyles({ color, headerHeight })
  const history = useHistory()

  return (
    <div className={classes.hero}>
      <div className={classes.wrapper}>
        <button
          className={classNames({
            [classes.back]: true,
            [classes.hide]: isMenuOpen,
          })}
          onClick={() => history.goBack()}
          aria-label="go-back"
        >
          <svg><use xlinkHref="#ico-arrow-left" /></svg>
        </button>
        <div className={classes.heroHeader}>
          <RevealText
            tag="h1"
            className={`${classes.heroTitle}`}
            type="lines"
            lineHeight={0.85}
            value={title}
            visible={ready}
          />
          <RevealText
            tag="div"
            className={`${classes.heroDate}`}
            type="lines"
            value={date}
            visible={ready}
          />
        </div>
        <RevealFadeIn
          className={classes.heroImage}
          visible={ready}
          delay={0.5}
        >
          <img src={image} alt={title} />
        </RevealFadeIn>
      </div>
    </div>
  )
}

const SimplePage = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const $root = useRef()
  const location = useLocation()
  const color = useRef()
  const stub = useRef(getSlug(location.pathname))

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, currentForm } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    preload.current.loadFile({ id: 'featured_image', src: page.featured_image })
  }

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      preloadMedia()
      color.current = page.acf?.post_color
      gsap.to('body', {
        background: `linear-gradient(to bottom, ${color.current}, ${color.current})`,
      })
    }
  }, [currentForm, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Elements
  ------------------------------*/
  const renderElements = () => {
    return page.acf.page_builder && (
      <PageElements
        className={classes.pageElements}
        elements={page.acf.page_builder}
      />
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          <Hero
            title={page.title.rendered}
            image={page.featured_image}
            date={convertDate(page.date)}
            ready={load}
          />
          {renderElements()}
        </div>
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageSimplePage ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default SimplePage
