import { useEffect, useRef } from 'react'
import { useTheme } from 'react-jss'
import { Loader } from 'resource-loader'

const useHandleAllMediaWithCb = ({
  ref,
  init,
}) => {
  const loader = useRef(new Loader())
  const counter = useRef(0)
  const theme = useTheme()

  /*------------------------------
  Callback fired when all images are loaded
  ------------------------------*/
  const onCompleteLoadImages = () => {
    if (!theme.detect.isIE11) window.dispatchEvent(new Event('resize'))
  }

  useEffect(() => {
    if (
      init
      && counter.current === 0
      && ref
    ) {
      counter.current += 1
      const imgs = Array.prototype.slice.call(ref.getElementsByTagName('img'))
        .map((img) => img.src)
        .filter((el) => el !== '')
      const uniqImgs = [...new Set(imgs)]
      loader.current
        .add(uniqImgs)
        .load()
        .onComplete.add(() => onCompleteLoadImages())
    }
  }, [init])
}

export default useHandleAllMediaWithCb
