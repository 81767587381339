import { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useTexture } from '@react-three/drei'
import { MathUtils } from 'three'
import constants from '@/constants'
import zustand from '@/base/zustand'

/*------------------------------
Logo
------------------------------*/
const Logo = () => {
  const { viewport } = useThree()
  const $logo = useRef()
  const { nodes } = useGLTF(`${constants.MODELS_URL}logo-magento-2.glb`)
  const texture = useTexture(`${constants.MODELS_URL}logo-magento.jpg`)

  useFrame(({ clock }) => {
    $logo.current.rotation.x = Math.sin(clock.getElapsedTime() / (2 * Math.PI)) * 0.1
    $logo.current.rotation.y = clock.getElapsedTime()
    $logo.current.position.y = MathUtils.mapLinear(zustand.manifest.progress.logo, 0, 1, -viewport.height, viewport.height)
  })

  return (
    <mesh
      ref={$logo}
      geometry={(nodes.magento).geometry}
      position={[0, -viewport.height, 1]}
      scale={[2, 2, 2]}
    >
      <meshStandardMaterial roughness={0.2} color="white" map={texture} />
    </mesh>
  )
}

export default Logo
