const global = (theme) => {
  const output = {
    root: {},
    '@global': {
      body: {
        extend: theme.typography.general,
        color: theme.colors[0],
        background: 'linear-gradient(#987fdd, #664dff)',
        // overflowY: theme.detect.isNativeScroll ? 'visible' : 'hidden',
        overflow: 'hidden',
        height: 'var(--vh)',
        maxHeight: '100vh',
        // '& #leva__root': {
        //   display: 'none',
        // },
        '& a': {
          textDecoration: 'none',
          transition: 'color .3s ease-in-out',
          color: 'inherit',
        },
        '& button': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& input': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
      },
      '.smooth-scroll': {
        height: 'var(--vh)',
        overflowX: 'hidden',
        overflowY: 'hidden',
      },
      '.has-scroll-init .smooth-scroll': {
        overflowY: 'auto',
      },
      '.has-scroll-smooth .smooth-scroll': {
        height: 'auto',
        overflowY: 'hidden',
      },
      '::selection': {
        background: theme.colors[10],
        color: theme.colors[1],
      },
      '::-moz-selection': {
        background: theme.colors[10],
        color: theme.colors[1],
      },

      /*------------------------------
      COOKIEBOT
      ------------------------------*/
      '#cookiebanner': {
        position: 'fixed',
        zIndex: '10',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        margin: '0 auto',
        background: 'rgba(0, 0, 0, 0.3)',
        color: 'rgba(255, 255, 255, .8)',
        fontFamily: '"TWKEverett", sans-serif',
        fontSize: '14px',
        padding: '15px 15px 15px 20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '30px 30px 0 0',
        '& .c-message': {
          marginBottom: '0',
        },
        '& .c-button': {
          fontFamily: '"TWKEverett", sans-serif',
          background: '#fff',
          color: '#232323',
          fontSize: '11px',
          borderRadius: '20px',
          padding: '12px 20px',
          textTransform: 'uppercase',
          cursor: 'pointer',
          textDecoration: 'none',
          marginLeft: '20px',
          fontWeight: 'bold',
          display: 'inline-block',
        },
      },
    },
  }

  return output
}

export default global
