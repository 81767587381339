const style = (theme) => {
  const output = {
    root: {
      marginBottom: 90,
    },
    button: {
      color: `${theme.colors[0]}!important`,
      extend: theme.button,
      marginRight: 15,
      marginBottom: 20,
    },
    buttonBorder: {
      background: 'none',
      color: `${theme.colors[1]}!important`,
      border: `1px solid ${theme.colors[1]}`,
      '& svg': {
        fill: theme.colors[1],
      },
      '&:hover': {
        '& svg': {
          transform: 'translate(1px, -1px)',
        },
      },
    },
    wrapper: {
      display: 'flex',
      maxWidth: 1920,
    },
    left: {
      flex: '0 0 50%',
      paddingRight: 40,
      '& img': {
        borderRadius: 15,
        overflow: 'hidden',
      },
    },
    right: {
      flex: '0 0 50%',
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    leftInverse: {
      order: 2,
      paddingLeft: 40,
      paddingRight: 0,
    },
    rightInverse: {
      order: 1,
    },
    title: {
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: 80,
      lineHeight: 0.85,
      fontWeight: 'normal',
      marginBottom: -10,
    },
    subTitle: {
      fontSize: 21,
      marginBottom: 20,
      margin: 0,
    },
    text: {
      color: theme.getRgba(theme.colors[1], 0.4),
      fontSize: 18,
      marginBottom: 50,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    wrapper: {
      maxWidth: 'none',
    },
    title: {
      fontSize: 60,
    },
    text: {
      fontSize: 16,
    },
    left: {
      paddingRight: 20,
      '& img': {
        borderRadius: 10,
      },
    },
    right: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    leftInverse: {
      paddingLeft: 20,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      marginBottom: 50,
    },
    wrapper: {
      display: 'block',
    },
    title: {
      fontSize: 40,
      marginTop: 20,
      marginBottom: 0,
    },
    subTitle: {
      fontSize: 18,
      marginBottom: 10,
    },
    text: {
      fontSize: 15,
      marginBottom: 20,
    },
    right: {
      paddingLeft: 0,
    },
    leftInverse: {
      paddingLeft: 0,
    },
  }

  return output
}

export default style
