import { Suspense, memo } from 'react'
import { createUseStyles } from 'react-jss'
import { Canvas } from '@react-three/fiber'
import Background from '@/components/Three/Background'
import Model from '@/components/Three/Model'
import PostProcessing from '@/components/Three/PostProcessing'
import style from './style'

const useStyles = createUseStyles(style)

const CanvasHome = () => {
  const classes = useStyles()

  return (
    <Canvas
      id="#canvas-home"
      className={classes.root}
      pixelRatio={1}
      gl={{ antialias: false }}
      camera={{ position: [0, 0, 15], fov: 40 }}
    >
      <Suspense fallback={null}>
        <Background />
        <Model />
        <PostProcessing />
      </Suspense>
    </Canvas>
  )
}

export default memo(CanvasHome)
