import { useEffect, useRef, useLayoutEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import HTMLParse from 'react-html-parser'
import { useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import MagneticButton from '@/components/MagneticButton'
import RevealText from '@/components/RevealText'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const ModalBio = () => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { teamList, pathname, location } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    location: state.router.location,
    teamList: state.content.cpt._cty_team || [], // eslint-disable-line
  }), shallowEqual)
  const history = useHistory()
  const classes = useStyles()
  const [people, setPeople] = useState(null)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const $root = useRef()
  const $close = useRef()
  const $overlay = useRef()
  const $wrapper = useRef()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (teamList?.length) setReady(true)
  }, [teamList])

  useLayoutEffect(() => {
    if (!ready) return
    const { partner } = location.query
    const teamPartner = teamList.find((team) => team.slug === partner)
    setPeople(teamPartner)
  }, [ready, location])

  // /*------------------------------
  // Render People
  // ------------------------------*/
  const renderPeople = () => {
    return people && (
      <div className={classes.container}>
        <RevealText
          className={classes.title}
          value={`${people.acf.name}<br>${people.acf.surname}`}
          lineHeight={0.8}
          tag="h2"
          type="lines"
          visible={true}
        />
        <div className={classes.wrapper} ref={$wrapper}>
          <div className={classes.text}>
            {HTMLParse(people.acf.bio)}
          </div>
          {people.acf.images?.length && (
            <div className={classes.images}>
              {people.acf.images.map((img, ind) => (
                <img
                  key={ind.toString()}
                  src={img.image_file?.url}
                  alt={img.image_name}
                />
              ))}
            </div>
          )}
          {people.acf.certifications?.length && (
            <div className={classes.certifications}>
              {people.acf.certifications.map((cert, ind) => (
                <a
                  key={ind.toString()}
                  className={classes.certificationButton}
                  href={cert.certification_file?.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {cert.certification_name}
                  <svg><use xlinkHref="#ico-download" /></svg>
                </a>
              ))}
            </div>
          )}

          <div className={classes.linkedin}>
            <a href={people.acf.linkedin} target="_blank" rel="noopener noreferrer">
              <svg>
                <title>Linkedin</title>
                <use xlinkHref="#ico-linkedin" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    )
  }

  return ready && (
    <>
      <div
        className={classNames({
          [classes.root]: true,
          isVisible: people,
        })}
        ref={$root}
        role="dialog"
        aria-modal="true"
      >
        <MagneticButton
          speed={0.5}
          className={classNames({
            [classes.magnetic]: true,
            [classes.close]: true,
            isVisible: people,
          })}
        >
          <button
            ref={$close}
            onClick={() => {
              history.push(pathname)
            }}
          >
            <MagneticButton
              speed={1}
              tollerance={0.3}
              className={classNames({
                [classes.magnetic]: true,
              })}
            >
              <svg><use xlinkHref="#ico-close" /></svg>
            </MagneticButton>
          </button>

        </MagneticButton>
        <div className={classes.wrapper}>
          {renderPeople()}
        </div>
      </div>
      <button
        ref={$overlay}
        className={classNames({
          [classes.overlay]: true,
          isVisible: people,
        })}
        onClick={() => {
          history.push(pathname)
        }}
      >
        close
      </button>
    </>
  )
}

export default ModalBio
