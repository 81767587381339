const style = (theme) => {
  const output = {
    root: {
      fontFamily: theme.fonts[1],
      fontSize: '10vw',
      color: '#ef97ff',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
    },
    text: {
      display: 'inline-block',
      '& span': {
        display: 'inline-block',
        paddingRight: '2rem',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      fontSize: '20vw',
    },
  }

  return output
}

export default style
