import { useEffect, useRef, useState, useContext } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import style from './style'

const useStyles = createUseStyles(style)

const Point = ({
  className,
  index,
  text,
}) => {
  const classes = useStyles()
  const $root = useRef()
  const $svg = useRef()
  const trigger = useRef()
  const [inview, setInview] = useState(false)
  const { scrollElement } = useContext(ScrollbarContext)

  useEffect(() => {
    if (scrollElement) {
      trigger.current = ScrollTrigger.create({
        trigger: $root.current,
        scroller: scrollElement,
        start: 'top 100%',
        once: false,
        onToggle: (e) => setInview(e.isActive),
      })
    }
    return () => {
      if (scrollElement) trigger.current.kill()
    }
  }, [scrollElement])

  return (
    <div
      className={classNames({
        [classes.point]: true,
        [className]: true,
        inview,
      })}
      ref={$root}
    >
      <svg viewBox="0 0 150 150" ref={$svg}>
        <path id="myTextPath" d="M 70,0 A 70,70 0 0 1 -70,0 A 70,70 0 0 1 70,0" transform="translate(75,75)" fill="none" stroke="none" />
        <text fill="rgba(255, 255, 255, 0.4)" letterSpacing="1">
          <textPath xlinkHref="#myTextPath">
            <tspan dy="5">
              {Array(120).fill(index + 1).map((i) => (i))}
            </tspan>
          </textPath>
        </text>
      </svg>
      <div className={classes.text}>
        <div className={classes.pointNum}>{index + 1}</div>
        {text}
      </div>
    </div>
  )
}

export default Point
