import { mainCacheAxios as axios } from '@/base/axios'
import { REQUEST_NAV, RECEIVE_NAV } from '@/actions/types'
import { API, LANG } from '@/constants'

const requestNav = () => ({ type: REQUEST_NAV })

const receiveNav = (data, name) => ({
  type: RECEIVE_NAV,
  payload: data,
  key: name,
})

const fetchMenu = (name) => async (dispatch) => {
  dispatch(requestNav())
  const response = await axios.get(`${API.MENU}/${name}?lang=${LANG}`)
  dispatch(receiveNav(response.data, name))
}

export {
  fetchMenu,
}
