import { useMemo } from 'react'
import { Color, MathUtils } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import zustand from '@/base/zustand'

/*------------------------------
Bubble
------------------------------*/
const Bubble = ({ manifestSection }) => {
  const { viewport } = useThree()
  const scale = useMemo(() => ((40 + 15) / viewport.distance), [viewport])

  const shaderArgs = useMemo(() => {
    return ({
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uAspect: { value: { x: viewport.width, y: viewport.height } },
        uSpeed: { value: 0 },
        uRadius: { value: zustand.manifest.sections[manifestSection].progress },
        uMinRadius: { value: 0 },
        uColor1: { value: new Color(zustand.manifest.sections[manifestSection].color) },
      },
      vertexShader: /* glsl */`
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `,
      fragmentShader: /* glsl */`
      uniform float uTime;
      uniform vec2 uAspect;
      uniform vec3 uColor1;
      uniform float uRadius;
      uniform float uMinRadius;
      uniform float uSpeed;
      varying vec2 vUv;

      vec2 rotate(vec2 v, float a) {
        float s = sin(a);
        float c = cos(a);
        mat2 m = mat2(c, -s, s, c);
        return m * v;
      }

      void main() {
        float aspect = max(uAspect.x / uAspect.y, uAspect.y / uAspect.x);
        vec2 uv = vUv;

        vec2 uvMask = vUv;

        uvMask -= .5;
        if (uAspect.x / uAspect.y > 1.) {
          uvMask.x *= aspect;
        } else {
          uvMask.y *= aspect;
        }
        float speed = clamp(uSpeed, 0., 0.05);

        uvMask.x += sin(20. * uv.y + uTime * 2.) * (0.005 + speed);
        uvMask.y -= cos(20. * uv.x + uTime * .5) * (0.005 + speed);


        float c = length(uvMask) * min(uAspect.x / uAspect.y, uAspect.y / uAspect.x) * 1.;
        float a = smoothstep(uRadius - 0.01, uRadius - 0.01 - 0.002, c);
        c = smoothstep(uRadius, uRadius - 0.002, c);

        vec3 finalColor = vec3(c) * uColor1;

        gl_FragColor = vec4(finalColor, a);
      }
    `,
    })
  }, [viewport])

  useFrame(({ clock }) => {
    shaderArgs.uniforms.uTime.value = clock.getElapsedTime()
    shaderArgs.uniforms.uRadius.value = MathUtils.lerp(shaderArgs.uniforms.uRadius.value, zustand.manifest.sections[manifestSection].progress, 0.1)
    shaderArgs.uniforms.uSpeed.value = MathUtils.lerp(shaderArgs.uniforms.uSpeed.value, zustand.general.speedScroll, 0.02)
  })

  return (
    <>
      <mesh position={[0, 0, -40]}>
        <planeBufferGeometry attach="geometry" args={[viewport.width * scale, viewport.height * scale, 1, 1]} />
        <shaderMaterial args={[shaderArgs]} />
      </mesh>
    </>
  )
}

export default Bubble
