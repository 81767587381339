import { RECEIVE_NAV } from '@/actions/types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NAV:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
