const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
    },
    page: {
    },
    wrapper: {
      extend: theme.wrapper,
    },
    canvas: {
      opacity: 0,
    },
    sectionWrapFixed: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
      extend: theme.wrapper,
      height: 'var(--vh)',
      pointerEvents: 'none',
      display: 'flex',
    },
    sectionItem: {
      position: 'absolute',
      padding: [0, '5%'],
      top: '50%',
      width: '100%',
      left: 0,
      transform: 'translateY(-50%)',
      '& h1, & h2': {
        color: theme.colors[1],
        fontFamily: theme.fonts[1],
        textTransform: 'uppercase',
        fontSize: '5vw',
        lineHeight: 0.85,
        fontWeight: 'normal',
        margin: 0,
        '& .revealText--wrap': {
          '& > .line:nth-child(1)': {
            paddingLeft: '0.5em',
          },
          '& > .line:nth-child(3)': {
            paddingLeft: '1em',
          },
          '& > .line:nth-child(4)': {
            paddingLeft: '.75em',
          },
        },
      },
      '&:nth-child(2n+2)': {
        textAlign: 'right',
        '& h1, & h2': {
          '& .revealText--wrap': {
            '& > .line:nth-child(1)': {
              paddingRight: '0.5em',
            },
            '& > .line:nth-child(3)': {
              paddingRight: '1em',
            },
          },
        },
      },
    },
    sectionWrapScroll: {
      '& $section:first-child': {
        height: 'var(--vh)',
      },
      '& $section:last-child': {
        height: 0,
        // marginBottom: '50vh',
      },
    },
    section: {
      height: '250vh',
      marginBottom: '250vh',
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'flex-start',
      padding: [0, '5%'],
      background: window.location.search.indexOf('debug') !== -1 ? 'linear-gradient(red, transparent, green)' : '',
      '& [data-scroll]': {
        paddingTop: '35vh',
      },
      '& h1, & h2': {
        fontSize: '5vw',
        lineHeight: 0.85,
        fontWeight: 'normal',
        margin: 0,
        '& .revealText--wrap': {
          '& > .line:nth-child(1)': {
            paddingLeft: '0.5em',
          },
          '& > .line:nth-child(3)': {
            paddingLeft: '1em',
          },
          '& > .line:nth-child(4)': {
            paddingLeft: '.75em',
          },
        },
      },
      '&:nth-child(2n + 2)': {
        justifyContent: 'flex-end',
        '& h1, & h2': {
          '& .revealText--wrap': {
            '& > .line:nth-child(3)': {
              paddingLeft: '0.5em',
            },
            '& > .line:nth-child(4)': {
              paddingLeft: '1em',
            },
          },
        },
      },
    },
    links: {
      height: 'var(--vh)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    link: {
      fontSize: '14vw',
      border: 'none',
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      cursor: 'pointer',
      overflow: 'hidden',
      padding: [0, 40],
      lineHeight: 0.85,
      '& a': {
        transition: 'color .3s ease-out',
        color: theme.getRgba(theme.colors[1], 0.3),
      },
      '&:nth-child(1)': {
        transform: 'translateY(-100%)',
        marginLeft: -40,
      },
      '&:nth-child(2)': {
        transform: 'translateY(100%)',
        marginRight: -40,
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& a': {
            color: theme.colors[1],
          },
        },
      },
    },
  }

  /*------------------------------
  Max Height 600
  ------------------------------*/
  output['@media (max-height: 600px)'] = {
    link: {
      fontSize: '8vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    section: {
      height: '150vh',
      marginBottom: '150vh',
    },
    sectionItem: {
      '& h1, & h2': {
        fontSize: 55,
      },
    },
    links: {
      height: 'var(--vh)',
      display: 'block',
      position: 'relative',
    },
    link: {
      fontSize: 80,
      padding: [0, 40],
      lineHeight: 0.85,
      whiteSpace: 'nowrap',
      position: 'absolute',
      left: 10,
      top: '50%',
      '& a': {
        color: theme.getRgba(theme.colors[1], 0.8),
      },
      '&:nth-child(2)': {
        left: 'auto',
        right: 10,
      },
    },
  }

  return output
}

export default style
