import { memo } from 'react'
import { createUseStyles } from 'react-jss'
import { Canvas } from '@react-three/fiber'
import Rays from './rays'
import Confetti from './confetti'
import style from './style'

const useStyles = createUseStyles(style)

const CanvasQuote = ({ canvasEffect, callback }) => {
  const classes = useStyles()
  return (
    <Canvas
      id="#canvas-quote"
      className={classes.root}
      pixelRatio={1}
      gl={{ antialias: false }}
      camera={{ position: [0, 0, 15], fov: 40 }}
    >
      <directionalLight position={[3, 10, 20]} />
      <ambientLight intensity={0.3} />
      {canvasEffect === 'confetti' && <Confetti callback={callback} />}
      {canvasEffect === 'rays' && <Rays callback={callback} />}
    </Canvas>
  )
}

export default memo(CanvasQuote)
