import { useRef, useEffect, useState, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { Context as AudioContext } from '@/context/audio'
import { useHistory } from 'react-router-dom'
import zustand from '@/base/zustand'
import MagneticButton from '@/components/MagneticButton'
import StrokeButton from '@/components/StrokeButton'
import style from './style'

const useStyles = createUseStyles(style)

const Section5 = ({ className, speed = 2, manifestSection, id, people, progressName }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const { setPlayHoverSound } = useContext(AudioContext)
  const $root = useRef()
  const $title1 = useRef()
  const $title2 = useRef()
  const $anchor = useRef()
  const $box = useRef()
  const $button = useRef()
  const classes = useStyles()
  const [lines, setLines] = useState(false)
  const theme = useTheme()
  const pause = {
    x: 0,
  }
  const tl = useRef()
  const history = useHistory()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections[manifestSection], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById(`section-${id}`)) {
        ScrollTrigger.getById(`section-${id}`).kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById(`section-${id}`)) {
        ScrollTrigger.getById(`section-${id}`).kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: `section-${id}`,
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
          onUpdate: (self) => setLines(self.progress > 0.2),
        },
      })
        .addLabel('start')
        .to(zustand.manifest.sections[manifestSection], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start')
        .fromTo($title1.current, {
          x: '-200%',
          opacity: 0,
        }, {
          x: '0%',
          opacity: 1,
          duration: 2,
        }, 'start')
        .fromTo($title2.current, {
          x: '200%',
          opacity: 0,
        }, {
          x: '0%',
          opacity: 1,
          duration: 2,
        }, 'start')
        .to(zustand.manifest.progress, {
          [progressName]: 0.5,
          duration: 2,
        }, 'start')
        .fromTo($box.current, {
          opacity: 0,
        }, {
          opacity: 1,
        })
        .fromTo($button.current, {
          scale: 0,
          opacity: 0,
        }, {
          scale: 1,
          opacity: 1,
          clearProps: 'opacity, transform',
        }, '<')
        .to(pause, {
          x: 1,
          duration: 2,
        })
        .addLabel('exit')
        .to(zustand.manifest.progress, {
          [progressName]: 1,
          duration: 2,
          ease: 'power1.in',
        }, 'exit')
        .to($title1.current, {
          x: '200%',
          duration: 2,
          opacity: 0,
          ease: 'power1.in',
        }, 'exit')
        .to($title2.current, {
          duration: 2,
          x: '-200%',
          opacity: 0,
          ease: 'power1.in',
        }, 'exit')
        .to($box.current, {
          opacity: 0,
        }, 'exit')
        .to($button.current, {
          scale: 0,
          opacity: 0,
        }, 'exit')
        .to($anchor.current, {
          top: '100%',
          duration: 0,
        }, 'exit+=3')

      if (theme.detect.debug) {
        window.console.log('section 5 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <h2
          className={classNames({
            [classes.title]: true,
            [classes.title1]: true,
          })}
          ref={$title1}
        >
          {people.acf.name}
        </h2>
        <h2
          className={classNames({
            [classes.title]: true,
            [classes.title2]: true,
          })}
          ref={$title2}
        >
          {people.acf.surname}
        </h2>
        <div ref={$box} className={classes.box}>
          <StrokeButton isHover={lines} offset={18} amplitude={10} className={classes.text}>{people.acf.role}</StrokeButton>
        </div>
        <MagneticButton
          className={classes.magnetic}
          speed={0.5}
        >
          <button
            ref={$button}
            className={classes.button}
            onClick={() => {
              history.push(`?partner=${id}`)
            }}
            onMouseEnter={() => { setPlayHoverSound(true) }}
          >
            <MagneticButton
              speed={1}
              tollerance={0.3}
              className={classNames({
                [classes.insideMagnetic]: true,
              })}
            >
              Read
              <br />
              Profile
            </MagneticButton>
          </button>
        </MagneticButton>
        <div
          ref={$anchor}
          className={classes.anchor}
          id={id}
        />
      </div>
    </section>
  )
}
export default Section5
