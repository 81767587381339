const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
      '& a': {
        color: theme.colors[1],
      },
    },
    overlay: {
      background: 'linear-gradient(#6983cd, #9c4efe)',
      position: 'fixed',
      zIndex: 10,
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      pointerEvents: 'none',
      transform: 'translateY(100%)',
      fontSize: '10vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        display: 'inline-block',
        transform: 'rotate(-45deg)',
      },
    },
    page: {
      paddingTop: (props) => props.headerHeight + 30,
    },
    wrapper: {
      extend: theme.wrapper,
      margin: [0, 'auto'],
      maxWidth: 1920,
      paddingLeft: 'calc(4.2vw + 90px)',
      paddingRight: 'calc(4.2vw + 90px)',
    },
    title: {
      color: theme.colors[1],
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: '8vw',
      lineHeight: 0.85,
      fontWeight: 'normal',
      margin: 0,
    },
    subTitle: {
      fontSize: '6vw',
    },
    textWrapper: {
      maxWidth: 740,
      margin: [80, 'auto'],
      '& h2': {
        color: theme.colors[1],
        fontFamily: theme.fonts[1],
        textTransform: 'uppercase',
        fontSize: 50,
        lineHeight: 0.85,
        fontWeight: 'normal',
        margin: [0, 0, '.2em', 0],
      },
    },
    scrollIndicator: {},

    scrollingText: {},
    scrollingTextStroke: {
      width: '80%',
      margin: [-20, 'auto', 0],
      pointerEvents: 'none',
      display: 'block',
      fontSize: 0,
      height: 80,
    },

    // Points
    points: {
      '& $wrapper': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      marginBottom: 200,
    },
    point: {
      '&:nth-child(4n+1)': {
        transform: 'scale(.7)',
        marginLeft: '-40vw',
        marginBottom: -200,
        '& svg': {
          fontSize: 9,
        },
      },
      '&:nth-child(4n+2)': {
        marginLeft: '40vw',
        marginBottom: -100,
      },
      '&:nth-child(4n+3)': {
        transform: 'scale(.9)',
        marginLeft: '-20vw',
        marginBottom: -80,
      },
      '&:nth-child(4n+4)': {
        transform: 'scale(.95)',
        marginLeft: '20vw',
        marginBottom: -100,
      },
    },

    // Banners
    banners: {
      marginTop: 100,
      marginBottom: 100,
    },
    bannerTitle: {
      fontSize: '5.5vw',
      marginBottom: 50,
    },

    // Section
    section: {
      pointerEvents: 'none',
      '& a, & button': {
        pointerEvents: 'all',
      },
    },

    // Next Link
    nextLink: {
      position: 'relative',
      zIndex: 1,
      height: 'var(--vh)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    svgBall: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      width: '80vw',
      height: '80vh',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
    link: {
      '@media (hover: hover)': {
        '&:hover': {
          '& $nextLinkTitle': {
            color: theme.getRgba(theme.colors[1], 0.3),
          },
          '& $nextLinkButton': {
            opacity: 1,
          },
        },
      },
    },
    nextLinkTitle: {
      fontFamily: theme.fonts[1],
      fontSize: '20vw',
      textTransform: 'uppercase',
      color: theme.colors[1],
      transition: 'color .3s ease-out',

    },
    nextLinkButton: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity .3s ease-out',
      extend: theme.button,
    },
    toTop: {
      color: theme.colors[1],
      textTransform: 'uppercase',
      fontSize: 10,
      position: 'absolute',
      zIndex: 100,
      bottom: 57,
      cursor: 'pointer',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      pointerEvents: 'all',
      '& svg': {
        width: 30,
        height: 30,
        position: 'absolute',
        zIndex: 1,
        top: -38,
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        transformOrigin: 'center',
        transition: 'transform .3s ease-out',
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& svg': {
            transform: 'translate3d(-50%, 0, 0) rotate(40deg) scale(1.1)',
          },
        },
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    textWrapper: {
      extend: theme.wrapper,
      margin: [0, 'auto'],
      maxWidth: 1920,
      paddingLeft: 'calc(4.2vw + 90px)',
      paddingRight: 'calc(4.2vw + 90px)',
    },
    bannerTitle: {
      fontSize: '6.5vw',
      marginBottom: 30,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    page: {
      paddingTop: (props) => props.headerHeight,
    },
    heroTitle: {
      fontSize: 70,
    },
    scrollIndicator: {
      display: 'none',
    },
    wrapper: {
      paddingLeft: 'calc(4.2vw + 15px)',
      paddingRight: 'calc(4.2vw + 15px)',
    },
    textWrapper: {
      paddingLeft: 'calc(4.2vw + 15px)',
      paddingRight: 'calc(4.2vw + 15px)',
      fontSize: 15,
    },

    scrollingText: {
      marginBottom: 40,
    },

    // Points
    points: {
      marginTop: 100,
      marginBottom: 100,
      '& $wrapper': {
        display: 'block',
        textAlign: 'left',
      },
    },

    // Banners
    bannerTitle: {
      fontSize: 50,
      marginBottom: 30,
    },

    nextLinkTitle: {
      fontSize: '30vw',
    },
  }

  return output
}

export default style
