import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from 'react-jss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { Context } from '@/context'

const useScrollTrigger = (offset = 'top 85%') => {
  const { scrollElement } = useContext(ScrollbarContext)
  const { pageAnimationReady } = useContext(Context)
  const [triggerActive, setTriggerActive] = useState(false)
  const [animationReady, setAnimationReady] = useState(false)
  const internScrollbar = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    if (theme.detect.isIE11) setTriggerActive(true)
    if (scrollElement) {
      internScrollbar.current = scrollElement
      setTriggerActive(true)
    }
  }, [scrollElement])

  useEffect(() => {
    if (triggerActive && pageAnimationReady) {
      ScrollTrigger.defaults({
        scroller: internScrollbar.current,
        start: offset,
        once: true,
      })
      setAnimationReady(true)
    }
  }, [triggerActive, pageAnimationReady])

  return { animationReady }
}

export default useScrollTrigger
