const style = (theme) => {
  const output = {
    overlay: {
      pointerEvents: 'none',
      backgroundColor: theme.getRgba(theme.colors[0], 0.6),
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100vw',
      height: 'var(--vh)',
      fontSize: 0,
      opacity: 0,
      zIndex: theme.zindex.overlay,
      transition: 'opacity .3s ease-out',
      '&.isVisible': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      paddingTop: 70,
      paddingLeft: 70,
      paddingBottom: 60,
      width: 570,
      borderRadius: [30, 0, 0, 30],
      height: 'var(--vh)',
      background: theme.colors[1],
      zIndex: theme.zindex.modal,
      transform: 'translateX(100%)',
      transition: `transform .5s ${theme.easings['power3.out']}`,
      '&.isVisible': {
        transform: 'translateX(0%)',
      },
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    wrapper: {
      flex: '1 1 auto',
      height: '100%',
      position: 'relative',
      paddingRight: 40,
      paddingBottom: 5,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      justifyContent: 'space-between',
      fontSize: 18,
      lineHeight: 1.4,
      color: theme.colors[12],
      '& ul': {
        margin: [20, 0],
        '& li': {
          position: 'relative',
          zIndex: 1,
          paddingLeft: 20,
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 10,
            left: 6,
            width: 4,
            height: 4,
            borderRadius: 4,
            background: theme.colors[0],
          },
        },
      },
    },
    title: {
      flex: '1 1 auto',
      marginBottom: 30,
      fontFamily: theme.fonts[1],
      fontSize: 64,
      lineHeight: 0.9,
      textTransform: 'uppercase',
    },
    button: {
      extend: theme.button,
    },
    extra: {
      position: 'fixed',
      left: '50%',
    },
    close: {
      padding: 10,
      position: 'fixed',
      zIndex: 10,
      right: 587,
      top: 127,
      opacity: 0,
      transition: 'opacity .3s ease-out',
      '&.isVisible': {
        opacity: 1,
      },
      '& button': {
        background: theme.colors[6],
        width: 90,
        height: 90,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
          width: 10,
          height: 10,
          fill: theme.colors[1],
          transition: 'transform .2s ease-out',
        },
      },
    },
    magnetic: {
      display: 'inline-block',
      padding: 10,
      '& $magnetic': {
        padding: 50,
      },
      '&:hover': {
        '& svg': {
          transform: 'scale(1.5) rotate(180deg)',
        },
      },

    },
    images: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: 20,
      '& img': {
        margin: [0, 20, 20, 0],
        maxWidth: 150,
        maxHeight: 150,
        width: '100%',
        height: 'auto',
      },
    },

    certifications: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
    },
    certificationButton: {
      position: 'relative',
      zIndex: 1,
      alignSelf: 'flex-start',
      background: theme.colors[12],
      color: theme.colors[1],
      padding: [10, 40, 10, 22],
      fontSize: 11,
      borderRadius: 20,
      marginBottom: 5,
      textTransform: 'uppercase',
      cursor: 'pointer',
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        fill: theme.colors[1],
        width: 22,
        height: 22,
        right: 14,
        top: 7,
      },
    },
    linkedin: {
      marginTop: 10,
      '& a': {
        width: 35,
        height: 35,
        borderRadius: 35,
        border: `1px solid ${theme.colors[12]}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& svg': {
        width: 11,
        height: 11,
        fill: theme.colors[12],
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 30,
      width: '100vw',
      borderRadius: 0,
    },
    wrapper: {
      paddingRight: 30,
      fontSize: 16,
      lineHeight: 1.3,
      '& ul': {
        '& li': {
          '&:before': {
            top: 8,
          },
        },
      },
    },
    close: {
      position: 'fixed',
      right: 20,
      top: 40,
      transform: 'none',
      '& button': {
        width: 40,
        height: 40,
      },
    },
  }

  return output
}

export default style
