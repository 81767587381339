import { useEffect, useState, useCallback } from 'react'
import injectSheet from 'react-jss'
import ReactHtmlParser from 'react-html-parser'
import DelayLink from '@/components/DelayLink'
import classNames from 'classnames'
import { cleanOrigin } from '@/utils/path'

import style from '../style'

const CheckboxField = ({
  classes,
  label,
  name,
  onBlur,
  required,
  type,
  value,
  error,
  touched,
  submitCount,
  setFieldValue,
}) => {
  const [transformedContent, setTransformedContent] = useState()

  const transformContent = useCallback(() => {
    const transform = (node, i) => { // eslint-disable-line
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <DelayLink
            key={i.toString()}
            to={cleanOrigin(node.attribs.href)}
          >
            {node.children[0].data}
          </DelayLink>
        )
      }
    }
    setTransformedContent(ReactHtmlParser(label, { transform }))
  }, [label])

  useEffect(() => {
    transformContent()
  }, [label])

  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlCheckbox]: true,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label
        htmlFor={name}
        className={classes.checkbox}
      >
        <input
          id={name}
          type={type}
          onChange={() => {
            setFieldValue(name, !value)
          }}
          onBlur={onBlur}
          checked={value || false}
        />
        <span className={classes.label}>
          {transformedContent}
          {' '}
          {required ? '*' : ''}
        </span>
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

export default injectSheet(style)(CheckboxField)
