import Home from '@/pages/Home'
import Manifesto from '@/pages/Manifesto'
import Post from '@/pages/Post'
import About from '@/pages/About'
import SimplePage from '@/pages/SimplePage'
import Quote from '@/pages/Quote'
import Contact from '@/pages/Contact'
import ArchiveNews from '@/pages/ArchiveNews'
import NotFound from '@/pages/NotFound'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'manifesto'),
    ),
    exact: true,
    render: () => <Manifesto />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post'),
    ),
    transition: false,
    render: () => <Post />,
  },
  {
    key: 3,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_news'),
    ),
    exact: true,
    render: () => <ArchiveNews />,
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'quote'),
    ),
    exact: true,
    render: () => <Quote />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'about'),
    ),
    render: () => <About />,
  },
  {
    key: 9,
    path: internazionalizePath(
      getPathFromCpt(routing, 'simple-page'),
    ),
    render: () => <SimplePage />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
