import { createContext, useState, useRef, useEffect } from 'react'
import useSound from 'use-sound'
import gsap from 'gsap'
import ambientAudio from '@/assets/audio/spielberg.mp3'
import clickAudio from '@/assets/audio/click.mp3'

const Context = createContext({})
const { Provider, Consumer } = Context

const AudioContext = ({
  children,
}) => {
  const [playAmbientAudio, {
    pause: pauseAmbientAudio,
    sound: soundAmbient,
  }] = useSound(ambientAudio, { loop: true })
  const [isAudioActive, setAudioActive] = useState(false)
  const [isAudioControlsVisible, setAudioControlsVisible] = useState(false)
  const [isFirstTimeAudio, setFirstTimeAudio] = useState(true)
  const [isAudioReady, setAudioReady] = useState(false)
  const volume = useRef({ progress: 0 })
  const [playHoverSound, setPlayHoverSound] = useState(false)
  const [playClickAudio, {
    sound: soundClick,
  }] = useSound(clickAudio)

  const play = () => {
    playAmbientAudio()
    gsap.killTweensOf(volume.current)
    gsap.to(volume.current, {
      progress: 1,
      duration: 2,
      ease: 'power1.easeOut',
      onUpdate: () => {
        soundAmbient.volume(volume.current.progress)
      },
    })
  }

  const pause = () => {
    gsap.killTweensOf(volume.current)
    gsap.to(volume.current, {
      progress: 0,
      duration: 1,
      ease: 'power1.easeOut',
      onUpdate: () => {
        soundAmbient.volume(volume.current.progress)
      },
      onComplete: pauseAmbientAudio,
    })
  }

  useEffect(() => {
    if (!soundAmbient) return
    if (!isAudioActive) {
      pause()
    } else {
      play()
    }
  }, [isAudioActive])

  const pauseOnBlur = () => {
    if (isAudioActive) {
      pause()
    }
  }

  const playOnFocus = () => {
    if (isAudioActive) {
      play()
    }
  }

  useEffect(() => {
    window.addEventListener('blur', pauseOnBlur)
    window.addEventListener('focus', playOnFocus)
    return () => {
      window.removeEventListener('blur', pauseOnBlur)
      window.removeEventListener('focus', playOnFocus)
    }
  }, [isAudioActive])

  useEffect(() => {
    if (soundAmbient) {
      soundAmbient.once('load', () => {
        setAudioReady(true)
      })
    }
  }, [soundAmbient])

  useEffect(() => {
    if (playHoverSound) {
      soundClick.volume(0.5)
      soundClick.rate(0.5 + Math.random())
      playClickAudio()
      setPlayHoverSound(false)
    }
  }, [playHoverSound])

  return (
    <Provider
      value={{
        isAudioActive,
        setAudioActive,
        isAudioControlsVisible,
        setAudioControlsVisible,
        isFirstTimeAudio,
        setFirstTimeAudio,
        isAudioReady,
        playHoverSound,
        setPlayHoverSound,
      }}
    >
      {children}
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default AudioContext
