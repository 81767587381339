import { theme } from '@/style'

const zustand = {

  general: {
    scrollY: 0,
    speedScroll: 0,
    mouse: {
      x: 0,
      y: 0,
    },
  },
  // setGeneral: ({ key: val }) => set(state => ({ general: {...state.general, key: val })),

  home: {
    col1: theme.colors[8],
    col2: theme.colors[9],
    modelHover: '',
  },

  manifest: {
    sections: {
      1: {
        color: '#c67bc7', // Rosa - Intro
        progress: 0,
      },
      8: {
        color: '#d95a25', // Arancione - Magento
        progress: 0,
      },
      9: {
        color: '#c34916', // Arancione - Icone
        progress: 0,
      },
      5: {
        color: '#707be4', // Azzurro - Danilo
        progress: 0,
      },
      6: {
        color: '#7559e2', // Viola - Riccardo
        progress: 0,
      },
      7: {
        color: '#32274f', // Super Viola - We are ghost unicorns
        progress: 0,
      },
      4: {
        color: '#707be4', // Azzurro - This is the true essence of perfection
        progress: 0,
      },
      2: {
        color: '#c67bc7', // Rosa - We are the ones who create
        progress: 0,
      },
      3: {
        color: '#7559e2', // Viola - We leave no marks
        progress: 0,
      },
      10: {
        color: '#2e2a45', // Viola Scuro - Sheets
        progress: 0,
      },
    },

    progress: {
      logo: 0,
      danilo: 0,
      riccardo: 0,
    },
  },

}

export default zustand
