const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 12,
        height: 10,
        marginRight: 10,
        '& *': {
          transition: `all 0.3s ${theme.easings['power4.out']}`,
          fill: 'none',
          stroke: theme.colors[1],
          strokeWidth: 1,
        },
        '& line': {
          opacity: 0,
          transition: 'opacity .25s ease-in-out',
        },
      },
      '& div': {
        position: 'relative',
        zindex: 1,
        whiteSpace: 'nowrap',
        fontSize: 9,
        fontFamily: theme.fonts[0],
        textTransform: 'uppercase',
        color: theme.colors[1],
        cursor: 'pointer',
        paddingBottom: 1,
        top: 1,
        display: 'flex',
        justifyContent: 'space-between',
        '&:after': {
          content: '""',
          position: 'absolute',
          zindex: -10,
          left: -25,
          top: 0,
          width: '100%',
          height: '100%',
        },
        '&:before': {
          width: 'calc(100% - 6px)',
        },
        '& em': {
          display: 'inline-block',
          width: 26,
          marginLeft: '.2rem',
          fontStyle: 'normal',
          textAlign: 'left',
          overflow: 'hidden',
          height: 12,
        },
        '& span': {
          display: 'block',
          transition: 'transform .25s ease-in-out',
        },
      },
    },
    inverse: {
      '& svg': {
        '& path': {
          stroke: theme.colors[1],
        },
      },
    },
    active: {
      '& svg': {
        '& line': {
          opacity: 1,
        },
      },
      '& div': {
        '& span': {
          transform: 'translateY(-100%)',
        },
      },
    },
    isHover: {
      '@media (hover: hover)': {
        '& svg': {
          '& path': {
            stroke: theme.colors[1],
          },
        },
        '&$inverse': {
          '& svg': {
            '& path': {
              stroke: theme.colors[3],
            },
          },
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
