const style = (theme) => {
  const output = {
    stroke: {
      fill: 'none',
      stroke: theme.colors[4],
      strokeWidth: 0.1,
      transformOrigin: 'center center',
      animation: '$rotate 10s linear infinite',
    },
    stroke2: {
      strokeWidth: 0.05,
      stroke: theme.colors[5],
      animationDuration: '12s',
      animationDirection: 'reverse',
    },
    stroke3: {
      strokeWidth: 0.15,
      stroke: theme.colors[6],
      animationDuration: '14s',
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
