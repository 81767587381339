import injectSheet from 'react-jss'
import classNames from 'classnames'

import style from '../style'

const FileField = ({
  classes,
  label,
  name,
  onBlur,
  required,
  type,
  value,
  error,
  touched,
  withLabel,
  submitCount,
  setFieldValue,
}) => {
  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlUpload]: true,
        [classes.formControlIsFill]: value?.name,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <input
        className={classes.input}
        type={type}
        id={name}
        onChange={(evt) => setFieldValue(name, evt.currentTarget.files[0])}
        onBlur={onBlur}
      />
      <label className={classes.fileUpload} htmlFor={name}>
        {withLabel && (
          <span className={classes.label}>
            {value?.name || `${label} ${required ? '*' : ''}`}
          </span>
        )}
      </label>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path d="M8 10H3l9-10 9 10h-5v10H8V10zm11 9v3H5v-3H3v5h18v-5h-2z" />
      </svg>
      {/* {error && touched ? <div>{error}</div> : null} */}
    </div>
  )
}

FileField.defaultProps = {
  withLabel: true,
}

export default injectSheet(style)(FileField)
