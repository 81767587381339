import { Color, AdditiveBlending, MathUtils } from 'three'
import { useRef, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useTexture } from '@react-three/drei'
import constants from '@/constants'

/*------------------------------
Light
------------------------------*/
const Light = ({ color, position, alpha = 1, scale, cut = 0 }) => {
  const mesh = useRef()
  const { randFloat } = MathUtils

  const shaderArgs = useMemo(() => ({
    blending: AdditiveBlending,
    transparent: true,
    uniforms: {
      uTime: { value: 0 },
      uColor: { value: new Color(color) },
      uAlpha: { value: alpha },
      uCut: { value: cut },
      uRandom: { value: { x: randFloat(0.4, 3), y: randFloat(0.4, 3), z: randFloat(0.4, 2) } },
    },
    vertexShader: /* glsl */`
      uniform float uTime;
      uniform vec3 uRandom;
      varying vec2 vUv;
      void main() {
        vUv = uv;
        vec3 pos = position;
        pos.x += cos(uTime * uRandom.x) * sin(uTime * uRandom.y) * uRandom.y * 4.;
        pos.y += sin(uTime * uRandom.y) * cos(uTime * uRandom.z) * uRandom.z * 4.;
        pos.z += sin(uTime * uRandom.z) * cos(uTime * uRandom.x) * uRandom.x * 4.;
        // pos.x += cos(time * uRandom.x) * sin(time * uRandom.y) * 20.;
        // pos.y += sin(time * uRandom.y) * cos(time * uRandom.z) * 20.;
        // pos.z += sin(time * uRandom.z) * cos(time * uRandom.x) * 4.;
        vec4 mvPosition = modelViewMatrix * vec4( pos, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
    fragmentShader: /* glsl */`
      varying vec2 vUv;
      uniform vec3 uColor;
      uniform float uCut;
      uniform float uAlpha;
      void main() {
        vec4 color;
        float c = length(vUv - .5);
        c = 1. - smoothstep(uCut, .5, c);

        color.rgb = uColor * c;
        color.a = uAlpha * c;

        gl_FragColor = color;
      }
    `,
  }), [])

  useFrame(({ clock }) => {
    mesh.current.material.uniforms.uTime.value = clock.getElapsedTime() * 0.1
  })

  return (
    <mesh ref={mesh} position={position} scale={scale} rotation={[0, -0.3 + Math.random() * 0.6, 0]}>
      <circleBufferGeometry attach="geometry" args={[randFloat(0.5, 1), 32]} />
      <shaderMaterial
        args={[shaderArgs]}
        attach="material"
        transparent={true}
        depthWrite={false}
      />
    </mesh>
  )
}

/*------------------------------
Particles
------------------------------*/
const Particles = ({ num, viewport }) => {
  const arr = new Array(num).fill()

  return arr.map((_, i) => {
    // Colors
    const r = 1
    const g = 0.5
    const b = 0.7

    const color = new Color(r, g, b)

    const x = MathUtils.randFloat(-viewport.width / 2, viewport.width / 2)
    const y = MathUtils.randFloat(-viewport.height / 2, viewport.height / 2)
    const z = MathUtils.randFloat(1, 2)

    const scale = 0.05 + Math.random() * 0.4
    const cut = Math.random() * 0.4
    const alpha = 0.02 + Math.random() * 0.05
    return (
      <Light key={i.toString()} color={color} position={[x, y, z]} scale={scale} alpha={alpha} cut={cut} />
    )
  })
}

/*------------------------------
Background
------------------------------*/
const Background = () => {
  const { viewport } = useThree()
  const scale = useMemo(() => ((40 + 15) / viewport.distance), [viewport])
  const [bgTexture] = useTexture([`${constants.IMAGE_URL}bg.jpg`])

  const shaderArgs = useMemo(() => ({
    uniforms: {
      uTime: { value: 0 },
      uTexture: { value: bgTexture },
      uResolution: { value: { x: viewport.width, y: viewport.height } },
      uImageRegsolution: { value: { x: bgTexture.image.width, y: bgTexture.image.height } },
    },
    vertexShader: /* glsl */`
      varying vec2 vUv;
      void main() {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
    fragmentShader: /* glsl */`
      uniform sampler2D uTexture;
      uniform float uTime;
      uniform vec2 uResolution;
      uniform vec2 uImageRegsolution;
      varying vec2 vUv;
      void main() {
        /*------------------------------
        BACKGROUND SIZE COVER 👌
        ------------------------------*/
        vec2 ratio = vec2(
          min((uResolution.x / uResolution.y) / (uImageRegsolution.x / uImageRegsolution.y), 1.0),
          min((uResolution.y / uResolution.x) / (uImageRegsolution.y / uImageRegsolution.x), 1.0)
        );
        vec2 uv = vec2(
          vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
          vUv.y * ratio.y + (1.0 - ratio.y) * 0.5
        );
        
        /*------------------------------
        MOVIMENTO BG 🌊
        ------------------------------*/
        // uv.y += sin(4. * uv.x + uTime) * .05;
        // uv.x += sin(3. * uv.y + uTime * .7) * .05;

        vec3 color = texture2D(uTexture, uv).rgb;
        gl_FragColor = vec4(color, 1.);
      }
    `,
  }), [viewport, bgTexture])

  useFrame(({ clock }) => {
    shaderArgs.uniforms.uTime.value = clock.getElapsedTime()
  })

  return (
    <>
      <mesh position={[0, 0, -40]}>
        <planeBufferGeometry attach="geometry" args={[viewport.width * scale, viewport.height * scale, 1, 1]} />
        <shaderMaterial args={[shaderArgs]} />
      </mesh>
      <Particles num={viewport.aspect > 1 ? 30 : 15} viewport={viewport} />
    </>
  )
}

export default Background
