const style = (theme) => {
  const output = {
    '@global': {
      body: {
        background: 'linear-gradient(to bottom, #6e88d0, #ab58ff)',
      },
    },
    root: {
      color: theme.colors[1],
      paddingTop: (props) => props.headerHeight,
    },
    wrapper: {
      extend: theme.wrapper,
    },
    heading: {
      position: 'fixed',
      zIndex: 1,
      top: (props) => props.headerHeight + 60,
      left: '4.2vw',
      color: theme.colors[1],
      pointerEvents: 'none',
      paddingLeft: 90,
    },
    headingTitle: {
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: '10vw',
      lineHeight: 0.85,
      fontWeight: 'normal',
    },
    headingText: {
    },
    items: {
      paddingLeft: '50vw',
      paddingRight: '13vw',
    },
    bannerStroke: {
      width: '100%',
    },
    bannerItem: {
      margin: [60, 0, 80],
      width: '100%',
      '& a': {
        display: 'block',
        width: '100%',
        color: theme.colors[1],
        '@media (hover: hover)': {
          '&:hover': {
            '& $bannerImage': {
              opacity: 0.4,
            },
          },
        },
      },
    },
    bannerImage: {
      marginBottom: 10,
      transition: 'opacity 0.3s ease-out',
      overflow: 'hidden',
      borderRadius: 10,
      height: 0,
      position: 'relative',
      zIndex: 1,
      width: '100%',
      paddingBottom: '56.25%',
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
      },
    },
    bannerTitle: {
      fontSize: 25,
      marginBottom: 4,
    },
    bannerDate: {
      fontSize: 13,
    },
    viewMore: {
      extend: theme.button,
      marginBottom: 100,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    heading: {
      paddingLeft: 0,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    heading: {
      position: 'relative',
      top: `${0}px!important`,
      left: 0,
      paddingTop: 0,
      paddingLeft: '4.2vw',
      paddingRight: '4.2vw',
      pointerEvents: 'all',
    },
    headingTitle: {
      fontSize: '20vw',
    },
    items: {
      paddingLeft: '4.2vw',
    },
    bannerItem: {
      transform: 'rotate(0deg)!important',
    },
    bannerTitle: {
      fontSize: 21,
    },
    bannerDate: {
      fontSize: 12,
      marginTop: 8,
    },
  }

  return output
}

export default style
