/* eslint-disable react/jsx-props-no-spreading */
import { useRef, createRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import style from './style'

const useStyles = createUseStyles(style)

const CircularText = ({
  children,
  className,
  radius = 100,
  space = 1,
  ...props
}) => {
  const classes = useStyles()
  const $root = useRef()
  const letters = children.split('')
  const $letters = useRef(letters.map(() => createRef()))

  const renderLetters = () => {
    return (letters.map((l, ind) => {
      const i = ind / (letters.length + space)
      const x = Math.sin(i * Math.PI * 2) * radius
      const y = -Math.cos(i * Math.PI * 2) * radius
      return (
        <span
          key={ind.toString()}
          ref={$letters.current[ind]}
          className={classNames({
            [classes.wrap]: true,
            'circular-letter-wrap': true,
          })}
        >
          <span
            className={classNames({
              [classes.letter]: true,
              'circular-letter': true,
            })}
            style={{
              transform: `translate3D(${x}px, ${y}px, 0) rotate(${
                i * 360}deg)`,
            }}
          >
            {l}
          </span>
        </span>
      )
    }))
  }

  return (
    <div
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
      {...props}
    >
      {renderLetters()}
    </div>
  )
}

export default CircularText
