const style = (theme) => {
  const output = {
    root: {
    },
    wrapper: {
      extend: theme.wrapper,
    },
    hero: {
      position: 'relative',
      zIndex: 1,
      padding: [0, 90],
      marginBottom: 120,
    },
    title: {
      marginTop: -30,
      color: theme.colors[1],
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: '16vw',
      lineHeight: 0.85,
      fontWeight: 'normal',
      margin: 0,
      '& .revealText--wrap': {
        '& .line:nth-child(2)': {
          '& div': {
            textAlign: 'center!important',
          },
        },
        '& .line:nth-child(3)': {
          '& div': {
            textAlign: 'right!important',
          },
        },
      },
    },
    text: {
      maxWidth: '50%',
      marginTop: '-8vw',
    },
    svg: {
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: '40%',
      width: '50%',
      height: 'auto',
      maxWidth: 800,
      transform: 'scale(.5) rotate(10deg)',
      opacity: 0,
    },
    path: {
      fill: 'none',
      stroke: 'red',
      strokeWidth: 2,
    },
    line1: {
      '& $path': {
        strokeWidth: 5,
        strokeDasharray: '100 650 200 700',
        strokeDashoffset: 200,
        stroke: theme.colors[4],
        animation: '$move1 5s linear infinite reverse',
      },
    },
    line2: {
      transform: 'translate(2px, -2px)',
      // display: 'none',
      '& $path': {
        strokeWidth: 6,
        strokeDasharray: '100 350 500 430',
        strokeDashoffset: 100,
        stroke: theme.colors[5],
        animation: '$move2 3s linear infinite reverse',
      },
    },
    line3: {
      transform: 'translate(-2px, 2px)',
      // display: 'none',
      '& $path': {
        strokeWidth: 4,
        strokeDasharray: '100 300 200 400',
        stroke: theme.colors[6],
        animation: '$move3 2s linear infinite reverse',
      },
    },
    '@keyframes move1': {
      '0%': {
        strokeDashoffset: 200,
      },
      '100%': {
        strokeDashoffset: 1850,
      },
    },
    '@keyframes move2': {
      '0%': {
        strokeDashoffset: 100,
      },
      '100%': {
        strokeDashoffset: 1480,
      },
    },
    '@keyframes move3': {
      '0%': {
        strokeDashoffset: 0,
      },
      '100%': {
        strokeDashoffset: 1000,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    hero: {
      padding: [0, 15],
      marginBottom: 80,
    },
    title: {
      marginTop: -10,
      fontSize: '20vw',
    },
    text: {
      fontSize: 16,
      maxWidth: 'none',
      marginTop: 0,
    },
    svg: {
      left: '30%',
      width: '70%',
    },
  }

  return output
}

export default style
