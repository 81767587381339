/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-eval */
import { useRef, useState, useMemo, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useDrag } from 'react-use-gesture'
import { useResize } from '@/components/Handlers'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const AvailableSelector = ({ className, items, ready }) => {
  const classes = useStyles()
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  const $items = useRef()
  const actualX = useRef(0)
  const [isDown, setIsDown] = useState(false)
  const itemWidth = useRef(207)
  const wrapperWidth = useRef(0)

  const { twelveMonths, firstAvailable } = useMemo(() => {
    const itemsToSplice = items.slice()
    const firstMonth = itemsToSplice.findIndex((item) => parseInt(item.month, 10) === month && parseInt(item.year, 10) === year)
    const spliceMonths = itemsToSplice.splice(firstMonth, firstMonth + 17)
    wrapperWidth.current = spliceMonths.length * itemWidth.current
    return {
      twelveMonths: spliceMonths,
      firstAvailable: spliceMonths.findIndex((item) => item.available === 'yes' || item.available === 'half'),
    }
  }, [items])

  const handleResize = () => {
    itemWidth.current = window.innerWidth >= 720 ? 207 : 140
    wrapperWidth.current = twelveMonths.length * itemWidth.current
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    if (firstAvailable && ready) {
      gsap.to($items.current, {
        x: -firstAvailable * itemWidth.current,
        ease: 'power3.inOut',
        duration: 4,
        delay: 1,
      })
    }
  }, [ready])

  useResize(() => {
    handleResize()
  })

  const bindDrag = useDrag(({ first, down, movement: [x] }) => {
    setIsDown(down)
    if (first) actualX.current = gsap.getProperty($items.current, 'x')
    if (down) {
      gsap.to($items.current, {
        x: gsap.utils.clamp(-wrapperWidth.current + itemWidth.current, 0, actualX.current + x * 1.3),
        ease: 'power3.out',
      })
    }
  })

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div
        className={classNames({
          [classes.wrapper]: true,
          grabbing: isDown,
        })}
        {...bindDrag()}
      >
        <div
          className={classes.items}
          ref={$items}
        >
          {twelveMonths.map((item, index) => {
            return (
              <div
                key={index.toString()}
                className={classNames({
                  [classes.item]: true,
                  [classes.half]: item.available === 'half',
                  [classes.available]: item.available === 'yes',
                })}
              >
                <span className={classes.month}>{months[item.month]}</span>
                <span className={classes.year}>{item.year}</span>
                <span className={classes.pin} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AvailableSelector
