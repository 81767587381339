import { mainCacheAxios, mainAxios as axios } from '@/base/axios'
import {
  RECEIVE_CPT,
  RECEIVE_CPTS,
  RECEIVE_PAGE,
  RECEIVE_POST,
  RECEIVE_POSTS,
  REQUEST_CPT,
  REQUEST_CPTS,
  REQUEST_PAGE,
  REQUEST_POST,
  REQUEST_POSTS,
  REQUEST_TAXONOMY,
  RECEIVE_TAXONOMY,
  REQUEST_CATEGORY,
  // RECEIVE_CATEGORY,
} from '@/actions/types'
import { mergeArrays } from '@/utils/array'
import { API, LANG } from '@/constants'

const requestPage = () => ({ type: REQUEST_PAGE })

const receivePage = (data, slug, child) => ({
  type: RECEIVE_PAGE,
  payload: data,
  key: slug,
  child,
})

const fetchPage = (slug, child = false) => async (dispatch) => {
  dispatch(requestPage())
  const response = await mainCacheAxios.get(`${API.PAGE}?slug=${slug}&lang=${LANG}`)
  const { id } = response.data[0]
  const responseChild = child && await mainCacheAxios.get(`${API.PAGE}?parent=${id}&lang=${LANG}`)
  dispatch(receivePage(response.data[0], slug, responseChild.data))
}

const requestCpts = () => ({ type: REQUEST_CPTS })

const receiveCpts = (data, slug) => ({
  type: RECEIVE_CPTS,
  payload: data,
  key: slug,
})

const fetchCpts = (cpt) => async (dispatch) => {
  dispatch(requestCpts())
  const response = await mainCacheAxios.get(`${API.BASE_URL}/${cpt}?lang=${LANG}`)
  dispatch(receiveCpts(response.data, cpt))
}

const requestCpt = () => ({ type: REQUEST_CPT })

const receiveCpt = (data, cpt) => ({
  type: RECEIVE_CPT,
  payload: data,
  key: cpt,
})

const fetchCpt = (cpt, slug) => async (dispatch) => {
  dispatch(requestCpt())
  const response = await axios.get(`${API.BASE_URL}/${cpt}?slug=${slug}&lang=${LANG}`)
  dispatch(receiveCpt(response.data, cpt))
}

const requestTaxonomy = () => ({ type: REQUEST_TAXONOMY })

const receiveTaxonomy = (data, name) => ({
  type: RECEIVE_TAXONOMY,
  payload: data,
  key: name,
})

const fetchTaxonomy = (name, save) => async (dispatch) => {
  dispatch(requestTaxonomy())
  const response = await axios.get(`${API.BASE_URL}/${name}`)
  if (save) dispatch(receiveTaxonomy(response.data, name))
  return response.data
}

const requestCategory = () => ({ type: REQUEST_CATEGORY })

const fetchCategory = (taxonomy, slug) => async (dispatch) => {
  dispatch(requestCategory())
  const response = await axios.get(`${API.BASE_URL}/${taxonomy}?slug=${slug}`)
  return response.data[0]
}

const requestPosts = () => ({ type: REQUEST_POSTS })

const receivePosts = (data, totalPosts, totalPages) => ({
  type: RECEIVE_POSTS,
  payload: data,
  totalPosts,
  totalPages,
})

const fetchAllPosts = (numItem, paged, categories, save) => async (dispatch) => {
  dispatch(requestPosts())
  const params = {
    params: {
      order: 'desc',
      orderby: 'date',
      per_page: numItem,
      page: paged,
      ...(categories && {
        categories,
      }),
    },
  }
  const response = await axios.get(`${API.POST}?&lang=${LANG}`, params)
  if (save) {
    dispatch(receivePosts(
      response.data,
      response.headers['x-wp-total'],
      response.headers['x-wp-totalpages'],
    ))
  }
  return response.data
}

const requestPost = () => ({ type: REQUEST_POST })

const receivePost = (data) => ({
  type: RECEIVE_POST,
  payload: data,
})

const fetchPost = (slug) => async (dispatch, getState) => {
  dispatch(requestPost())
  const response = await axios.get(`${API.POST}?slug=${slug}&lang=${LANG}`)
  const posts = mergeArrays(getState().content.posts, response.data)
  dispatch(receivePost(posts))
}

export {
  fetchAllPosts,
  fetchCategory,
  fetchCpt,
  fetchCpts,
  fetchPage,
  fetchPost,
  fetchTaxonomy,
}
