const style = (theme) => {
  const output = {
    visible: {
      opacity: 1,
    },
    spinner: {
      width: 30,
      height: 30,
      pointerEvents: 'none',
      transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      opacity: 0,
      position: 'fixed',
      top: 'calc(50% - 15px)',
      left: 'calc(50% - 15px)',
      '&$visible': {
        opacity: 1,
      },
    },
    spinnerLoader: {
      stroke: theme.getRgba(theme.colors[0], 0.3),
      width: '100%',
      height: '100%',
      transition: `all 1s ${theme.easings['power3.out']}`,
      animation: '$rotate 2s linear infinite',
      '& .path': {
        strokeLinecap: 'round',
        animation: '$dash 1.5s ease-in-out infinite',
      },
    },
    '@keyframes rotate': {
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: '0',
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: '-35',
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: '-124',
      },
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      display: 'none',
      visibility: 'hidde',
    },
  }

  return output
}

export default style
