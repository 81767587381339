import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import ScrollIndicator from '@/components/ScrollIndicator'
import DelayLink from '@/components/DelayLink'
import CanvasHome from '@/components/CanvasHome'
import StrokeButton from '@/components/StrokeButton'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import RevealButton from '@/components/RevealButton'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import zustand from '@/base/zustand'
import { removeParagraph } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const HomePage = ({
  stub: stubFromProps,
}) => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const stub = useRef(stubFromProps)
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const $canvas = useRef()
  // const theme = useTheme()
  const [scrollSection, setScrollSection] = useState(-1)

  const { scrollElement } = useContext(ScrollbarContext)
  const sections = useRef(Array(4).fill(''))

  // /*------------------------------
  // Executes a callback on loading each image
  // ------------------------------*/
  // useHandleAllMediaWithCb({
  //   init: load,
  //   ref: $root.current?.ref,
  // })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, strings, socialNav } = useSelector((state) => ({
    strings: state.options.strings,
    page: state.content.pages[stub.current] || {},
    socialNav: state.nav.social_menu,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Did Mount (Fetch Data)
  ------------------------------*/
  useEffect(() => {
    gsap.to('body', {
      background: 'linear-gradient(#987fdd, #664dff)',
    })
    if (Object.keys(page).length === 0) fetchPage(stub.current)
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      setDataFetched(true)
      sections.current[0] = removeParagraph(page.acf?.section.text1)
      sections.current[1] = removeParagraph(page.acf?.section.text2)
      sections.current[2] = removeParagraph(page.acf?.section.text3)
      sections.current[3] = removeParagraph(page.acf?.section.text4)
    }
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [
      page.featured_image && { ...{ type: 'image', src: page.featured_image } },
    ],
    callback: () => {
      setPageAnimationReady(true)
    },
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Canvas Animation
  ------------------------------*/
  useEffect(() => {
    if (load && $canvas.current) {
      gsap.fromTo($canvas.current, {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 2,
        delay: 1,
      })
    }
  }, [load])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (scrollElement) {
      ScrollTrigger.batch('.section', {
        scroller: scrollElement,
        batchMax: 1,
        toggleClass: 'active',
        onToggle: (self) => {
          setScrollSection(self[0].classList.contains('active') ? self[0].dataset.index : -1)
        },
      })
    }
  }, [scrollElement])

  /*------------------------------
  Render Links
  ------------------------------*/
  const renderLinks = () => {
    return load && (
      <div className={classes.links}>
        <InViewTrigger className={classes.link}>
          <RevealButton
            onMouseEnter={() => { zustand.home.modelHover = 'left' }}
            onMouseLeave={() => { zustand.home.modelHover = '' }}
            delay={0.2}
          >
            <StrokeButton>
              <DelayLink to={page.acf?.links.link1}>{page.acf?.links.label_link1}</DelayLink>
            </StrokeButton>
          </RevealButton>
        </InViewTrigger>
        <InViewTrigger className={classes.link}>
          <RevealButton
            onMouseEnter={() => { zustand.home.modelHover = 'right' }}
            onMouseLeave={() => { zustand.home.modelHover = '' }}
            delay={0.2}
          >
            <StrokeButton>
              <DelayLink to={page.acf?.links.link2}>{page.acf?.links.label_link2}</DelayLink>
            </StrokeButton>
          </RevealButton>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  RenderSections
  ------------------------------*/
  const renderSections = () => {
    return load && (
      <div className={classes.sectionWrapFixed}>
        {sections.current.map((s, i) => {
          return (
            <section key={i.toString()} className={classes.sectionItem}>
              <RevealText
                tag={i === 0 ? 'h1' : 'h2'}
                value={s}
                type="lines"
                lineHeight={0.85}
                visible={i.toString() === scrollSection.toString()}
              />
            </section>
          )
        })}
      </div>
    )
  }

  /*------------------------------
  RenderContent
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <div className={classes.page}>
        <div className={classes.wrapper}>
          <div className={classes.sectionWrapScroll}>
            {Array(sections.current.length + 2).fill('').map((_, i) => (
              <section
                key={i.toString()}
                data-index={i - 1}
                className={classNames({
                  [classes.section]: true,
                  section: true,
                })}
              />
            ))}
          </div>
          {renderLinks()}
        </div>
      </div>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageHome ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      <ScrollIndicator load={load} social={socialNav} label={strings.scroll_to_discover_label} />
      <div ref={$canvas} className={classes.canvas}>
        <CanvasHome load={load} />
      </div>
      {renderSections()}
    </>
  )
}

export default HomePage
