const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
      '& a': {
        color: theme.colors[1],
      },
    },
    page: {
      paddingTop: (props) => props.headerHeight + 30,
    },
    wrapper: {
      extend: theme.wrapper,
      margin: [0, 'auto'],
      maxWidth: 1920,
      display: 'flex',
    },
    smallWrapper: {
      extend: theme.smallWrapper,
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.colors[1],
      fontFamily: theme.fonts[1],
      textTransform: 'uppercase',
      fontSize: '8vw',
      lineHeight: 0.85,
      fontWeight: 'normal',
      margin: 0,
    },
    subTitle: {
      fontSize: '4vw',
      marginTop: '3.8vw',
    },
    text: {
      marginTop: 40,
      maxWidth: 400,
    },
    left: {
      flex: '0 0 50%',
      paddingLeft: 90,
      paddingRight: 50,
    },
    right: {
      flex: '0 0 50%',
      paddingRight: 30,
    },
    availableSelector: {
      marginTop: 40,
    },
    formWrapper: {
      paddingTop: (props) => props.headerHeight + 30,
      minHeight: 'var(--vh)',
      '& form': {
        '& > div:first-child': {
          width: '100%',
        },
      },
    },
    email: {
      marginTop: 10,
      fontSize: 30,
      '& svg': {
        transform: 'translateY(20px)',
      },
    },
    scrollIndicator: {},
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    title: {
      fontSize: 80,
    },
    text: {
      maxWidth: 'none',
    },
    email: {
      fontSize: 20,
    },
    wrapper: {
      display: 'block',
    },
    right: {
      paddingLeft: 90,
      paddingRight: 30,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    scrollIndicator: {
      display: 'none',
    },
    page: {
      paddingTop: (props) => props.headerHeight - 60,
    },
    title: {
      fontSize: 75,
    },
    subTitle: {
      fontSize: 60,
    },
    text: {
      fontSize: 16,
      marginTop: 20,
    },
    left: {
      paddingTop: 50,
      paddingLeft: 10,
    },
    right: {
      paddingTop: 50,
      paddingLeft: 10,
      paddingRight: 0,
    },
    availableSelector: {
      marginTop: 20,
    },
    formWrapper: {
      paddingTop: (props) => props.headerHeight - 60,
    },
  }

  return output
}

export default style
