/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useEffect, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { BoxBufferGeometry, Color, DoubleSide } from 'three'

const Confetto = ({ x, y, z, scale, time, timeX, color, geometry }) => {
  const $ref = useRef()
  useFrame(() => {
    $ref.current.position.y -= time
    $ref.current.position.x += Math.sin(timeX) * 3
    $ref.current.rotation.y += Math.sin(time) * 3
    $ref.current.rotation.x += time * 1.3
    $ref.current.rotation.z += time
  })

  return (
    <mesh position={[x, y, z]} scale={[scale, scale * 0.8, scale * 0.03]} ref={$ref} geometry={geometry}>
      <meshPhongMaterial shininess={100} color={color} side={DoubleSide} />
    </mesh>
  )
}

const Confetti = ({ callback }) => {
  const [geometry] = useState(() => new BoxBufferGeometry(1, 1, 1), [])
  const data = useMemo(() => {
    return new Array(200).fill().map(() => ({
      x: Math.random() * 20 - 10,
      y: 10,
      z: -2 - Math.random() * 4,
      scale: 0.1 + Math.random() * 0.1,
      time: 0.05 + Math.random() * 0.1,
      timeX: -0.01 + Math.random() * 0.02,
      color: new Color(`hsl(${Math.floor(Math.random() * 360)}, 90%, 50%)`),
    }))
  }, [])
  const timer = useRef()

  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      callback(false)
    }, 6000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return data.map((props, i) => (
    <Confetto
      key={i.toString()}
      {...props}
      geometry={geometry}
    />
  ))
}

export default Confetti
