import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import gsap from 'gsap'
import { useLocation } from 'react-router-dom'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import { useRaf } from '@/components/Handlers'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import StrokeButton from '@/components/StrokeButton'
import DelayLink from '@/components/DelayLink'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import useViewport from '@/hooks/useViewport'
import * as contentActions from '@/actions/content'
import { convertDate } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const NUM_ITEM = 10

const ArchiveNews = () => {
  const { vw } = useViewport()
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const { scrollbar, scroll, speed } = useContext(ScrollbarContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()
  const $items = useRef()
  const [categories, setCategories] = useState([])
  const [currentPosts, setCurrentPosts] = useState([])
  const [pagination, setPagination] = useState(1)

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, posts, strings, totalPages } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    posts: state.content.posts,
    totalPages: state.content.totalPages,
    strings: state.options.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchTaxonomy = useCallback((name, save) => dispatch(contentActions.fetchTaxonomy(name, save)), [dispatch])
  const fetchAllPosts = useCallback((numItem, paged, category, save) => dispatch(contentActions.fetchAllPosts(numItem, paged, category, save)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    currentPosts
      .slice(0, 2)
      .map((item) => {
        preload.current.loadFile({ id: `img-${item.id}`, src: item.featured_image })
        return false
      })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (
      Object.keys(page).length > 0
      && currentPosts.length > 0
      && categories.length > 0
    ) {
      preloadMedia()
    }
  }, [page, currentPosts, categories])

  useEffect(() => {
    if (posts.length > 0) setCurrentPosts(posts)
  }, [posts])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    fetchAllPosts(NUM_ITEM, pagination, '', true)
    fetchTaxonomy('categories', false).then((res) => setCategories(res))
    return false
  }

  /*------------------------------
  Fetch data when pagination value change
  ------------------------------*/
  useEffect(() => {
    if (pagination > 1) fetchAllPosts(NUM_ITEM, pagination, '', false).then((res) => setCurrentPosts((prev) => [...prev, ...res]))
  }, [pagination])

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    gsap.to('body', {
      background: 'linear-gradient(#987fdd, #664dff)',
    })
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Update Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (currentPosts.length > 0 && pagination > 1) {
      setTimeout(() => {
        if (scrollbar) scrollbar.update()
      }, 200)
    }
  }, [currentPosts])

  /*------------------------------
  Raf
  ------------------------------*/
  useRaf(() => {
    if (scroll.current.y !== undefined && vw > 720 && $items.current) {
      gsap.to($items.current.querySelectorAll('.bannerItem'), {
        rotate: -5 + gsap.utils.clamp(-5, 5, speed.current),
        duration: 1,
        ease: 'power1.out',
      })
    }
  }, [scroll, vw])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderHeading = () => {
    return load && (
      <div className={`${classes.heading}`}>
        <RevealText
          tag="h1"
          className={`${classes.headingTitle}`}
          type="lines"
          lineHeight={0.85}
          value={page.acf?.archive_news_title}
          visible={pageAnimationReady}
        />
        <RevealText
          tag="div"
          className={`${classes.headingText}`}
          type="lines"
          value={page.acf?.archive_news_label}
          visible={pageAnimationReady}
        />
      </div>
    )
  }

  /*------------------------------
  Render Items
  ------------------------------*/
  const renderItems = () => {
    return currentPosts.map((item) => {
      return (
        <RevealFadeIn
          key={item.id}
          className={`${classes.bannerItem} bannerItem`}
          visible={pageAnimationReady}
        >
          <StrokeButton className={classes.bannerStroke}>
            <DelayLink
              to={item.link}
            >
              <div className={classes.bannerImage}>
                <img src={item.featured_image} alt={item.title.rendered} />
              </div>
              <div className={classes.bannerTitle}>
                {item.title.rendered}
              </div>
              <div className={classes.bannerDate}>
                {convertDate(item.date)}
              </div>
            </DelayLink>
          </StrokeButton>
        </RevealFadeIn>
      )
    })
  }

  /*------------------------------
  Handle Click Load More
  ------------------------------*/
  const handleClickLoadMore = () => {
    setPagination(pagination + 1)
  }

  /*------------------------------
  Render View More
  ------------------------------*/
  const renderViewMore = () => {
    return pagination < totalPages && (
    <button
      className={classes.viewMore}
      onClick={handleClickLoadMore}
    >
      {strings.load_more_label}
      <svg><use xlinkHref="#ico-arrow-right" /></svg>
    </button>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.items} ref={$items}>
          {renderItems()}
          {renderViewMore()}
        </div>
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageArchiveNews ${classes.root}`}
        ref={$root}
      >
        {vw < 720 && renderHeading()}
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
      {vw >= 720 && renderHeading()}
    </>
  )
}

export default ArchiveNews
