import { useRef, useEffect, useContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Context as GeneralContext } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import zustand from '@/base/zustand'
import CircularText from '@/components/CircularText'
import RevealText from '@/components/RevealText'
import { removeParagraph, removeBr } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const Section7 = ({ className, speed = 2, text }) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const { scrollElement } = useContext(ScrollbarContext)
  const $root = useRef()
  const $text = useRef()
  const $circularWrap = useRef()
  const $circular = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const tl = useRef()

  /*------------------------------
  Kill
  ------------------------------*/
  useEffect(() => {
    gsap.set(zustand.manifest.sections['7'], {
      progress: 0,
    })

    return (() => {
      if (ScrollTrigger.getById('section-7')) {
        ScrollTrigger.getById('section-7').kill()
      }
      tl.current.kill()
      tl.current = null
    })
  }, [])

  /*------------------------------
  Scrollbar
  ------------------------------*/
  useEffect(() => {
    if (pageAnimationReady && scrollElement) {
      if (ScrollTrigger.getById('section-7')) {
        ScrollTrigger.getById('section-7').kill()
      }
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }

      tl.current = gsap.timeline({
        scrollTrigger: {
          id: 'section-7',
          scroller: scrollElement,
          trigger: $root.current,
          start: 'top',
          end: `+=${window.innerHeight * speed}`,
          scrub: 1,
          pin: true,
          once: false,
        },
      })
        .addLabel('start')
        .fromTo($circularWrap.current, {
          rotation: -360,
        }, {
          rotation: 0,
          duration: 4.5,
          ease: 'linear',
        }, 'start')
        .fromTo($circularWrap.current, {
          opacity: 0,
          scale: 0,
        }, {
          opacity: 1,
          scale: 1,
          duration: 2,
          ease: 'power1.out',
        }, 'start')
        .to($text.current.querySelectorAll('.line > div'), {
          y: '0%',
          scaleY: 1,
          duration: 0.1,
          stagger: 0.05,
        }, 'start+=1')
        .to($circular.current, {
          opacity: 0,
          scale: 8,
          duration: 2,
          ease: 'power3.in',
        }, 'start+=2')
        .to(zustand.manifest.sections['7'], {
          progress: 1,
          duration: 2,
          ease: 'linear',
        }, 'start+=0.5')

      if (theme.detect.debug) {
        window.console.log('section 7 ---->', tl.current.duration())
      }
    }
  }, [pageAnimationReady, scrollElement])

  return (
    <section
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div className={classes.section}>
        <div ref={$circularWrap}>
          <div ref={$circular} className={classes.circularTextWrap}>
            <CircularText
              className={classes.circularText}
              space={true}
            >
              WE ARE GHOST UNICORNS
            </CircularText>
          </div>
        </div>
        <RevealText
          value={removeParagraph(window.innerWidth > 768 ? text : removeBr(text))}
          ref={$text}
          className={classes.text}
          tag="div"
          type="lines"
        />
      </div>
    </section>
  )
}
export default Section7
