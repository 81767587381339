import { useEffect, useState, useRef, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import DelayLink from '@/components/DelayLink'
import { useResize } from '@/components/Handlers'
import SplitText from '@/vendors/bonus-files-for-npm-users/umd/SplitText'
import style from './style'

const useStyles = createUseStyles(style)

const RevealText = forwardRef(({
  className,
  value,
  lineHeight,
  enterDelay,
  exitDelay,
  stagger,
  tag,
  type,
  duration,
  ease,
  link,
  visible,
  inview,
}, ref) => {
  // Il line-height minimo per la visualizzazione corretta di un overflow:hidden è 1.25
  // Passo ad ogni linea il margine negativo in em per correggere il line-height passato dalle props
  const linesMargin = -1.25 + lineHeight
  const classes = useStyles({ linesMargin })
  const $root = useRef()
  const $words = useRef()
  const $split = useRef()
  const winWidth = useRef()
  const Tag = tag
  const [splitted, setSplitted] = useState(false)

  /*------------------------------
  Wrap Line
  ------------------------------*/
  const wrapLine = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el)
    wrapper.classList.add('line')
    wrapper.appendChild(el)
  }

  /*------------------------------
  Split
  ------------------------------*/
  const split = () => {
    if (!$words.current) return
    $split.current = new SplitText($words.current, { type: `lines ${type}` })
    gsap.set($split.current[type], {
      y: '120%',
      transformOrigin: 'center top',
    })
    gsap.set($root.current, {
      opacity: 1,
    })
    setSplitted(true)

    $split.current.lines.forEach((line) => {
      wrapLine(line, document.createElement('div'))
    })
  }

  /*------------------------------
  Resize
  ------------------------------*/
  useResize(() => {
    if (winWidth.current !== window.innerWidth && $split.current) {
      $split.current.revert()
      setSplitted(false)
      setTimeout(() => {
        split()
        winWidth.current = window.innerWidth
      }, 10)
    }
  }, [])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    winWidth.current = window.innerWidth
    split()
  }, [])

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    if (splitted) {
      $words.current.style.opacity = 1
      gsap.to($split.current[type], {
        y: (visible || inview) ? '0%' : '110%',
        scaleY: (visible || inview) ? 1 : 2,
        duration,
        stagger,
        ease,
        delay: (visible || inview) ? enterDelay : exitDelay,
      })
    }
  }, [visible, inview, splitted])

  return (
    <Tag
      ref={(r) => {
        if (ref) ref.current = r
        $root.current = r
      }}
      className={classNames({
        [className]: className,
        [classes.root]: true,
        [classes.visible]: visible,
      })}
    >
      <ConditionalWrapper
        condition={link}
        wrapper={(child) => <DelayLink className={classes.link} to={link}>{child}</DelayLink>}
      >
        <div
          className={classNames({
            [classes.wrap]: true,
            'revealText--wrap': true,
          })}
          dangerouslySetInnerHTML={{ __html: value }}
          ref={$words}
        />
      </ConditionalWrapper>
    </Tag>
  )
})

/*------------------------------
Default Props
------------------------------*/
RevealText.defaultProps = {
  className: '',
  value: '',
  type: 'chars', // chars, words, or lines
  enterDelay: 0,
  exitDelay: 0,
  lineHeight: 1.25,
  tag: 'div',
  stagger: 0.1,
  duration: 1.3,
  ease: 'power3.out',
  link: false,
  visible: false,
  inview: false,
}

export default RevealText
