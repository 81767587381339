const style = () => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      fontSize: 40,
    },
    wrap: {
      position: 'absolute',
      zIndex: 1,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      verticalAlign: 'middle',
    },
    letter: {
      display: 'inline-block',
    },
  }

  return output
}

export default style
