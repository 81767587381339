import { useCallback, useEffect, useContext, useRef, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useSound from 'use-sound'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HTMLParse from 'react-html-parser'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import ally from 'ally.js'
import { Context } from '@/context'
import { useResize } from '@/components/Handlers'
import StrokeButton from '@/components/StrokeButton'
import MagneticButton from '@/components/MagneticButton'
import DelayLink from '@/components/DelayLink'
import usePrevious from '@/hooks/usePrevious'
import { getSlug } from '@/utils/path'
import { decodeEntities } from '@/utils/decodeEntities'
import * as layerActions from '@/actions/layer'
import openMenuAudio from '@/assets/audio/open-menu.mp3'
import closeMenuAudio from '@/assets/audio/close-menu.mp3'
import style from './style'

const useStyles = createUseStyles(style)

const Nav = ({
  mainNav,
}) => {
  const { headerHeight, headerRef, cookieRef } = useContext(Context)
  const location = useLocation()
  const [isQuoteLinkClicked, setQuoteLinkClicked] = useState(false)
  const [playOpenMenuAudio, {
    sound: openMenuSound,
  }] = useSound(openMenuAudio)
  const [playCloseMenuAudio, {
    sound: closeMenuSound,
  }] = useSound(closeMenuAudio)
  const [isMobile, setMobile] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isMenuOpen, strings, socialNav } = useSelector((state) => ({
    strings: state.options.strings,
    pathname: state.router.location.pathname,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    socialNav: state.nav.social_menu,
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)
  const classes = useStyles({ headerHeight, isMenuOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const $root = useRef()
  const $mainNav = useRef()

  const disableAllTabIndex = useRef(null)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (mainNav?.length) setReady(true)
  }, [mainNav])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.set([$root.current], {
      x: '-100%',
    })
    gsap.set([$mainNav.current], {
      autoAlpha: 0,
      y: 40,
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  const openMainMenu = () => {
    gsap.killTweensOf([$root.current])
    openMenuSound.rate(1.3)
    openMenuSound.volume(0.3)
    playOpenMenuAudio()
    const tl = gsap.timeline({ ease: 'power3.out' })
    tl
      .to($root.current, {
        duration: 0.5,
        x: '0%',
      })
      .to([$mainNav.current], {
        duration: 0.5,
        autoAlpha: 1,
        y: 0,
        stagger: 0.08,
      }, '<0.2')
  }

  const closeMainMenu = () => {
    closeMenuSound.volume(0.15)
    closeMenuSound.rate(1.6)
    playCloseMenuAudio()
    gsap.killTweensOf([$root.current])
    gsap.to([$root.current], {
      duration: 0.5,
      x: '-100%',
    })
    gsap.to([$mainNav.current], {
      duration: 0.5,
      autoAlpha: 0,
      y: 40,
      delay: 0.3,
    })
  }

  /*------------------------------
  Resize
  ------------------------------*/
  useResize(() => {
    setMobile(window.innerWidth < 720)
  }, [])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Manage Accessibility
  ------------------------------*/
  useEffect(() => {
    if (isMenuOpen) {
      disableAllTabIndex.current = ally.maintain.disabled({
        filter: [$root.current, headerRef.current, ...cookieRef.current ? [cookieRef.current] : []],
      })
    }
    if (!isMenuOpen && disableAllTabIndex.current !== null) disableAllTabIndex.current.disengage()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (prevPathname !== pathname) {
      setTimeout(() => {
        if (isMenuOpen) closeMenu()
      }, 500)
    }
  }, [pathname])

  const renderMainNav = () => {
    return (
      <nav className={`${classes.nav} ${classes.mainNav}`}>
        <ul ref={$mainNav}>
          {mainNav.map((item, index) => {
            return (
              <li
                className={classes.mainNavItem}
                key={item.ID}
              >
                <StrokeButton>
                  <DelayLink
                    className={classNames({
                      entering: isMenuOpen,
                      [classes.active]: getSlug(item.url) === getSlug(location.pathname),
                    })}
                    style={{ '--index': index }}
                    to={item.url}
                  >
                    {decodeEntities(item.title)}
                  </DelayLink>
                </StrokeButton>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  const renderFooterNav = () => {
    return (
      <div className={classes.footerNav}>
        { strings.footer_nav_1 !== undefined && (
        <div className={classes.footerNavItem}>
          {HTMLParse(strings.footer_nav_1)}
        </div>
        )}
        { strings.footer_nav_2 !== undefined && (
        <div className={classes.footerNavItem}>
          {HTMLParse(strings.footer_nav_2)}
        </div>
        )}
      </div>
    )
  }

  const renderQuoteButton = ({ className }) => {
    return strings.richiedi_preventivo_label !== undefined && (
      <div className={classNames({
        [classes.quoteButton]: true,
        [classes[className]]: true,
        entering: isMenuOpen,
        clicked: isQuoteLinkClicked,
      })}
      >
        <MagneticButton
          speed={0.5}
          className={classes.magnetic}
        >
          <DelayLink
            to={strings.richiedi_preventivo_url}
            onClick={() => {
              if (!location.pathname.includes(strings.richiedi_preventivo_url)) {
                setQuoteLinkClicked(true)
                setTimeout(() => {
                  setQuoteLinkClicked(false)
                }, 3000)
              }
            }}
          >
            {HTMLParse(strings.richiedi_preventivo_label)}
            <svg viewBox="0 0 100 100">
              <path
                fillRule="evenodd"
                d="M92.712,54.205 L99.999,50.1 L92.713,45.794 L99.38,40.247 L91.72,37.540 L96.194,30.867 L87.852,29.768 L91.573,22.224 L83.178,22.772 L85.356,14.647 L77.228,16.822 L77.780,8.428 L70.232,12.148 L69.135,3.806 L62.459,8.928 L59.756,0.962 L54.207,7.287 L50.0,0.0 L45.794,7.287 L40.245,0.962 L37.541,8.928 L30.866,3.806 L29.768,12.148 L22.222,8.426 L22.773,16.822 L14.645,14.645 L16.822,22.772 L8.427,22.221 L12.149,29.768 L3.807,30.864 L8.928,37.540 L0.961,40.245 L7.286,45.794 L0.0,49.998 L7.286,54.205 L0.960,59.754 L8.928,62.459 L3.805,69.132 L12.147,70.231 L8.426,77.778 L16.821,77.227 L14.644,85.354 L22.771,83.177 L22.221,91.573 L29.767,87.851 L30.865,96.193 L37.541,91.71 L40.244,99.37 L45.793,92.714 L49.998,99.999 L54.206,92.714 L59.753,99.40 L62.459,91.71 L69.133,96.193 L70.232,87.851 L77.777,91.573 L77.228,83.177 L85.354,85.357 L83.178,77.227 L91.572,77.780 L87.852,70.231 L96.192,69.135 L91.72,62.459 L99.38,59.754 L92.712,54.205 Z"
              />
            </svg>
          </DelayLink>
        </MagneticButton>
      </div>
    )
  }

  /*------------------------------
  Render Socials
  ------------------------------*/
  const renderSocials = () => {
    return socialNav && (
      <div className={classes.socials}>
        { socialNav.map((s, index) => (
          <DelayLink to={s.url} key={index.toString()} className={classes.social}>
            <svg>
              <title>{s.title.toLowerCase()}</title>
              <use xlinkHref={`#ico-${s.title.toLowerCase()}`} />
            </svg>
          </DelayLink>
        ))}
      </div>
    )
  }

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {renderMainNav()}
          {renderQuoteButton({ className: 'quoteButtonMobile' })}
          {renderFooterNav()}
          {isMobile && renderSocials()}
          <a href="https://www.studiogusto.com/" rel="noopener noreferrer" target="_blank" className={classes.credits}>Credits</a>
        </div>
      </div>
      {renderQuoteButton({ className: 'quoteButtonDesktop' })}
    </div>
  )
}

Nav.propTypes = {
  mainNav: PropTypes.array,
}

Nav.defaultProps = {
  mainNav: [],
}

export default Nav
