import { useRef, useEffect, useState, createContext } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useTheme } from 'react-jss'
import { useRaf } from '@/components/Handlers'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import usePrevious from '@/hooks/usePrevious'

gsap.registerPlugin(ScrollTrigger)

const Context = createContext({})
const {
  Provider,
  Consumer,
} = Context

const ScrollbarContext = ({ children }) => {
  const [scrollbar, setScrollbar] = useState(null)
  const [nativeScrollbarWrapper, setNativeScrollbarWrapper] = useState(null)
  const [isScrollBlock, setIsScrollBlock] = useState(false)
  const [updateScrollbar, setUpdateScrollbar] = useState(false)
  const theme = useTheme()
  const scroll = useRef({})
  // const delta = useRef({})
  const limit = useRef(0)
  const [scrollElement, setScrollElement] = useState(null)
  // const [direction, setDirection] = useState(null)
  // const directionRef = useRef(null)
  const speed = useRef(0)
  const oldScroll = useRef({})

  /*------------------------------
  Update scrollbar after first scroll
  ------------------------------*/
  useEffect(() => {
    if (!theme.detect.isIE11 && updateScrollbar) {
      if (scrollbar) scrollbar.update()
    }
  }, [updateScrollbar])

  /*------------------------------
  Init and Change Page
  ------------------------------*/
  useEffect(() => {
    if (scrollbar) {
      // Init new scrollbar on change page
      if (theme.detect.isNativeScroll) {
        scrollbar.el.addEventListener('scroll', () => {
          const $el = scrollbar.el

          if (!updateScrollbar && $el.scrollTop > 0) {
            setUpdateScrollbar(true)
          }
          scroll.current = { x: $el.scrollLeft, y: $el.scrollTop }
          limit.current = { x: $el.clientWidth, y: $el.scrollHeight - $el.clientHeight }
        })
      } else {
        scrollbar.on('scroll', (e) => {
          if (!updateScrollbar && e.scroll.y > 0) {
            setUpdateScrollbar(true)
          }
          scroll.current = e.scroll
          // delta.current = e.delta
          limit.current = e.limit
          speed.current = e.speed
        // if (e.direction !== directionRef.current) {
        //   directionRef.current = e.direction
        //   setDirection(e.direction)
        // }
        })
      }
      setScrollElement(scrollbar.el)
    }
  }, [scrollbar, updateScrollbar])

  useRaf(() => {
    if (theme.detect.isNativeScroll) {
      speed.current = Math.abs(scroll.current.y - oldScroll.current.y)
      oldScroll.current.y = scroll.current.y
    }
  }, [])

  useUpdateEffect(() => {
    if (isScrollBlock && scrollbar) {
      if (!theme.detect.isNativeScroll) scrollbar.stop()
    }
    if (!isScrollBlock && scrollbar) {
      if (!theme.detect.isNativeScroll) scrollbar.start()
    }
  }, [isScrollBlock])

  const prevScrollbar = usePrevious(scrollbar)
  useEffect(() => {
    if (
      prevScrollbar !== undefined
      && prevScrollbar?.el !== scrollbar?.el
    ) {
      scroll.current.y = 0
      scrollbar.update()
      setUpdateScrollbar(false)
    }
  }, [scrollbar])

  // Init ScrollTrigger
  useEffect(() => {
    if (scrollbar && !theme.detect.isIE11) {
      scrollbar.on('scroll', () => {
        ScrollTrigger.update()
      })
      ScrollTrigger.scrollerProxy(scrollbar.el, {
        scrollTop(value) {
          return arguments.length ? scrollbar.scrollTo(value, 0, 0) : scroll.current.y
        },
        getBoundingClientRect() {
          return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
        },
        pinType: scrollbar.el.style.transform ? 'transform' : 'fixed',
      })
    }
  }, [scrollbar])

  return (
    <Provider value={{
      scrollbar,
      setScrollbar,
      speed,
      scroll,
      // delta,
      limit,
      // direction,
      isScrollBlock,
      setIsScrollBlock,
      nativeScrollbarWrapper,
      setNativeScrollbarWrapper,
      scrollElement,
    }}
    >
      { children }
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ScrollbarContext
