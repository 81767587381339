import { useState, useEffect } from 'react'

const useViewport = () => {
  const [vw, setVW] = useState(0)
  const [vwfixed, setVWfixed] = useState(0)
  const [vh, setVH] = useState(0)
  const [vhfixed, setVHfixed] = useState(0)

  useEffect(() => {
    const setSizes = () => {
      if (window.innerWidth !== vw) setVW(window.innerWidth)
      if (window.innerHeight !== vh) setVH(window.innerHeight)
    }

    setSizes()

    window.addEventListener('resize', setSizes)
    return () => window.removeEventListener('resize', setSizes)
  }, [vh, vw])

  useEffect(() => {
    if (window.innerWidth !== vwfixed) setVWfixed(window.innerWidth)
    if (window.innerHeight !== vhfixed) setVHfixed(window.innerHeight)
  }, [vhfixed, vwfixed])

  return { vw, vh, vwfixed, vhfixed }
}

export default useViewport
