import { useState, useEffect, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import StrokeBall from '@/components/StrokeBall'
import { Context as AudioContext } from '@/context/audio'
import style from './style'

const useStyles = createUseStyles(style)

const WhoSelector = ({ className, handleChange, types }) => {
  const { setPlayHoverSound } = useContext(AudioContext)
  const [who, setWho] = useState(0)
  const radius = 160
  const classes = useStyles({ radius })

  useEffect(() => {
    handleChange({ who: types[who] })
  }, [who])

  /*------------------------------
  Agency
  ------------------------------*/
  const renderAgency = () => {
    return (
      <div
        className={classNames({
          [classes.text]: true,
          [classes.active]: who === 0,
          className,
        })}
        style={{
          transform: `scale(${who === 0 ? 1.4 : 1}) rotate(-62deg)`,
          transformOrigin: who === 0 ? '57% 52%' : '50% 50%',
        }}
      >
        {types[0].split('').map((l, ind) => {
          const i = ind / 14
          const x = Math.sin(i * Math.PI * 2) * radius
          const y = -Math.cos(i * Math.PI * 2) * radius
          return (
            <span
              key={i.toString()}
              style={{ transform: `translate3D(${x}px, ${y}px, 0) rotate(${i * 360}deg)` }}
            >
              {l}
            </span>
          )
        })}
      </div>
    )
  }

  /*------------------------------
  Brand
  ------------------------------*/
  const renderBrand = () => {
    return (
      <div
        className={classNames({
          [classes.text]: true,
          [classes.active]: who === 1,
        })}
        style={{
          transform: `scale(${who === 1 ? '1.4, -1.4' : '1, -1'})  rotate(133deg)`,
          transformOrigin: who === 1 ? '41% 67%' : '50% 50%',
        }}
      >
        {types[1].split('').map((l, ind) => {
          const i = ind / 15
          const x = -Math.sin(i * Math.PI * 2) * radius
          const y = Math.cos(i * Math.PI * 2) * radius
          return (
            <span
              key={i.toString()}
              style={{ transform: `translate3D(${x}px, ${y}px, 0) rotate(${i * 360}deg) scale(-1, 1)` }}
            >
              {l}
            </span>
          )
        })}
      </div>
    )
  }

  /*------------------------------
  Switch
  ------------------------------*/
  const renderSwitch = () => {
    return (
      <button
        className={classNames({
          [classes.switch]: true,
          [classes.switchOff]: who === 1,
        })}
        onMouseEnter={() => {
          setPlayHoverSound(true)
        }}
        onClick={() => {
          setWho(who === 0 ? 1 : 0)
        }}
      >
        <span className={classes.switchButton} />
      </button>
    )
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <div
        className={classes.wrapper}
      >
        {renderAgency()}
        {renderBrand()}
        {renderSwitch()}
        <StrokeBall
          className={classNames({
            [classes.ball]: true,
            [classes.ballOff]: who === 1,
          })}
        />
      </div>
    </div>
  )
}

export default WhoSelector
