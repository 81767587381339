const style = (theme) => {
  const output = {
    root: {
      height: 'var(--vh)',
      color: theme.colors[1],
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      textAlign: 'center',
      '&:before': {
        content: window.location.search.indexOf('debug') !== -1 ? '""' : '',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: 10,
        background: 'linear-gradient(to bottom, red, blue)',
      },
      display: 'block',
      '@media (hover: hover)': {
        '& a': {
          '&:hover': {
            '& $title': {
              color: theme.getRgba(theme.colors[1], 0.3),
            },
            '& $button': {
              opacity: 1,
            },
          },
        },
      },
    },
    section: {
      position: 'relative',
      zIndex: 1,
      height: 'var(--vh)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    svgBall: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      width: '80vw',
      height: '80vh',
      transform: 'translate(-50%, -50%)',
    },
    title: {
      fontFamily: theme.fonts[1],
      fontSize: '20vw',
      textTransform: 'uppercase',
      color: theme.colors[1],
      transition: 'color .3s ease-out',
    },
    button: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity .3s ease-out',
      extend: theme.button,
    },
    toTop: {
      color: theme.colors[1],
      textTransform: 'uppercase',
      fontSize: 10,
      position: 'absolute',
      zIndex: 100,
      bottom: 57,
      cursor: 'pointer',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      pointerEvents: 'all',
      '& svg': {
        width: 30,
        height: 30,
        position: 'absolute',
        zIndex: 1,
        top: -38,
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        transformOrigin: 'center',
        transition: 'transform .3s ease-out',
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& svg': {
            transform: 'translate3d(-50%, 0, 0) rotate(40deg) scale(1.1)',
          },
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      marginTop: '0vh',
    },
    title: {
      fontSize: '30vw',
    },
  }

  return output
}

export default style
